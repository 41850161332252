import axios from "axios";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import imgData from "../assets/images/png/Logo@2x.png";

const downloadFile = async ({
  key,
  value,
  outputFormat,
  API_ENDPOINT,
  authToken,
  fileNameProp,
  setLoadingState,
  rfqData
}) => {
  try {
    setLoadingState(true);

    const formData = new FormData();

    formData.append(`${key}`, value);
    formData.append("output_format", outputFormat);

    const response = await axios.post(API_ENDPOINT, formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    setLoadingState(false);

    const fileExtension = outputFormat === "pdf" ? "pdf" : "csv";
    const timestamp = dayjs().format("DD-MM-YYYY");
    const filename = `${fileNameProp} - ${timestamp}.${fileExtension}`;

    if (outputFormat === "pdf") {
      const pdf = createPdf(response.data.response, fileNameProp, rfqData);
      pdf.save(`${fileNameProp} - ${timestamp}.pdf`);
    } else {
      const blob = new Blob([response.data], {
        type: `application/${fileExtension}`,
      });
      saveAs(blob, filename);
    }
  } catch (error) {
    console.error(error);
    setLoadingState(false);
  }
};

const createPdf = (data, fileNameProp, rfqData) => {
  const doc = new jsPDF({
    orientation: rfqData &&"landscape", // Use landscape for RFQ, portrait otherwise
    unit: rfqData && "px", // Set dimensions in pixels
    format: rfqData && [1920, 1080], // Custom size for RFQ, A4 for others
  });

  // Add company logo
  doc.addImage(imgData, "png", 12, 3, 22, 8);
  doc.setFontSize(12);
  doc.text(`${fileNameProp}`, 14, 16);

  // Define columns dynamically based on field names
  const fieldNames = Object.keys(data[0]);
  const columns = fieldNames.map((fieldName) => ({
    header: fieldName,
    dataKey: fieldName,
  }));
  // check if rfqData is true
  if (rfqData) {
    const totalColumns = fieldNames.length;
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20; // Left and right margins
    const columnWidth = (pageWidth - margin * 2) / totalColumns; // Equal width for all columns

    doc.autoTable({
      theme: "grid",
      headStyles: {
        fillColor: "#3B86C7",
        textColor: [255, 255, 255],
        fontStyle: "bold",
        halign: "center", // Center-align headers
      },
      bodyStyles: {
        fontSize: 8, // Smaller font size for better fit
        cellPadding: 2, // Moderate padding for readability
      },
      columns: columns, // Dynamically generated columns
      body: data, // Data from the backend
      startY: 25, // Start below the title/logo
      margin: { left: margin, right: margin }, // Set margins
      tableWidth: "auto", // Dynamically adjust table width
      styles: {
        overflow: "linebreak", // Ensure text wraps inside cells
        halign: "center", // Center-align all text
      },
      columnStyles: fieldNames.reduce((styles, _, index) => {
        styles[index] = { cellWidth: columnWidth }; // Equal width for all columns
        return styles;
      }, {}),
    });
  } else {
    // Default PDF for non-RFQ data
    doc.autoTable({
      theme: "grid",
      headStyles: {
        fillColor: "#3B86C7",
        textColor: [255, 255, 255],
        overflow: "linebreak",
        cellWidth: "wrap",
      },
      columns: columns, // Dynamically generated columns
      body: data, // Data from the backend
      margin: { top: 20 },
      tableWidth: 200,
      styles: {
        overflow: "linebreak", // Ensure text wraps inside cells
      },
      horizontalPageBreak: true, // Allow page breaks for large tables
    });
  }

  return doc;
};

export { downloadFile };

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: []
}

export const VendorContactsData = createSlice({
    name: 'VendorContactsData',
    initialState,
    reducers: {
        setVendorContactsData: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setVendorContactsData } = VendorContactsData.actions;
export default VendorContactsData.reducer;
import React, { memo } from 'react'
import classes from './ReportCard.module.css'
import { Skeleton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
const Reportcard = ({ reportImg, title, value, loadingState, size, customClass, grade }) => {
    return (
        <div className={`${classes.rc_wrap} ${customClass}`} style={{ width: `${size}` }}>
            {
                !loadingState ?
                    <>
                        <div className={classes.title}>
                            <span>{title}</span>
                            <img src={reportImg} alt="" />
                        </div>

                        <div className={classes.value}>
                            <Tooltip describeChild title={<span style={{ fontSize: "12px" }}>{value}</span>}>
                                <p>{value}</p>
                            </Tooltip>
                            {/* <div className={classes.grade}>
                                {grade}
                            </div> */}
                        </div>
                    </>
                    :
                    <>
                        <div className={classes.title}>
                            <span><Skeleton width={"60px"} height={"25px"} /></span>
                            <Skeleton variant='circular' width={'40px'} height={'40px'} />
                        </div>

                        <div className={classes.value}>
                            <p><Skeleton width={'130px'} height={"40px"} variant='text' /></p>
                            {/* <div className={classes.grade}>
    {grade}
</div> */}
                        </div></>

            }

        </div>
    )
}

export default memo(Reportcard)
import React, { useState } from "react";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
// css
import classes from "./PDFView.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFView = ({ fileSrc, scaleDoc, customWrapperClass }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  let sourcename = fileSrc && fileSrc?.split("/");
  let fileName = sourcename[sourcename?.length - 1];

  // let sourceExtension = imgSrc && imgSrc.split(".");
  // let extension = sourceExtension[sourceExtension.length - 1];

  return (
    <div className={`${classes.pdfWrapper} ${customWrapperClass}`}>
      <p>{fileName}</p>
      <Document
        file={fileSrc}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <Page pageNumber={currentPage} className={classes.pdfPage} />
      </Document>

      <div className={classes.pdfBtn}>
        <p>
          {currentPage} - {numPages} Pages
        </p>
        <div className={classes.flex}>
          <Button className={classes.button} onClick={handlePrevPage}>
            <ArrowLeft
              sx={{
                fontSize: "2.4rem",
                color: "#000",
              }}
            />
          </Button>
          <Button className={classes.button} onClick={handleNextPage}>
            <ArrowRight
              sx={{
                fontSize: "2.4rem",
                color: "#000",
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PDFView;

import React, { memo } from "react";
import classes from "./DRadioInputs.module.css";

const DRadioInput = ({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  customClass,
}) => {
  const handleRadioChange = (event) => {
    onChange(event.target.checked, event.target.value);
  };

  return (
    <div className={`${classes.cus_radio} ${customClass}`}>
      <input
        type="radio"
        id={id}
        value={value}
        name={name}
        checked={checked}
        onChange={handleRadioChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default memo(DRadioInput);

import React, { memo } from 'react';

const LinearProgressBar = ({ progress, color, label }) => {

    const mainContainer = {
        display: "flex",
        alignItems: "flex-start",
        jusctifyContent: 'center',
        flexDirection: 'column',
        fontFamily: 'GilroyMedium',
        fontSize: '14px',
        marginBottom: '2rem'
    }

    const containerStyle = {
        width: '100%',
        height: '5px',
        backgroundColor: '#f0f0f0',
        borderRadius: '50px',
        overflow: 'hidden',
    };

    const progressBarStyle = {
        width: `${progress}%`,
        height: '100%',
        borderRadius: '50px',
        backgroundColor: `${color}`,
        transition: 'width 0.5s',
    };

    const labelStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: '#000',
        fontFamily: 'NSRegular',
        marginBottom: '0.5rem',
        fontSize: '1.4rem',
    };

    return (
        <div style={mainContainer}>
            <span style={labelStyle}>{label}</span>
            <div style={containerStyle}>
                <div style={progressBarStyle}></div>
            </div>
        </div>
    );
};

export default memo(LinearProgressBar);

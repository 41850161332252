import React, { memo, useEffect, useState } from 'react';
import classes from "./Homebuttons.module.css"
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';

const DropDownButton = ({ noOptions, mainClick, options, label }) => {

    //navigator
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogin = (path) => {
        navigate(path)
    };

    // /for toggle button
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`${classes.login_dropdown}`}>
            <Button
                className={`${classes.dropdown_toggle}`} onClick={(e) => {
                    !noOptions ? handleClick(e) : mainClick()
                }}
                sx={{
                    position: " relative",
                    background: " none",
                    border: " none",
                    cursor: " pointer",
                    fontSize: "1.35rem",
                    border: " 1.5px solid #adadad80",
                    padding: " 0.8rem 2.8rem",
                    borderRadius: " 30px",
                    color: " #333",
                    fontFamily: "Source Sans SemiBold",
                }}
            >
                {label}
            </Button>

            {/* {!noOptions && isOpen ? (
                <div className={`${classes.dropdown_menu}`}>
                    {options?.map((e, i) => (
                        <li key={i} onClick={() => handleLogin(`/${e.path}`)}>{e.path_name}</li>
                    ))}
                </div>
            ) : ''} */}
            {!noOptions &&
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                        transform: 'translate(0px,8px)',
                        ".MuiMenu-paper": {
                            boxShadow: "0px 6px 24px #00000017",
                        }
                    }}
                >
                    {options?.map((e, i) => (
                        <MenuItem key={i} onClick={() => handleLogin(`/${e.path}`)} sx={{
                            fontSize: "1.35rem"
                        }}>{e.path_name}</MenuItem>
                    ))}
                </Menu>}
        </div >
    );
};

export default memo(DropDownButton);

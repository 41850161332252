import React, { memo } from "react";
import classes from "./ImgFrame.module.css";

const ImgFrame = ({
  imgSrc,
  imgAlt,
  imgClassName,
  imgSize,
  customClass,
  ImgClick,
}) => {
  return (
    <div
      className={`${classes.br_logo} flex ${customClass}`}
      onClick={ImgClick}
      style={{ width: `${imgSize}` }}
    >
      <img src={imgSrc} alt={imgAlt} className={imgClassName} />
    </div>
  );
};

export default memo(ImgFrame);

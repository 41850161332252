import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
//css
import classes from "../DashboardStyles/DashboardStyles.module.css";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const OrganisationDashboard = () => {
  const sideBarToggled = useSelector((data) => data?.sideBarToggle.value);

  return (
    <div className={`${classes.dashboard_wrapper}`}>
      <Sidebar />
      <div
        className={
          sideBarToggled ? classes.mainContentClose : classes.mainContent
        }
      >
        <Outlet />
      </div>
    </div>
  );
};

export default OrganisationDashboard;

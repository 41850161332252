import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: []
}

export const loginDataSlice = createSlice({
    name: 'loginData',
    initialState,
    reducers: {
        setLoginData: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { setLoginData } = loginDataSlice.actions;
export default loginDataSlice.reducer;
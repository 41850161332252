import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: []
}

export const buyerRFQDataSlice = createSlice({
    name: 'buyerRFQData',
    initialState,
    reducers: {
        setBuyerRFQData: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setBuyerRFQData } = buyerRFQDataSlice.actions;
export default buyerRFQDataSlice.reducer;
import React from 'react';
import classes from "./Attachment.module.css";
import { AttachmentOutlined, DeleteOutline } from "@mui/icons-material";

const Attachment = (props) =>{
    return (
        <div style={{marginTop: '10px' }}>
            <p className={classes.attachmentHeading}>
                <label htmlFor="file-upload" className={classes.fileAttachemnt}>
                    <AttachmentOutlined sx={{ fontSize: "25px" }} /> <span className={classes.attachmentText}>Attach a file</span>
                </label>
            </p>
            {props?.msg_file.length > 0 &&
                (<><p className={`${classes.attachmentHeading} ${classes.attachedFile}`}>Attached Files:</p><ul className={`${classes.attachmentHeading} ${classes.attachedFiles}`}>
                    {props?.msg_file.map((file, index) => {
                        return (
                            <li key={index}>
                                {file.name} <span onClick={() => props?.handleRemoveFile(index)} className={classes.removeAttachment}>&times;</span>
                            </li>
                        )
                    })}
            </ul></>)}
            <input id="file-upload" type="file" accept="/" onChange={props?.handleFileChange} style={{ display: 'none' }} />
        </div>
    )  
}

export default  Attachment;



import React, { memo } from "react";
import classes from "./DownloadFileButton.module.css";
import { Download } from "@mui/icons-material";
import { saveAs } from "file-saver";

const DownloadFileButton = ({ file, fileName }) => {
  const downloadFile = () => {
    saveAs(file, fileName);
  };

  return (
    <div className={classes.btnWrap} onClick={downloadFile}>
      <span>{fileName}</span>
      <Download
        sx={{
          fontSize: "1.8rem",
        }}
      />
    </div>
  );
};

export default memo(DownloadFileButton);

import React, { memo, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import classes from '../ChartStyles.module.css';
import ChartIndicator from '../../ChartIndicator/ChartIndicator';
import { Skeleton } from '@mui/material';

const HorizontalBarChart = ({ chartData, loadingState, chartDataSet, filterOnChange, customCardClass, approveData }) => {
    const today = new Date();
    const lastSixMonths = new Array(6).fill().map((_, index) => {
        const date = new Date(today);
        date.setMonth(today.getMonth() - index);
        return date;
    });

    const [selectedOption, setSelectedOption] = useState('last six months');
    const [formattedLabels, setFormattedLabels] = useState([]);

    useEffect(() => {
        const startDate = getStartDate();
        const endDate = new Date();

        const newFormattedLabels = lastSixMonths
            .filter(date => date >= startDate && date <= endDate)
            .map(date => dayjs(date).format('MMM'))
            .reverse();

        setFormattedLabels(newFormattedLabels);
    }, [selectedOption]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        filterOnChange && filterOnChange(event.target.value);
    };

    const getStartDate = () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();

        switch (selectedOption) {
            case 'this month':
                return new Date(currentYear, currentMonth, 1);

            case 'last month':
                return new Date(currentYear, currentMonth - 1, 1);

            case 'last six months':
                return new Date(currentYear, currentMonth - 6, 1);

            default:
                return new Date(currentYear, currentMonth, 1);
        }
    };

    const dataSet = {
        labels: chartDataSet?.chartYAxisLabels,
        datasets: chartData
    };

    const optionsDataSet = {
        indexAxis: 'y',
        plugins: {
            legend: false,
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        let value = Math.abs(context.parsed.x);
                        if(approveData){
                            if (label.includes('Response Time In Minutes')) {
                                // MRT should display in minutes
                                return `${label}: ${value}min`;
                            } else if (label.includes('Response Time In Hours')) {
                                // ART should display in hours
                                return `${label}: ${value}hr`;
                            }
                            return `${label}: ${value}`;
                        }else{
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.x !== null) {
                                label += Math.abs(context.parsed.x);
                            }
                            return `${label}hr`;
                        }
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                border: {
                    dash: [6],
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        if(value > 0) {
                            return `${value} ${approveData ?'' : '(ART)'}`
                        } else if(value === 0) {
                            return value
                        }
                        else {
                            return `${Math.abs(value)} (MRT)`
                        }
                    }
                },
            },
            y: {
                grid: {
                    display: false
                },
            },
        },
    };

    return (
        <div className={`${classes.barsCard} ${customCardClass}`}>
            {
                !loadingState ?
                    <>
                        <div className={classes.linearProgressInline}>
                            <div>
                                <span>{chartDataSet?.cardHeading}</span>
                                <div className={classes.indicators}>
                                    {
                                        chartDataSet?.chartIndicators?.map((e) => (
                                            <span key={e.value}><ChartIndicator label={e.value} color={e.color} /></span>
                                        ))
                                    }
                                </div>
                            </div>
                            {/* <div className={classes.monthPicker}>
                                <span>Show : </span>
                                <select className={classes.filterDropdown} onChange={handleOptionChange} value={selectedOption}>
                                    <option value="this week">This week</option>
                                    <option value="this month">This month</option>
                                    <option value="last month">Last month</option>
                                    <option value="last six months">Last six months</option>
                                </select>
                            </div> */}
                        </div>
                        <Bar data={dataSet} options={optionsDataSet} style={{ maxHeight: '280px' }} />

                    </>
                    :
                    <>
                        <div className={classes.linearProgress} style={{
                            marginBottom: '0'
                        }}>
                            <span><Skeleton /></span>
                            <div className={classes.indicators}>
                                <Skeleton width={"70%"} />
                            </div>
                        </div>

                        <div className={`${classes.flex} ${classes.lineSkeleton}`}>

                            <Skeleton width={"100%"} height={"350px"} />

                        </div>
                    </>
            }

        </div>
    );
};

export default memo(HorizontalBarChart);

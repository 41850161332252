const fomateDateString = (date)=> {
    const originalDate = new Date(date.slice(0).replace('-', ' '));
    const formattedDate = originalDate.toLocaleString('en-US', { year: '2-digit', month: 'short' });
    return formattedDate
}
const formateWeekString = (week)=> {
    const splitedValue = week.split(' ');
    return `(${splitedValue[0].slice(0,1)}${splitedValue[1]})`;
}
export const weeklyReportObjFormate1 = (data,from) => {
    let formattedData = {};
    for (let key in data) {
        for (let subKey in data[key]) {
            formattedData[`${fomateDateString(key)}-${formateWeekString(subKey)}`] = data[key][subKey];
        }
    }
    return formattedData;
};
export const weeklyReportObjFormate = (data, from) => {
    let formattedData = {};
    const sortedKeys = Object.keys(data).sort(); // Sort keys (dates) in ascending order
    for (let key of sortedKeys) {
        for (let subKey in data[key]) {
            formattedData[`${fomateDateString(key)}-${formateWeekString(subKey)}`] = data[key][subKey];
        }
    }
    return formattedData;
};
export const monthlyReportObjFormate = (data)=> data?.map((item)=>{
    const originalDate = new Date(item);
    const formattedDate = originalDate.toLocaleString('en-US', { year: '2-digit', month: 'short' });
    return formattedDate
});
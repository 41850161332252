import React from "react";
import classes from "./AttachmentList.module.css";
import { saveAs } from "file-saver";

const AttachmentList = (props) => {
  return (
    props?.item?.document && props?.item?.document.length > 0 ? (
      <div className={classes.box_loyout_right}>
        <div className={classes.padding_box}>
          <p className={classes.left_titel}>Attachment</p>
          {props.item.document.map((doc, docIndex) => {
            const isSupportedFile =/\.(pdf|csv|xls|xlsx|png|pptx|ppt|jpg|jpeg|doc|docx|txt|svg|odt|rtf)$/i.test(doc.url);
            const attachmentUrl = isSupportedFile ?`${process.env.REACT_APP_CLOUDFRONT_URL}/${doc.url.replace(
              "https://teckost.s3.amazonaws.com/",
              ""
            )}`:null;
            const fileName = doc.name || "Attachment";
            return (
              <p key={docIndex} className={classes.right_progrop}>
                {attachmentUrl ?(
                <a
                  href={attachmentUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    saveAs(attachmentUrl, fileName);
                  }}
                  download={fileName} rel="noreferrer"
                >
                  {fileName}
                </a>):''}
              </p>
            );
          })}
        </div>
      </div>
    ) : null
  );
};

export default AttachmentList;

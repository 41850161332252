import { useState, useEffect } from 'react';

function useDynamicTable(data) {
  // State for storing updated data
  const [updatedData, setUpdatedData] = useState([]);

  // Update data when props change
  useEffect(() => {
    if (!Array.isArray(data)) {
      console.error("Invalid data format. Expected an array.");
      return;
    }
    // Logic to update data based on props.data
    let allKeys = new Set();
    data?.forEach(item => {
      Object.keys(item).forEach(key => allKeys?.add(key));
    });

    const updatedData = data?.map(item => {
      const newItem = { ...item };
      allKeys?.forEach(key => {
        if (!(key in newItem)) {
          newItem[key] = "Not Available"; // You can set default value if needed
        }
      });
      return newItem;
    });
    setUpdatedData(updatedData);
  }, [data]);

  return updatedData;
}

export default useDynamicTable;
import { createContext, useState } from "react";

export const CurrencyContext = createContext();

const CurrencyProvider = ({ children }) => {
  const [fromCurrency, setFromCurrency] = useState({
    flag: '🇮🇳',
    flags: 'https://flagcdn.com/in.svg',
    countryName: 'India',
    currency: 'INR'
  });
  const [toCurrency, setToCurrency] = useState({
    flag: '🇮🇳',
    flags: 'https://flagcdn.com/in.svg',
    countryName: 'India',
    currency: 'INR'
  });
  const [firstAmount, setFirstAmount] = useState("");
  const [countryName, setCountryName] = useState("");

  const value = {
    fromCurrency,
    setFromCurrency,
    toCurrency,
    setToCurrency,
    firstAmount,
    setFirstAmount,
    countryName,
    setCountryName,
  };
  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_ENDPOINT = {
  // base url
  Base_URL: `${BASE_URL}`,

  // contact us
  contact_us: `${BASE_URL}/api/v1/contact_us`,

  //login page
  login: `${BASE_URL}/api/v1/login_view`,

  //adminlogin page
  admin_login: `${BASE_URL}/api/v1/admin_login_view`,

  // reset password
  reset_password: `${BASE_URL}/api/v1/reset_password_org`,

  change_password: `${BASE_URL}/api/v1/change_password`,

  //email verification for forgot password
  verify_email: `${BASE_URL}/api/v1/check_email_verify`,

  //forgot password
  forgot_password_send_otp: `${BASE_URL}/api/v1/forgotpassword_send_otp`,

  forgot_password_resend_otp: `${BASE_URL}/api/v1/forgotpassword_resend_otp`,

  forgot_password_verify_email_otp: `${BASE_URL}/api/v1/verify_forgotpassword_otp`,

  //buyer organization signup
  verify_company_registration_number: `${BASE_URL}/api/v1/verify_company_register_number`,

  buyer_signup: `${BASE_URL}/api/v1/buyer_registration`,

  buyer_signup_send_otp: `${BASE_URL}/api/v1/buyer_onboard_otp`,

  buyer_signup_resend_otp: `${BASE_URL}/api/v1/buyer_onboard_resend_otp`,

  buyer_signup_verify_email_otp: `${BASE_URL}/api/v1/verify_onboard_mail_otp`,

  cheque_leaf_extract: `${BASE_URL}/api/v1/cheque_leaf_extract`,

  extract_mems_and_numbers: `${BASE_URL}/api/v1/extract_mems_and_numbers`,

  pan_extract: `${BASE_URL}/api/v1/pan_extract`,

  extract_CIN_number: `${BASE_URL}/api/v1/extract_CIN_number`,

  extract_gst_details: `${BASE_URL}/api/v1/extract_gst_details`,

  // add user or buyer
  add_user: `${BASE_URL}/api/v1/user_add`,

  add_item: `${BASE_URL}/api/v1/item_add`,

  // vendor signup
  vendor_signup: `${BASE_URL}/api/v1/vendor_registration`,

  vendor_signup_send_otp: `${BASE_URL}/api/v1/vendor_onboard_otp`,

  vendor_signup_resend_otp: `${BASE_URL}/api/v1/vendor_onboard_resend_otp`,

  vendor_signup_verify_mail_otp: `${BASE_URL}/api/v1/vendor_verify_mail_otp`,

  // get all users or buyers
  get_all_users: `${BASE_URL}/api/v1/user_view`,

  // CRUD users or buyers
  update_user: `${BASE_URL}/api/v1/user_update?user_token=`,

  delete_user: `${BASE_URL}/api/v1/user_delete`,

  get_unique_user: `${BASE_URL}/api/v1/user_individual_view?user_token=`,

  //buyer shipping address
  buyer_shipping_address: `${BASE_URL}/api/v1/buyer_shipping_address?user_token=`,

  // CRUD items
  get_all_items: `${BASE_URL}/api/v1/item_view`,
  buyer_item_view: `${BASE_URL}/api/v1/buyer_item_view`,

  get_individual_item: `${BASE_URL}/api/v1/item_individual_view?item_token=`,

  get_individual_item_by_item_code: `${BASE_URL}/api/v1/item_individual_view?item_token=`,

  update_item: `${BASE_URL}/api/v1/item_update?user_token=`,

  delete_item: `${BASE_URL}/api/v1/item_delete`,

  permanent_delete_item: `${BASE_URL}/api/v1/permanant_item_delete`,

  add_bulk_items: `${BASE_URL}/api/v1/item_bulk_add`,

  //CRUD vendors
  get_all_vendor: `${BASE_URL}/api/v1/vendor_view?page=`,

  //vendor list for organisation
  get_all_org_vendor: `${BASE_URL}/api/v1/org_vendor_list`,

  buyer_vendor_view: `${BASE_URL}/api/v1/buyer_vendor_view`,

  get_individual_vendor: `${BASE_URL}/api/v1/vendor_individual_view?`,

  delete_vendor: `${BASE_URL}/api/v1/vendor_delete`,

  invite_vendor: `${BASE_URL}/api/v1/invite_vendor`,

  add_vendor: `${BASE_URL}/api/v1/vendor_add`,

  vendor_dropdown_list: `${BASE_URL}/api/v1/vendor_dropdown_list`,

  vendordelete_in_org: `${BASE_URL}/api/v1/vendordelete_in_org`,

  get_prompt_table: `${BASE_URL}/api/v1/get_prompt_table`,

  update_prompt_table: `${BASE_URL}/api/v1/update_prompt_table`,

  list_of_prompt_category: `${BASE_URL}/api/v1/list_of_prompt_category`,

  create_prompt_table: `${BASE_URL}/api/v1/create_prompt_table`,

  //three types of RFQ

  //organisation RFQ
  get_all_org_RFQ: `${BASE_URL}/api/v1/view_org_rfq`,

  get_org_bid_view: `${BASE_URL}/api/v1/org_bid_view`,

  //buyer RFQ
  get_all_buyer_RFQ: `${BASE_URL}/api/v1/view_buyer_rfq`,

  new_RFQ: `${BASE_URL}/api/v1/add_rfq_multiple_item`,

  edit_RFQ: `${BASE_URL}/api/v1/multiple_item_rfq_update?rfq_token=`,

  delete_RFQ: `${BASE_URL}/api/v1/rfq_delete?rfq_token=`,

  rfq_vendor_email_verification: `${BASE_URL}/api/v1/vendor_email_verification`,

  rfq_comparison_nanonets: `${BASE_URL}/api/v1/nanonets_comparison?buyer_token=`,

  rfq_comparison_azure: `${BASE_URL}/api/v1/azure_comparison?buyer_token=`,

  rfq_comparison_GPT: `${BASE_URL}/api/v1/chatgpt`,

  rfq_comparison_DOCSUMO: `${BASE_URL}/api/v1/comparison_docsumo`,

  rfq_newversion_chatgpt: `${BASE_URL}/api/v1/rfq_newversion_chatgpt`,

  comparison_rfq_history: `${BASE_URL}/api/v1/comparison_rfq_history`,

  rfq_add_new_vendor: `${BASE_URL}/api/v1/rfq_add_new_vendor`,

  enable_vendor_list: `${BASE_URL}/api/v1/enable_vendor_list`,

  list_of_msg: `${BASE_URL}/api/v1/list_of_msg`,

  get_purchase_order_list: `${BASE_URL}/api/v1/get_purchase_order_list`,

  create_selected_PO: `${BASE_URL}/api/v1/create_selected_PO`,

  update_purchase_order: `${BASE_URL}/api/v1/update_purchase_order`,

  detele_purchase_order: `${BASE_URL}/api/v1/detele_purchase_order`,

  send_email_purchase_order: `${BASE_URL}/api/v1/send_email_purchase_order`,

  completed_pr_rfq_draft: `${BASE_URL}/api/v1/completed_pr_rfq_draft`,

  //items view for new rfq
  view_all_item: `${BASE_URL}/api/v1/view_all_item`,

  //Vendor RFQ
  vendor_csv_upload: `${BASE_URL}/api/v1/vendor_csv_upload`,

  get_all_vendor_RFQ: `${BASE_URL}/api/v1/view_vendor_rfq`,

  get_vendor_all_bids: `${BASE_URL}/api/v1/vendor_bid_view`,

  update_vendor: `${BASE_URL}/api/v1/vendor_update?vendor_token=`,

  update_encrypt_data_in_vendor: `${BASE_URL}/api/v1/update_encrypt_data_in_vendor?vendor_token=`,

  add_bids: `${BASE_URL}/api/v1/new_add_bids`,

  select_bid: `${BASE_URL}/api/v1/select_bid`,

  get_vendor_contacts: `${BASE_URL}/api/v1/vendor_user_list`,

  add_vendor_contact: `${BASE_URL}/api/v1/add_vendor_contact`,

  delete_vendor_contact: `${BASE_URL}/api/v1/vendorcontact_delete`,

  get_individual_RFQ: `${BASE_URL}/api/v1/rfq_individual_items_view?rfq_token=`,

  requote_bid: `${BASE_URL}/api/v1/resubmit_request_email`,

  vendor_msg_box: `${BASE_URL}/api/v1/vendor_msg_box`,

  rfq_chat_box: `${BASE_URL}/api/v1/rfq_chat_box`,

  //comparison analytics list

  comparison_analytics: `${BASE_URL}/api/v1/comparison_files`,

  // dashboard analytics apis
  buyer_dashboard: `${BASE_URL}/api/v1/buyer_dashbord`,

  new_buyer_dashbord: `${BASE_URL}/api/v1/new_buyer_dashbord`,

  org_dashboard: `${BASE_URL}/api/v1/org_dashboard`,

  new_org_dashboard: `${BASE_URL}/api/v1/new_org_dashboard`,

  vendor_dashboard: `${BASE_URL}/api/v1/vendor_dashbord`,

  vendor_dashboard_new: `${BASE_URL}/api/v1/vendor_dashbord_new`,

  new_vendor_dashbord: `${BASE_URL}/api/v1/new_vendor_dashbord`,

  getBuyerList: `${BASE_URL}/api/v1/customer_list`,

  getItemCodes: `${BASE_URL}/api/v1/item_code_list`,

  getCompanyList: `${BASE_URL}/api/v1/company_list`,

  getOrgBuyerList: `${BASE_URL}/api/v1/buyer_list`,

  getNotifications: `${BASE_URL}/api/v1/notifications`,

  unreadNotifications: `${BASE_URL}/api/v1/unread_notification`,

  vendor_customer_list: `${BASE_URL}/api/v1/vendor_customer_list`,

  vendor_company_list: `${BASE_URL}/api/v1/vendor_company_list`,

  user_update_data_encrypt: `${BASE_URL}/api/v1/user_update_data_encrypt?user_token=`,

  //org download list apis
  csv_user_view: `${BASE_URL}/api/v1/csv_user_view`,
  csv_org_vendor_list: `${BASE_URL}/api/v1/csv_org_vendor_list`,
  csv_view_org_rfq: `${BASE_URL}/api/v1/csv_view_org_rfq`,
  csv_org_item_view: `${BASE_URL}/api/v1/csv_org_item_view`,
  csv_buyer_item_view: `${BASE_URL}/api/v1/csv_buyer_item_view`,
  csv_vendor_user_list: `${BASE_URL}/api/v1/csv_vendor_user_list`,

  vendor_csv_rfq: `${BASE_URL}/api/v1/vendor_csv_rfq`,

  buyer_rfq_csv: `${BASE_URL}/api/v1/buyer_rfq_csv`,

  purchase_order_csv: `${BASE_URL}/api/v1/purchase_order_csv`,

  buyer_vendor_csv: `${BASE_URL}/api/v1/buyer_vendor_csv`,
  csv_buyer_vendor_list: `${BASE_URL}/api/v1/csv_buyer_vendor_list`,

  vendor_csv_rfq: `${BASE_URL}/api/v1/vendor_csv_rfq`,

  get_pr_rfq_draft: `${BASE_URL}/api/v1/get_pr_rfq_draft`,

  delete_pr_rfq_draft: `${BASE_URL}/api/v1/delete_pr_rfq_draft`,

  //country state city dropdown APIs
  view_countries: `${BASE_URL}/api/v1/view_countries`,
  view_state: `${BASE_URL}/api/v1/view_state`,
  view_cities: `${BASE_URL}/api/v1/view_cities`,
  rfq_chat: `${BASE_URL}/api/v1/rfq_chat`,
  view_rfq_chat: `${BASE_URL}/api/v1/view_rfq_chat`,
  view_rfq_vendors: `${BASE_URL}/api/v1/view_rfq_vendors`,

  // compair bidz in websocket
  check_prn_number_unic: `${BASE_URL}/api/v1/check_prn_number_unic`,
  newversion_chatgpt: `${BASE_URL}/api/v1/newversion_chatgpt`,
  updated_chatgpt_compare: `${BASE_URL}/api/v1/updated_chatgpt_compare`,
  response_data: `${BASE_URL}/api/v1/response_data`,
  comparison_history: `${BASE_URL}/api/v1/comparison_history`,

   // get All Department
   get_Department:`${BASE_URL}/api/v1/org_department_list`,

  // Get user List
    user_list:`${BASE_URL}/api/v1/org_users_list`,

  //slab Config
  slab_config: `${BASE_URL}/api/v1/slab_config`,
  slab_config_list : `${BASE_URL}/api/v1/slab_config_list`,
  // Cost Center
  add_cost_center:`${BASE_URL}/api/v1/add_cost_center`,
  cost_center_list:`${BASE_URL}/api/v1/cost_center_list`,
  add_bulkcode:`${BASE_URL}/api/v1/add_bulkcode`,

  
  // GL
  add_gl_code:`${BASE_URL}/api/v1/add_gl_code`,
  gl_code_list:`${BASE_URL}/api/v1/gl_code_list`,
  add_bulkglcode:`${BASE_URL}/api/v1/add_bulkglcode`,

  

  // Projects
  add_projects:`${BASE_URL}/api/v1/add_projects`,
  projects_list:`${BASE_URL}/api/v1/projects_list`,
  add_bulkprojectcode:`${BASE_URL}/api/v1/add_bulkprojectcode`,

  
  
  // GST
  add_gst:`${BASE_URL}/api/v1/add_gst`,
  gst_list:`${BASE_URL}/api/v1/gst_list`,
  
  
  get_all_gst:`${BASE_URL}/api/v1/get_all_gst`,


  tax_bulk_add:`${BASE_URL}/api/v1/tax_bulk_add`,
  
  hierarchy_approval: `${BASE_URL}/api/v1/hierarchy_approval`,
  approvalNotifications: `${BASE_URL}/api/v1/approval_notifications`,
};

export { API_ENDPOINT };

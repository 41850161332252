import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 8,
    },
  }));
  
  const getStatusColor = (status) => {
    if (status === 2) return 'green';
    if (status === 1) return 'red';
    return '#ffc107';
  };

  const ApproverTooltip = ({ approvers, children }) => {
    const loginData = useSelector((state) => state.loginData.value);
    
    return (
        <LightTooltip
            title={
                <div style={{ padding: '2px' }}>
                    <table>
                        <thead>
                            <tr style={{ height: '40px' }}>
                                <th style={{ padding: '2px 4px', textAlign: 'left' }}>Comments</th>
                                <th style={{ padding: '2px 4px', textAlign: 'left' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {approvers && approvers.map((approver, index) => (
                                <tr key={index} style={{ height: '40px' }}>
                                    <td style={{ padding: '2px 4px' }}>{approver.account_id__org_id__first_name}</td>
                                    <td>
                                        {approver.timeline_comment}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            arrow
            placement="top"
        >
            {children}
        </LightTooltip>
    );
};
  
  export default ApproverTooltip;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: false
}

export const sideBarToggleAction = createSlice({
    name: 'sideBarToggle',
    initialState,
    reducers: {
        setSideBarToggle: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setSideBarToggle } = sideBarToggleAction.actions;
export default sideBarToggleAction.reducer;
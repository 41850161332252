const previouPageClick = (pageNumber, setPageNumber) => {
  if (pageNumber > 1) {
    setPageNumber(parseInt(pageNumber) - 1);
  }
};

const nextPageClick = (pageNumber, setPageNumber, paginationData) => {
  if (pageNumber <= paginationData?.page_count) {
    setPageNumber(parseInt(pageNumber) + 1);
  }
};

export { previouPageClick, nextPageClick };

import React, { useEffect,useContext, useState } from "react";
//img
import profileImg from "../../../assets/images/png/Profile.png";
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
import deletePopupImg from "../../../assets/images/png/Group 2@2x.png";
import plusIcon from "../../../assets/images/svg/plusIcon.png";
//css
import classes from "./slab.module.css";
//components
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import DateBar from "../../../components/common/DateBar/DateBar";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DataTable from "../../../components/common/DataTable/DataTableSorting";
import { Box, Fade, Modal, Zoom } from "@mui/material";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import DSelectInput from "../../../components/Dashboard/DashboardInputs/DSelectInput";
// import DCheckboxInput from "../../../components/Dashboard/DashboardInputs/DCheckboxInput";
import PopupFrame from "../../../components/Popups/PopupFrame/PopupFrame";
import InfoLabel from "../../../components/common/InfoLabel/InfoLabel";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
//API
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_ENDPOINT } from "../../../services/api/resources";
import { setItemsData } from "../../../features/ItemsDataSlice";
import { emptyCheck, validateFile, validateNumber } from "../../../utils/validations";
import { MoreHoriz } from "@mui/icons-material";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
//custom hooks
import useFetchData from "../../../hooks/useFetchData";
import DFileUpload from "../../../components/Dashboard/DashboardInputs/DFileUpload";
import DefaultLabel from "../../../components/Dashboard/DefaultLabel/DefaultLabel";
//smaple xl/ csv file for download
import sampleCSV from "../../../assets/files/Sample Items CSV.xlsx";
import { saveAs } from "file-saver";
import unspscCodes from "../../../data/unspsc.json";
import hsnCodes from "../../../data/hsn.json";
import { getUniqueData } from "../../../utils/getUniqueData";
import { downloadFile } from "../../../utils/downloadFile";
import DropdownBtnOutlined from "../../../components/Dashboard/DropdownButtons/DropdownBtnOutlined/DropdownBtnOutlined";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import set from "date-fns/fp/set/index";
import { CurrencyContext } from "../../../context/CurrencyContext";
import SelectCountry from "../../../components/Dashboard/amount/SelectCountry";


const SlabConfig = () => {
  //dispatcher
  const dispatch = useDispatch();

  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  // const itemsData = '';

  //popuptext
  const [popupHeroText, setPopupHeroText] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  //search filtered data
  const [searchQuery, setSearchQuery] = useState("");

  const [sortingColumnName, setSortingColumnName] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  const order = sortingOrder === null || sortingOrder === "asc" ? "desc" : "asc";
  //unique user
  const [uniqueData, setUniqueData] = useState();
  const [uniqueDataID, setUniqueDataID] = useState("");

  //credentials
  const [auth_token, setAuthToken] = useState(data?.auth_token);
  const [user_token, setUserToken] = useState(data?.user?.user_token);
  const [org_token, setOrgToken] = useState(data?.user?.token);
  const [pageNumber, setPageNumber] = useState(1);

  //pagination data
  const [paginationData, setPaginationData] = useState();

  //get org users
  const [users, setUsers]  = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [userError, setUserError] = useState(false);
  const [orgName, setOrgName] = useState();
  const [orgId, setOrgId] = useState();
  const [configUsers, setConfigUsers]  = useState([]);

  //pagination logics
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;

  //btn label
  const [loadingState, setLoadingState] = useState(false);
  const [loadingStateDwnload, setLoadingStateDwnload] = useState(false);

  //button label
  const [primaryBtnLabel, setPrimaryBtnLabel] = useState();

  //toggle add/update actions
  const [itemsAction, setItemsAction] = useState({
    addItem: false,
    updateItem: false,
    addBulkItem: false,
  });

  const [UNSPSCList, setUNSPSCList] = useState(
    unspscCodes?.map((e) => e.commodity + " - " + e.commodity_name)
  );
  const [HSNList, setHSNList] = useState(
    hsnCodes?.map((e) => e.hsn_code + " - " + e.description)
  );

  // error message
  const [primaryBtnError, setPrimaryBtnError] = useState({
    error: false,
    errorMsg: "",
  });
  const [tableProps, setTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });

  const [approvers, setApprovers] = useState([]);

  const {
    fromCurrency,
    setFromCurrency,
    toCurrency,
    countryName,
    setToCurrency,
    firstAmount,
    setFirstAmount,
  } = useContext(CurrencyContext);

  const regexForAlphabets = /[^a-zA-Z\s]/g;
  const regexForAlphaNumeric = /[^a-zA-Z0-9\s]+/g;
  const specialAlphaNumericRegex = /^[a-zA-Z0-9\s\W]+$/;
  const regexForNumber = /[^\d\s]/g;

  const GetUserList = async() =>{
    await axios({
     url:`${API_ENDPOINT.user_list}`,
     method :"POST",
     data:{
      user_token:user_token
     },
     headers:{
      Accept:"application/json",
      Authorization:`Bearer ${auth_token}`,
     }
    })
    .then((res) =>{
      setUsers(res.data.user_list)
      setOrgName(res.data.org_name)
      setOrgId(res.data.org_id)
    })
    .catch((err)=>{
      console.log("err",err)
    })
    
  }
  
  const GetConfigList = async() =>{
    const formData = new FormData();
    formData.append("org_id", orgId);

    await axios({
     url:`${API_ENDPOINT.slab_config_list}`,
     method :"POST",
     data:formData,
     headers:{
      Accept:"application/json",
      Authorization:`Bearer ${auth_token}`,
     }
    })
    .then((res) =>{
      setConfigUsers(res.data.data)
    })
    .catch((err)=>{
      console.log("err",err)
    })
    
  }
    const itemsData = configUsers;
    // const itemsData = configUsers?.map(item => ({
    //   ...item,
    //   users: item.users ? item.users.split(',').map(user => user.trim()) : [], 
    // }));

    // setUniqueData({
    //   ...uniqueData,
    //   users: uniqueData.users ? uniqueData.users.split(',').map(user => user.trim()) : [], 
    // });

  const handleAddApprover = () => {
    let updatedApprovers = [...approvers];
    if (updatedApprovers.length > 0) {
      updatedApprovers[updatedApprovers.length - 1].showPlus = false;
    }
    const newApprover = { id: approvers.length + 1, value: '', showPlus: true };
    updatedApprovers.push(newApprover);
    setApprovers(updatedApprovers);
  };
    
  const handleRemoveApprover = (id = null) => {
    let updatedApprovers;

    if (id === null) {
      updatedApprovers = [];
    } else {
      updatedApprovers = approvers.filter((approver) => approver.id !== id);
    }
  
    if (updatedApprovers.length > 0) {
      updatedApprovers[updatedApprovers.length - 1].showPlus = true;
    }
  
    setApprovers(updatedApprovers);
  };

  const renderApproverFields = () => {
    return approvers && approvers.map((approver, index) => (
      <div key={approver.id} className={classes.approverFieldWrapper}>
        <>
          <DSelectInput
            customClass={classes.fields}
            getIDValue={true}
            label={`Level ${index + 2}`}
            placeHolder={'Select Approver'}
            value={approver.value}
            options={{ 
              tokens: userOptions.values.tokens,
              visibleOption: getFilteredOptions(approver.value, index)
            }}
            onChange={(e) => {
              const selectedToken = userOptions?.values.tokens[
                userOptions?.values?.visibleOption?.indexOf(e)
              ];
              let updatedApprovers = [...approvers];
              updatedApprovers[index].value = e; 
              updatedApprovers[index].id = selectedToken;
              setApprovers(updatedApprovers);
            }}
            error={!selectedApprovers.isValid}
            errorMsg={"Approver is required"}
            size={"60%"}
          />
          {!approver.showPlus && (
          <DButton
            customClass={classes.approveFieldRemove}  
            label={'Remove'}
            btnWidth={"13rem"}
            btnHeight={"4rem"}
            loadingState={loadingState}
            error={primaryBtnError.error}
            errorMsg={primaryBtnError.errorMsg}
            onClick={() => handleRemoveApprover(approver.id)}
            /> )},

          {approver.showPlus && (
            <DButton
            label={'Add'}
            btnWidth={"13rem"}
            btnHeight={"4rem"}
            loadingState={loadingState}
            error={primaryBtnError.error}
            errorMsg={primaryBtnError.errorMsg}
            onClick={handleAddApprover}
            />
          )}
        </>
      </div>
    ));
  };
  
  const getFilteredOptions = (currentApprover, index) => {
    // const defaultApproverValue = Array.isArray(selectedApprovers.value) 
    //   ? selectedApprovers.value 
    //   : [selectedApprovers.value];
  
    // const selectedApproversList = [
    //   ...defaultApproverValue, 
    //   ...approvers.map((approver, i) => approver.value && i !== index ? approver.value : null)
    // ].filter(Boolean); 
  
    const selectedApproversList = [
      selectedApprovers.value, 
      ...(Array.isArray(approvers) ? approvers.map((approver) => approver.value) : []) 
    ].filter(Boolean);
  
  
    return userOptions.values.visibleOption.filter(
      (option) => !selectedApproversList.includes(option) || option === currentApprover
    );
  };
  const userOptions = {
    values:{
      tokens: users?.map(user => user.id) || [], 
      visibleOption: users?.map(user => user.full_text) || [] 
    },
  }

  const handleApproverChange = (e) => {
    const selectedToken = userOptions?.values.tokens[
      userOptions?.values?.visibleOption?.indexOf(e)
    ];
    setSelectedApprovers({
      value: e, 
      id: selectedToken,
      isValid: e !== '',
    });
  };

  const initializeSelectedApprovers = (existingEmails) => {
    const defaultApproverEmail = existingEmails[0] || '';
    // console.log('useroptions',userOptions)
    // console.log('defaultApproverEmail',defaultApproverEmail)
    const defaultApproverId = userOptions.values.tokens[userOptions.values.visibleOption.indexOf(defaultApproverEmail)] || '';
    // console.log('defaultApproverId',defaultApproverId)
    setSelectedApprovers({
      value: defaultApproverEmail,
      id: defaultApproverId,
      isValid: defaultApproverEmail !== '',
    });
  
    const dynamicApprovers = existingEmails.slice(1).map((full_text, index) => {
      const emailId = userOptions.values.tokens[userOptions.values.visibleOption.indexOf(full_text)];
      return {
        id: emailId,
        value: full_text,
        showPlus: index === existingEmails.length - 2,
      };
    });
    setApprovers(dynamicApprovers);
  };

  const GetAllData = () => {
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_all_items}`,
      idName: "org_token",
      idValue: org_token,
      searchQuery: searchQuery,
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: order }),
      auth_token: auth_token,
      setData: setItemsData,
      setPaginationData: setPaginationData,
      dispatch: dispatch,
      tableProps,
      setTableProps,
    });
  };

  let delay = 300;

  //get users data for table view
  useEffect(() => {
    let timeoutId = null;

    timeoutId = setTimeout(() => {
      if (pageNumber) {
        GetAllData();
      }
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [pageNumber, searchQuery, sortingColumnName, sortingOrder]);

  useEffect(() => {
    GetUserList();
    setPrimaryBtnLabel(
      itemsAction.addItem
        ? "Add"
        : itemsAction.updateItem
          ? "Update"
          : itemsAction.addBulkItem
            ? "Add"
            : ""
    );
  }, [itemsAction]);

  useEffect(() => {
    if (orgId) {
      GetConfigList(); 
    }
  }, [orgId]);


  const updateInputs = (data) => {
    setStageName((prev) => ({
      ...prev,
      value: data?.name,
    }));

    // setSelectedApprovers((prev) => ({
    //   ...prev,
    //   value: data?.users,
    // }));

    setStartPrice((prev) => ({
      ...prev,
      value: data?.price_start,
    }));

    SetEndPrice((prev) => ({
      ...prev,
      value: data?.price_end,
    }));

    if (data?.users?.length > 0) {
      setSelectedApprovers((prev) => ({
        ...prev,
        value: data.users[0], 
        id: userOptions.values.tokens[userOptions.values.visibleOption.indexOf(data.users[0])],
        isValid: true,
      }));
  
      const remainingApprovers = data.users.slice(1).map((user, index) => ({
        value: user,
        id: userOptions.values.tokens[userOptions.values.visibleOption.indexOf(user)],
        isValid: true,
        showPlus: index === data.users.length - 2,
      }));
  
      // if (remainingApprovers.length === 0) {
      //   remainingApprovers.push({ value: '', id: '', isValid: false, showPlus: true });
      // }
  
      setApprovers(remainingApprovers);
    } else {
      setSelectedApprovers((prev) => ({
        ...prev,
        value: [],
        id: [],
        isValid: false,
      }));
  
      // setApprovers([{ value: '', id: '', isValid: false, showPlus: true }]);
    }
    setFromCurrency((prev) => ({
      ...prev,
      currency: data?.currency_type,
      countryName: data?.country_name && data?.country_name
    }));
  };



  //demo data for the datatable
  const headings = [
    { label: "Name", field: "name" },
    { label: "Users", field: "users" },
    { label: "Currency", field: "currency_type" },
    { label: "Start Price", field: "price_start" },
    { label: "End Price", field: "price_end" },
    // { label: "Status", field: "status", additional_data: "toggle" },
  ];

  // modal states
  const [open, setOpen] = useState(false);

  const handleOpenAddItem = () => {
    setOpen(true);
    resetForm();
    setItemsAction({
      addItem: true,
      updateItem: false,
    });
    // addCodesList(hsnCodes, setHSNList);

  };

  // const addCodesList = (listData, setData) => {
  //   setData(listData?.map(e => e.hsn_code))
  // }

  const handleOpenAddBulkItem = () => {
    setOpen(true);
    resetForm();
    setItemsAction({
      addItem: false,
      updateItem: false,
      addBulkItem: true,
    });
  };
  const handleOpenEditItem = (unique_id) => {
    setOpen(true);
    // resetForm();
    let rowData = getUniqueData(itemsData, "id", unique_id);
    rowData = {
      ...rowData,
      users: rowData.users ? rowData.users.map(user => user.full_text.trim()) : []
    };
    const existingApproversEmails = rowData.users;
    initializeSelectedApprovers(existingApproversEmails)
    
    updateInputs(rowData);
    setUniqueDataID(unique_id);
    setItemsAction({
      addItem: false,
      updateItem: true,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setPrimaryBtnError({
      error: false,
      errorMsg: "",
    });
    setApprovers([])
    setSelectedApprovers([])
  };

  // S - success popup
  const [openS, setOpenS] = useState(false);
  const handleOpenS = () => {
    setOpenS(true);
  };
  const handleCloseS = () => {
    setOpenS(false);
  };

  // VU - View User popup
  const [openViewItemPopup, setOpenViewItemPopup] = useState(false);
  const handleOpenViewItemPopup = (unique_id) => {
    let rowData = getUniqueData(itemsData, "id", unique_id);
    setUniqueData(rowData);
    // setUniqueData({
    //   ...rowData,
    //   users: rowData.users ? rowData.users.split(',').map(user => user.trim()) : [], 
    // });
    setOpenViewItemPopup(true);

  };
  const handleCloseViewItemPopup = () => {
    setOpenViewItemPopup(false);
  };

  // viewItemsActionButtons
  const viewItemsActionButtons = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseViewItemPopup}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label={"OK"}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      onClick={handleCloseViewItemPopup}
    />,
  ];
  const viewUserChildren = [
    <InfoLabel
    size={"44%"}
    label={"Stage Name"}
    value={uniqueData?.name}
  />,
    <InfoLabel
      size={"44%"}
      label={"Start price"}
      value={uniqueData?.price_start}
    />,
    <InfoLabel
      size={"44%"}
      label={"End Price"}
      value={uniqueData?.price_end}
    />,
<InfoLabel
    size={"44%"}
    label={"Users"}
    value={
      uniqueData?.users ? (
        <ul>
          {uniqueData.users.map((user, index) => (
            <li key={index}>{user.full_text}</li>
          ))}
        </ul>
      ) : (
        "-"
      )
    }
  />,
    // <InfoLabel
    //   size={"44%"}
    //   label={"UNSPSC Code"}
    //   value={uniqueData?.unspsc_code}
    // />,
    // <InfoLabel size={"100%"} label={"HSN Code"} value={uniqueData?.hsn_code} />,
  ];

  const viewUserHeading = (
    <HeaderTitle
      titleText={
        uniqueData?.name || <MoreHoriz sx={{ fontSize: "30px" }} />
      }
      customclass={classes.VUpopupHeading}
    />
  );

  //common states for CRUD
  const [itemName, setItemName] = useState({
    value: "",
    isValid: true,
  });
  const [itemCode, setItemCode] = useState({
    value: "",
    isValid: true,
  });
  const [HSNCode, setHSNCode] = useState({
    value: "",
    isValid: true,
  });
  const [UNSPSCCode, setUNSPSCCode] = useState({
    value: "",
    isValid: true,
  });
  const [ItemCategory, setItemCategory] = useState({
    value: "",
    isValid: true,
  });
  const [csvFile, setCsvFile] = useState({
    value: "",
    isValid: true,
  });
  const [user, setUser] = useState({
    value: "",
    isValid: true,
  });

  const [startPrice, setStartPrice] = useState({
    value: "",
    isValid: true,
  });
  const [endPrice, SetEndPrice] = useState({
    value: "",
    isValid: true,
  });
  const [stageName, setStageName] = useState({
    value: "",
    isValid: true,
  });
  const [currencyType, setCurrencyType] = useState({
    value: "",
    isValid: true,
  });
  const [selectedApprovers , setSelectedApprovers] = useState({
    value: [],
    id:[],
    isValid: true,
  });


  // const [vendorId, setVendorID] = useState([]);
  const [existingVendorList, setExistingVendorList] = useState([]);

  const resetForm = () => {
    setItemCode({
      value: "",
      isValid: true,
    });
    setItemCategory({
      value: "",
      isValid: true,
    });
    setItemName({
      value: "",
      isValid: true,
    });
    setHSNCode({
      value: "",
      isValid: true,
    });
    setUNSPSCCode({
      value: "",
      isValid: true,
    });
    setCsvFile({
      value: "",
      isValid: true,
    });
    setStartPrice({
      value: "",
      isValid: true,
    });
    setStageName({
      value: "",
      isValid: true,
    });
    SetEndPrice({
      value: "",
      isValid: true,
    });
    setSelectedApprovers({
      value: "",
      id:"",
      isValid: true,
    });
  };

  const validateForm = (query) => {
    // const isItemName = emptyCheck(itemName.value);
    // const isItemCode = emptyCheck(itemCode.value);
    // const isItemCategory = emptyCheck(ItemCategory.value);
    // const isHSNCode = HSNCode.value?.trim() !== "" && HSNCode.value !== null;
    
    const isStageName =emptyCheck(stageName.value);
    const isStartPrice =emptyCheck(String(startPrice.value));
    const isEndPrice =emptyCheck(String(endPrice.value))&& (parseFloat(endPrice.value) > parseFloat(startPrice.value));
    const areUsersSelected = selectedApprovers.value && selectedApprovers.value.length > 0 ;

    const isCsvFile = validateFile(
      csvFile.value,
      false,
      ["csv", "xlsx"],
      5
    ).isValid;

    setStartPrice((prev) => ({
      ...prev,
      isValid: isStartPrice,
    }));
    SetEndPrice((prev) => ({
      ...prev,
      isValid: isEndPrice,
    }));
    setStageName((prev) => ({
      ...prev,
      isValid: isStageName,
    }));
    setSelectedApprovers((prev) => ({
      ...prev,
      isValid: areUsersSelected,
    }));
    setUserError(!areUsersSelected);

    // setUser((prev) => ({
    //   ...prev,
    //   isValid: isUser,
    // }));

    if (query === "bulkData") {
      setCsvFile((prev) => ({
        ...prev,
        isValid: isCsvFile,
      }));

      /* return isCsvFile; */
    } else {
      return isStartPrice && isEndPrice && areUsersSelected && isStageName ;
    }
  };

  // ADD Item function
  const addItem = async () => {
    const defaultApproverID = selectedApprovers.id;
     
    const dynamicApproversIDs =approvers.length>0 ? approvers.map((approver) => approver.id).filter(Boolean) : '';
    const approversIDs = [defaultApproverID, ...dynamicApproversIDs];
  
    const formData = new FormData();
    formData.append("id", '');
    formData.append("org_id", orgId);
    formData.append("name", stageName.value);
    formData.append("price_start", startPrice.value);
    formData.append("price_end", endPrice.value);
    // formData.append("users", selectedApprovers.id);
    formData.append("currency", fromCurrency.currency);
    formData.append("country_name", fromCurrency.countryName);
    formData.append("users", approversIDs);

    await axios({
      url: `${API_ENDPOINT.slab_config}`,
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        // GetAllData();
        setPopupHeroText("Slab Added!");
        setPopupMessage(res?.data?.response);
        handleOpenS();
        handleClose();
        resetForm();
        setLoadingState(false);
        setSelectedApprovers('')
        setApprovers('')
        GetConfigList()
      })
      .catch((err) => {
        setPrimaryBtnError({
          error: true,
          errorMsg: Object.values(err?.response?.data)[0],
        });
        setLoadingState(false);
      });
  };

  // ADD Bulk Items function
  const addBulkItems = async () => {
    const formData = new FormData();

    formData.append("org_token", org_token);
    formData.append("csv_file", csvFile.value);

    await axios({
      url: `${API_ENDPOINT.add_bulk_items}`,
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        GetAllData();
        setPopupHeroText("Items Added!");
        setPopupMessage(res?.data?.response);
        handleOpenS();
        handleClose();
        resetForm();
        setLoadingState(false);
      })
      .catch((err) => {
        setPrimaryBtnError({
          error: true,
          errorMsg:
            (typeof err?.response?.data?.response !== "object" &&
              err?.response?.data?.response) ||
            "Something went wrong! please check the file values & try again.",
        });
        setLoadingState(false);
      });
  };

  // Update item submit click
  const updateItem = async () => {
    // console.log('approvers', approvers)
    // console.log('selectedApprovers', selectedApprovers)
    const defaultApproverID = selectedApprovers.id;
    const dynamicApproversIDs = approvers.map((approver) => approver.id).filter(Boolean);
    const approversIDs = [defaultApproverID, ...dynamicApproversIDs];
    const formData = new FormData();
    formData.append("id", uniqueDataID);
    formData.append("org_id", orgId);
    formData.append("name", stageName.value);
    formData.append("price_start", startPrice.value);
    formData.append("price_end", endPrice.value);
    formData.append("users", approversIDs);
    formData.append("currency", fromCurrency.currency);
    formData.append("country_name", fromCurrency.countryName);

    await axios({
      url: `${API_ENDPOINT.slab_config}`,
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        GetAllData();
        setPopupHeroText("Slab Updated!");
        setPopupMessage(res?.data?.response);
        handleClose();
        handleOpenS();
        resetForm();
        setLoadingState(false);
        GetConfigList();
        setApprovers('');
      })
      .catch((err) => {
        console.log(err);
        setPrimaryBtnError({
          error: true,
          errorMsg: Object.values(err?.response?.data)[0],
        });
        setLoadingState(false);
      });
  };

  //submit click ADD/UPDATE item
  const handleSubmit = () => {
    const validation = itemsAction.addBulkItem
      ? validateForm("bulkData"):
       validateForm();
    if (validation) {
      if (itemsAction.addItem) {
        setLoadingState(true);
        addItem();
      } else if (itemsAction.updateItem) {
        setLoadingState(true);
        updateItem();
      } else if (itemsAction.addBulkItem) {
        setLoadingState(true);
        addBulkItems();
      }
    } else {
      setItemName((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setItemCode((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setItemCategory((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setHSNCode((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setUNSPSCCode((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setCsvFile((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setStartPrice((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setStageName((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      SetEndPrice((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
      setUser((prevState) => ({
        ...prevState,
        isValid: prevState.isValid,
      }));
    }
  };

  //category select options
  const selectCategory = ["Select", "IT", "Stationary"];
  
  // INPUTS
  const InputsHeading = (
    <HeaderTitle
      titleText={
        itemsAction.addItem
          ? "Add Slab Range"
          : itemsAction.updateItem
            ? "Update Approver Range"
            : itemsAction.addBulkItem
              ? "Add Bulk Items"
              : ""
      }
      customclass={classes.popupHeading}
    />
  );

  const ItemInputs = [

   
    <DTextInput
    label="Stage Name"
    type="text"
    maxLength={250}
    value={stageName.value}
    onChange={(e) => {
      let val = e.target.value;
      setStageName({
        value: val,
        isValid: emptyCheck(e.target.value),
      });
    }}
    error={!stageName.isValid}
    customClassName={classes.popupFields}
    errorMsg={"Stage Name is required"}
    size={"45%"}
  />,
  <SelectCountry
    customClass={classes.approvercountry}
    value={fromCurrency}
    setValue={setFromCurrency}
    label="Currency"
    // size={"60%"}
    width={true}
    onChange={(newCurrency) => {
      setFromCurrency({
        ...newCurrency,
      });
    }}
  />,
  <DTextInput
    label="Start Price "
    type="number"
    maxLength={250}
    value={startPrice.value}
    onChange={(e) => {
      let val = e.target.value;
      setStartPrice({
        value: val,
        isValid: emptyCheck(e.target.value),
      });
    }}
    error={!startPrice.isValid}
    customClassName={classes.popupFields}
    errorMsg={"Start Price required"}
    size={"45%"}
  />,
  <DTextInput
    label="End Price"
    type="number"
    // maxLength={250}
    value={endPrice.value}
    onChange={(e) => {
      let val = e.target.value;
      SetEndPrice({
        value: val,
        isValid: emptyCheck(e.target.value),
      });
    }}
    error={!endPrice.isValid}
    customClassName={classes.popupFields}
    errorMsg={
      endPrice.value!='' && endPrice.value<= startPrice.value ?
      "End Price should not be lesser than Start Price" : "End Price required"
    }
    size={"45%"}
  />,
  <DSelectInput
    customClass={classes.fields}
    getIDValue={true}
    label="Level 1"
    value={selectedApprovers.value}
    placeHolder={'Select Approver'}
    // options={userOptions.values}
    options={{ 
      tokens: userOptions.values.tokens,
      visibleOption: getFilteredOptions(selectedApprovers.value, -1)
    }}
  
    onChange={handleApproverChange}
    error={!selectedApprovers.isValid}
    errorMsg={"Approver is required"}
    size={"60%"}
  />,
  <DButton
    customClass={classes.approveFieldAdd}
    label={approvers.length > 0 ? 'Remove' : 'Add'}
    btnWidth={"13rem"}
    btnHeight={"4rem"}
    loadingState={loadingState}
    error={primaryBtnError.error}
    errorMsg={primaryBtnError.errorMsg}
    onClick={approvers.length > 0 ? () => handleRemoveApprover(null) : handleAddApprover}
  />,
    <>
    {renderApproverFields()}
    </>
    ,

  ];

  const BulkItemInputs = [
    <div className={classes.input_container}>
      <DefaultLabel label={"Upload CSV file"} />
      <DFileUpload
        fileTypes={".xlsx,.csv"}
        customFileTypeText={"(Supported format CSV)"}
        onFileUpload={(file) => {
          let isFileValid = validateFile(
            file,
            false,
            ["csv", "xlsx"],
            5
          ).isValid;
          setCsvFile({
            value: file,
            isValid: isFileValid,
          });
        }}
        error={!csvFile.isValid}
        errorMsg={
          validateFile(csvFile.value, false, ["csv", "xlsx"], 5, "CSV file")
            .errorMessage
        }
        size={"100%"}
      />
      <p
        className={classes.linkBtn}
        onClick={() => {
          saveAs(sampleCSV, "Sample CSV");
        }}
      >
        Sample CSV
      </p>
    </div>,
  ];

  const itemsActionsButton = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleClose}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label={primaryBtnLabel}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      loadingState={loadingState}
      error={primaryBtnError.error}
      errorMsg={primaryBtnError.errorMsg}
      onClick={handleSubmit}
    />,
  ];

  //deletion popups and functions
  const deleteItem = async (deletionType) => {
    let isHardDelete = deletionType && deletionType === "hard delete";
    setLoadingState(true);

    setPopupMessage("");

    if (isHardDelete) {
      const formData = new FormData();
      formData.append("id", uniqueDataID);
      formData.append("status", '3');
      formData.append("org_id", orgId);
      formData.append("name", stageName.value);
      formData.append("price_start", startPrice.value);
      formData.append("price_end", endPrice.value);
      formData.append("users", selectedApprovers.value);
      formData.append("currency",'');
      formData.append("country_name",'');

      await axios({
        method: "POST",
        url: `${API_ENDPOINT.slab_config}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          GetAllData();
          handleCloseDltConfirmation();
          setPopupHeroText(`Slab Deleted Successfully!`);
          setPopupMessage(res?.data?.response);
          setLoadingState(false);
          GetConfigList();
        })
        .catch((err) => {
          setPopupHeroText("OOPS! Something went wrong");
          setPopupMessage(err?.response?.data?.response);
          console.log(err);
          setLoadingState(false);
        });
    } else {
      await axios({
        method: "POST",
        url: `${API_ENDPOINT.delete_item}`,
        data: {
          token: uniqueDataID,
          status: itemStatus,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          GetAllData();
          handleCloseDltConfirmation();
          setPopupHeroText(
            `Item ${itemStatus ? "Activated!" : "Deactivated!"}`
          );
          setPopupMessage(res?.data?.response);
          setLoadingState(false);
        })
        .catch((err) => {
          setPopupHeroText("Something went wrong!");
          setPopupMessage(err?.response?.data?.response);
          console.log(err);
          setLoadingState(false);
        });
    }
  };

  //delete data
  const [openDlt, setOpenDlt] = useState(false);
  const handleOpenDlt = () => setOpenDlt(true);
  const handleCloseDlt = () => {
    setOpenDlt(false);
  };
  const [openDltConfirmation, setOpenDltConfirmation] = useState(false);
  const [itemStatus, setItemStatus] = useState();

  const [itemHardDelete, setItemHardDelete] = useState(false);

  const handleOpenDltConfirmation = (deletion_id, rowData, deletionType) => {
    let isHardDelete = deletionType && deletionType === "hard delete";
    setItemHardDelete(isHardDelete);
    let popupMsg = rowData.status
      ? "Are you sure want to deactivate"
      : "Are you sure want to activate";
    let status = !rowData.status;
    let uniqueData = getUniqueData(itemsData, "id", deletion_id);
    setPopupHeroText(
      `${isHardDelete ? "Are you sure want to delete" : popupMsg} "${uniqueData?.name
      }"?`
    );
    setOpenDltConfirmation(true);
    setUniqueDataID(deletion_id);
    setItemStatus(status);
  };
  const handleCloseDltConfirmation = () => {
    setOpenDltConfirmation(false);
    setItemHardDelete(false);
  };
  const handleDeleteUser = () => {
    handleOpenDlt();
    itemHardDelete ? deleteItem("hard delete") : deleteItem();
    handleCloseDltConfirmation();
  };

  return (
    <div className={classes.users_tab_wrapper}>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} ${classes.flex}`}>
          <HeaderTitle titleText="value based purchase order approval" customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} ${classes.flex}`}>
          <DateBar />
          {/* <NotificationBell /> */}
          <ProfileCard
            imageSrc={profileImg}
            profilePath={"/Organization_dashboard/My_profile"}
          />
        </div>
      </div>

      <div className={classes.search_add_wrapper}>
        <SearchBar
          onChange={(query) => {
            setSearchQuery(query);
            setPageNumber(1);
          }}
        />

        <div
          className={classes.flex}
          style={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {/* <DropdownBtnOutlined
            label="DOWNLOAD AS"
            btnHeight="4rem"
            btnWidth="18rem"
            loadingState={loadingStateDwnload}
            disabled={itemsData?.length === 0}
            onClick={(fileFormat) => {
              downloadFile({
                key: "org_token",
                value: org_token,
                fileNameProp: "Items Data",
                outputFormat: fileFormat,
                API_ENDPOINT: API_ENDPOINT.csv_org_item_view,
                authToken: auth_token,
                setLoadingState: setLoadingStateDwnload,
              });
            }}
            options={["CSV", "PDF"]}
          /> */}

          {/* <DButtonOutlined
            iconWidth="1rem"
            iconHeight="1rem"
            label="UPLOAD"
            btnHeight="4rem"
            onClick={handleOpenAddBulkItem}
          /> */}

          <DButton
            labelImg={plusIcon}
            iconWidth="1rem"
            iconHeight="1rem"
            label="PO APPROVAL MATRIX"
            btnHeight="4rem"
            onClick={handleOpenAddItem}
          />
        </div>
      </div>

      <div className={`${classes.d_dataTable}`}>
        <DataTable
          headings={headings}
          tableData={itemsData}
          actions={true}
          tableSize={itemsData?.length === 0 ? "100%" : "100%"}
          viewBtn={true}
          deleteBtn={true}
          editBtn={true}
          sorting={true}
          viewClick={handleOpenViewItemPopup}
          editClick={handleOpenEditItem}
          toggleOnChange={handleOpenDltConfirmation}
          deleteClick={(id, row) => {
            handleOpenDltConfirmation(id, row, "hard delete");
          }}
          errorMessage={tableProps.errorMessage}
          isLoading={tableProps.isLoading}
          itemsTable={true}
          setSortingColumnName={setSortingColumnName}
          sortingColumnName={sortingColumnName}
          setSortingOrder={setSortingOrder}
          sortingOrder={sortingOrder}
        />

        <PaginantionComponent
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          currentPage={currentPage || 0}
          itemsPerPage={itemsPerPage || 0}
          totalItems={totalItems || 0}
          localStorageKey={"rfq_organ_page_number"}
          previouPageClick={() => {
            previouPageClick(pageNumber, setPageNumber);
          }}
          nextPageClick={() => {
            nextPageClick(pageNumber, setPageNumber, paginationData);
          }}
          disablePrevious={parseInt(pageNumber) === 1 || pageNumber === ""}
          disableNext={
            parseInt(pageNumber) === paginationData?.page_count ||
            !paginationData ||
            pageNumber === ""
          }
        />
      </div>

      {/* modal popups */}

      {/* add/update item modal */}
      <Modal
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={open}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            <PopupFrame
              inputChildren={
                itemsAction.addItem
                  ? ItemInputs
                  : itemsAction.updateItem
                    ? ItemInputs
                    : itemsAction.addBulkItem
                      ? BulkItemInputs
                      : ItemInputs
              }
              poupHeading={InputsHeading}
              actionButtons={itemsActionsButton}
              closePopup={handleClose}
              customClass={
                itemsAction.updateItem ? classes.updatePopup : classes.addPopup
              }
            // styleCenterDiv={classes.centerDiv}
            />
          </div>
        </Fade>
      </Modal>

      {/* view user modal */}
      <Modal
        open={openViewItemPopup}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={openViewItemPopup}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            <PopupFrame
              inputChildren={viewUserChildren}
              poupHeading={viewUserHeading}
              actionButtons={viewItemsActionButtons}
              closePopup={handleCloseViewItemPopup}
              width={"auto"}
              customClass={classes.addPopup}
              styleCenterDiv={classes.centerDiv}
            />
          </div>
        </Fade>
      </Modal>

      {/* success modal */}
      <Modal open={openS}>
        <Fade in={openS}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              btnText={"Okay"}
              btnClick={handleCloseS}
            />
          </Box>
        </Fade>
      </Modal>

      {/* delete error modal two states - confirmation and deletion */}

      {/* confirmation */}
      <Modal open={openDltConfirmation}>
        <Fade in={openDltConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText={popupHeroText}
              popBtn={true}
              btnText={"Yes"}
              secondaryBtn={true}
              btnTextSecondary={"No"}
              btnClick={handleDeleteUser}
              customHeroTextClass={classes.popupTxtSm}
              btnClickSecondary={handleCloseDltConfirmation}
            />
          </Box>
        </Fade>
      </Modal>

      {/* deletion */}
      <Modal open={openDlt}>
        <Fade in={openDlt}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              btnText={"Okay"}
              loadingPrimaryBtn={loadingState}
              btnClick={handleCloseDlt}
              customHeroTextClass={classes.popupTxtSm}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default SlabConfig;

import React, { memo, useEffect, useState } from "react";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
//img
import viewIcon from "../../../assets/images/svg/viewtech.svg";
import editIcon from "../../../assets/images/svg/File Edit.svg";
import dltIcon from "../../../assets/images/svg/Delete.svg";
import sortIcon from "../../../assets/images/svg/Sort.svg";
//css
import styles from "./DataTable.module.css";
//components
import ImgFrame from "../ImageFrame/ImgFrame";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DataTable = ({
  headings,
  tableData,
  actions,
  viewBtn,
  editBtn,
  deleteBtn,
  viewClick,
  editClick,
  deleteClick,
  customClass,
  sorting,
  tableSize,
  isLoading,
  errorMessage,
  stickyHead,
  tableFixedHeight,
  setSortingColumnName,
  setSortingOrder,
}) => {
  const [data, setData] = useState(tableData);
  let quoteOptions = headings.filter((e) => e.label === "Quote Iteration")[0]
    ?.DropdownOptions;
  useEffect(() => {
    tableData && setData(tableData);
  }, [tableData]);

  const [sortedField, setSortedField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (field) => {
    let newSortOrder = "asc";
    if (sortedField === field && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortedField(field);
    setSortOrder(newSortOrder);
  };

  //open dropdown states and handlers
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdownHeading = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdownHeading = () => {
    setAnchorEl(null);
  };

  const sortfunction = () => {
    setSortingColumnName(sortedField);
    setSortingOrder(sortOrder);
  };
  useEffect(() => {
    sortfunction();
  }, [sortedField, sortOrder]);
  // if (!data || Object.keys(data).length === 0 || !data.length === 0) return;
  return (
    <div className={styles.container}>
      <div
        className={`${styles.datatable_wrapper} ${customClass}`}
        style={{
          maxHeight: tableFixedHeight && tableFixedHeight,
        }}
      >
        <table
          style={{
            width: tableSize || "100vw",
          }}
        >
          <thead className={`${stickyHead && styles.stickyHead}`}>
            <tr>
              {headings.map((heading) => (
                <th
                  key={heading.field}
                  className={`${styles.buyerHead}`}
                  style={{
                    textAlign:
                      heading.label === "Status" ||
                      heading.label === "Bid Status"
                        ? "left"
                        : "left",
                    width:
                      heading.label === "Status"
                        ? "15rem"
                        : heading.col_width && heading.col_width,
                  }}
                >
                  {/* show only field heading - default*/}
                  {!heading.selectable_header && heading.label}
                  {/* show only field heading - default*/}
                  {sorting &&
                    !heading.selectable_header &&
                    heading.label !== "Bids Status" && (
                      <button
                        onClick={() => {
                          handleSort(heading.field);
                        }}
                        style={{
                          outline: "none",
                        }}
                      >
                        <img
                          src={sortIcon}
                          className={styles.sortIcon}
                          alt=""
                        />
                      </button>
                    )}

                  {heading.selectable_header && (
                    <span
                      className={`${styles.flex} ${styles.pointer}`}
                      onClick={handleOpenDropdownHeading}
                    >
                      {heading.label}
                      <ArrowDropDownIcon
                        sx={{
                          width: "25px",
                          fontSize: "2rem",
                        }}
                      />
                    </span>
                  )}

                  {heading.selectable_header && (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      sx={{
                        ".MuiMenu-paper": {
                          marginTop: "0.6rem",
                          marginLeft: "2rem",
                          boxShadow: "0px 6px 24px #00000017",
                        },
                      }}
                      onClose={handleCloseDropdownHeading}
                    >
                      {heading.selectable_header &&
                        quoteOptions?.map((option, index) => {
                          return (
                            <MenuItem
                              sx={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "1.4rem",
                                fontFamily: "gilroyMedium",
                              }}
                              key={index}
                              onClick={() => {
                                handleCloseDropdownHeading();
                                heading.selectable_header &&
                                  heading?.onClick &&
                                  heading?.onClick(
                                    option?.toLowerCase(),
                                    option
                                  );
                              }}
                            >
                              {option || <em></em>}
                            </MenuItem>
                          );
                        })}
                    </Menu>
                  )}
                </th>
              ))}
              {actions && (
                <th
                  className={`${styles.buyerHead}`}
                  style={{ textAlign: "center", width: "150px" }}
                >
                  Actions
                </th>
              )}
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {data &&
                data?.map((row, i) => (
                  <tr key={row.id}>
                    {headings.map((heading) =>
                      heading.isSingle === "single" ? (
                        <td key={heading.field}>
                          <span>{row[heading.field]?.data || "-"}</span>
                        </td>
                      ) : (
                        <td key={heading.field}>
                          {row[heading.field]?.data?.map((item) => (
                            <>
                              <span>{item || "-"}</span>
                              <br />
                            </>
                          ))}
                        </td>
                      )
                    )}
                    {actions && (
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              editClick(
                                row["RFQDraftData"]?.data?.rfq_details?.token,
                                row
                              )
                            }
                            customClass={`${styles.actBtn}`}
                          />
                        )}

                        {deleteBtn && (
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token, row)}
                            customClass={styles.actBtn}
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>

      {isLoading && (
        <span className={styles.infoMsg}>
          <CircularProgress
            thickness={6}
            variant="indeterminate"
            disableShrink
            sx={{
              animationDuration: "450ms",
              color: "#55555580",
            }}
            size={25}
          />
        </span>
      )}

      {(errorMessage ||
        errorMessage === "" ||
        !tableData ||
        tableData.lenght === 0) && (
        <span className={styles.infoMsg}>
          {errorMessage || "No data found."}
        </span>
      )}
    </div>
  );
};

export default memo(DataTable);

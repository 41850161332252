import React, { memo } from 'react'

const BrandLogo = ({ imgSrc, imgAlt, imgClassName, imgSize, customClass, imgOnclick }) => {
    return (
        <div className={`br_logo flex ${customClass}`} style={{ width: `${imgSize}` }} onClick={imgOnclick} >
            <img src={imgSrc} alt={imgAlt} className={imgClassName} />
        </div>
    )
}

export default memo(BrandLogo);
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    value: []
}
export const AdminDataSlice = createSlice({
    name: 'adminData',
    initialState,
    reducers: {
        setAdminData: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { setAdminData } = AdminDataSlice.actions;
export default AdminDataSlice.reducer;
import React, { useEffect, useReducer, useState } from "react";
import { Box, Fade, Modal, Skeleton } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";

//components
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import DateBar from "../../../components/common/DateBar/DateBar";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import Dbutton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
import PopupFrame from "../../../components/Popups/PopupFrame/PopupFrame";
// import DTextArea from "../../../components/Dashboard/DashboardInputs/DTextArea";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";

//img
import profileImg from "../../../assets/images/png/Profile.png";
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";

//css
import classes from "./PromptCreation.module.css";

//utills
import { API_ENDPOINT } from "../../../services/api/resources";
import { emptyCheck } from "../../../utils/validations";

const promptReducer = (state, action) => {
  switch (action.type) {
    case "setPromptData":
      return { ...state, promptList: action.payload, createPromptData: "" };
    case "openCreatePrompt":
      return {
        ...state,
        isCreateModalOpen: true,
        createPromptData: action.payload,
      };
    case "closeCreatePrompt":
      return {
        ...state,
        isCreateModalOpen: false,
        createSuccessModal: false,
        createPromptData: "",
      };
    case "CreateSuccessModal":
      return { ...state, createSuccessModal: true, isCreateModalOpen: false };
    case "CreateSuccessModalClose":
      return { ...state, createSuccessModal: false, createPromptData: "" };
    default:
      return { ...state };
  }
};
const PromptCard = ({ data }) => {
  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(date);

    const month = parts.find((part) => part.type === "month").value;
    const day = parts.find((part) => part.type === "day").value;
    const year = parts.find((part) => part.type === "year").value;
    const hour = parts.find((part) => part.type === "hour").value;
    const minute = parts.find((part) => part.type === "minute").value;

    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    const formattedDateString = `${day} ${month} ${year} - ${hour}:${minute} ${ampm}`;

    return formattedDateString;
  }
  const { category, created_date, id } = data;
  return (
    <div className={classes.promptScreen} key={id}>
      <div className={classes.propmtItem}>
        <div className={classes.propmtItemDesc}>
          <p className={classes.promptDescription}>{category}</p>
          <h4 className={classes.promptTitle}>{formatDate(created_date)}</h4>
        </div>
      </div>
    </div>
  );
};
const NoDataInfo = ({ message }) => {
  return (
    <div className={classes.nodataInforWrapper}>
      <p>{message}</p>
    </div>
  );
};
const PromptCreation = () => {
  let initialState = {
    promptList: [],
    createPromptData: "",
    isCreatemodalOpen: false,
    createSuccessModal: false,
  };
  const data = useSelector((state) => state.loginData.value);
  //credentials
  const auth_token = data?.auth_token;

  //state
  const [promptDescripton, setPromptDescripton] = useState({
    value: "",
    isValid: true,
  });
  const [primaryBtnError, setPrimaryBtnError] = useState({
    error: false,
    errorMsg: "",
  });
  const [loadingState, setLoadingState] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  //reducer
  const [promptState, promptDispatch] = useReducer(promptReducer, initialState);
  useEffect(() => {
    setLoadingState(true);
    setIsDataLoading(true);
    axios({
      method: "GET",
      url: `${API_ENDPOINT.list_of_prompt_category}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        setLoadingState(false);
        setIsDataLoading(false);
        let promptList = response.data.response;
        promptDispatch({ type: "setPromptData", payload: promptList });
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingState(false);
        setIsDataLoading(false);
        promptDispatch({ type: "setPromptData", payload: [] });
      });
  }, [promptState.createSuccessModal]);

  const promptCreateOpen = () => {
    promptDispatch({ type: "openCreatePrompt" });
  };
  const handleCreatePromptSubmit = () => {
    setPrimaryBtnError({
      error: false,
      errorMsg: "",
    });
    setPromptDescripton((state) => ({
      ...state,
      isValid: true,
    }));
    setLoadingState(true);
    if (promptState.createPromptData) {
      axios({
        method: "POST",
        url: `${API_ENDPOINT.create_prompt_table}`,
        data: {
          prompt_category: promptState.createPromptData,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          setLoadingState(false);
          let response = res.data.response;
          if (response === "Created successfully") {
            promptDispatch({ type: "CreateSuccessModal" });
            setPromptDescripton((state) => ({
              value: "",
              isValid: true,
            }));
          } else {
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingState(false);
          setPrimaryBtnError({
            error: true,
            errorMsg: error.data.response,
          });
        });
    } else {
      setLoadingState(false);
      setPromptDescripton({
        error: true,
        errorMsg: "Prompt value not empty",
      });
    }
  };
  const InputsHeading = (
    <HeaderTitle
      titleText={"Add RFQ Category"}
      customclass={classes.popupHeading}
    />
  );
  const itemInputs = [
    <DTextInput
      customClassName={classes.fields}
      label="RFQ Category"
      value={promptDescripton.value}
      onChange={(e) => {
        let val = e.target.value;
        setPromptDescripton({
          value: val,
          isValid: emptyCheck(e.target.value),
        });
        promptDispatch({
          type: "openCreatePrompt",
          payload: e.target.value,
        });
      }}
      error={!promptDescripton.isValid}
      errorMsg={"RFQ category description is required"}
      size={"100%"}
    />,
  ];

  const itemsActionsButton = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={() => {
        setPrimaryBtnError({
          error: false,
          errorMsg: "",
        });
        setPromptDescripton({
          value: "",
          isValid: true,
        });
        promptDispatch({ type: "closeCreatePrompt" });
      }}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label="Save"
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      loadingState={loadingState}
      error={primaryBtnError.error}
      errorMsg={primaryBtnError.errorMsg}
      onClick={handleCreatePromptSubmit}
    />,
  ];
  return (
    <>
      <div
        className={`${classes.users_tab_wrapper} ${classes.superAdminContainer}`}
      >
        <div className={classes.users_topHeader}>
          <div className="flex">
            <HeaderTitle
              titleText="RFQ Category"
              customclass={classes.dashHead}
            />
          </div>
          <div className="flex">
            <DateBar />
            <ProfileCard
              imageSrc={profileImg}
              profilePath={"/Organization_dashboard/My_profile"}
            />
          </div>
        </div>
        <div className={classes.promptScreenMain}>
          <Dbutton
            label="Add RFQ Category"
            customWrapperClass={classes.crateBtnWrapper}
            customClass={classes.edtBtn}
            iconWidth={"2rem"}
            iconHeight={"2rem"}
            btnHeight={"4.5rem"}
            disabled={false}
            onClick={promptCreateOpen}
          />
          {!isDataLoading ? (
            promptState.promptList?.length > 0 ? (
              promptState.promptList?.map((item) => (
                <PromptCard data={item} key={item.id} />
              ))
            ) : (
              <NoDataInfo message="No RFQ Category Message Available" />
            )
          ) : (
            <>
              {[...Array(2)].map((_, index) => (
                <div
                  className={classes.promptSkeleton}
                  style={{
                    width: "100%",
                    maxHeight: "120px",
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ width: "80%" }}>
                    <Skeleton width={"100%"} height={"20px"} />
                    <br />
                    <Skeleton
                      width={"100%"}
                      height={"20px"}
                      sx={{ transform: "scale(1)" }}
                    />
                  </span>
                  <span style={{ width: "10%" }}>
                    <Skeleton
                      width={"100%"}
                      height={"35px"}
                      sx={{ transform: "scale(1)" }}
                    />
                  </span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {/* Create modal */}
      <Modal open={promptState.isCreateModalOpen}>
        <Fade in={promptState.isCreateModalOpen}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            <PopupFrame
              inputChildren={itemInputs}
              poupHeading={InputsHeading}
              actionButtons={itemsActionsButton}
              closePopup={() => promptDispatch({ type: "closeCreatPrompt" })}
              customClass={classes.promptModalFram}
            />
          </div>
        </Fade>
      </Modal>
      {/* success modal */}
      <Modal open={promptState.creatSuccessModal}>
        <Fade in={promptState.creatSuccessModal}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={"AI dialogue added successfuly."}
              popupDescription={""}
              customHeroTextClass={classes.successPopupTitle}
              popBtn={true}
              btnText={"Okay"}
              btnClick={() => {
                promptDispatch({ type: "CreateSuccessModalClose" });
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PromptCreation;

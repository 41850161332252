import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 8,
    },
  }));
  
  const getStatusColor = (status) => {
    if (status === 2) return 'green';
    if (status === 1) return 'red';
    return '#ffc107';
  };

  const ApproverTooltip = ({ approvers, children }) => {
    const loginData = useSelector((state) => state.loginData.value);
    
    return (
        <LightTooltip
            title={
                <div style={{ padding: '2px' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr style={{ height: '40px' }}>
                                <th style={{ padding: '2px 4px', textAlign: 'left' }}>Approver</th>
                                <th style={{ padding: '2px 4px', textAlign: 'left' }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {approvers && approvers.length > 0 ? (
                            approvers.map((approver, index) => (
                                <tr key={index} /* style={{ height: '40px' }} */>
                                    <td style={{ padding: '2px 4px' }}>{approver.account_id__org_id__first_name}{approver.account_id__org_id__token && approver.account_id__org_id__token === loginData.user.user_token ? " (You)" : ""}</td>
                                    <td style={{ padding: '2px 4px', marginBottom: '20px', color: getStatusColor(approver.app_status) }}>
                                        {approver.app_status === 2
                                            ? "Approved"
                                            : approver.app_status === 1
                                            ? "Rejected"
                                            : "Pending"}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'center', padding: '4px' }}>No approvers available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            }
            arrow
            placement="top"
        >
            {children}
        </LightTooltip>
    );
};
ApproverTooltip.propTypes = {
    approvers: PropTypes.arrayOf(
        PropTypes.shape({
            account_id__org_id__first_name: PropTypes.string,
            account_id__org_id__token: PropTypes.string,
            app_status: PropTypes.number,
        })
    ),
    children: PropTypes.element.isRequired,
};
  
  export default ApproverTooltip;

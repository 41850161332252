import React from 'react'
import HomeFooter from '../../../../components/Home/HomeFooter/HomeFooter'
import classes from "./CookiePolicyLayout.module.css"
import Header from '../../../../components/Home/Header/Header'
import { Element } from 'react-scroll'
import CookiePolicy from './CookiePolicy'
const CookiePolicyLayout = () => {
    return (
        <>
            {/* scroll element for privacy policy section */}
            <Element name="cookie_content"></Element>
            {/* scroll element for privacy policy section */}
            <Header />

            <CookiePolicy />

            <div className={`${classes.footer}`}>
                <HomeFooter />
            </div>
        </>
    )
}

export default CookiePolicyLayout
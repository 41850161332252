const getUniqueData = (data, key, value) => {
    let filteredData = data?.find((e) => {
        if (e && e[key] === value) {
            return true;
        }
        return false;
    });
    return filteredData;
};

export { getUniqueData };
import React, { memo } from "react";
//img
import previousBtn from "../../../assets/images/svg/leftarrow.svg";
import previousBtnFade from "../../../assets/images/svg/leftarrowfade.svg";
import nextBtn from "../../../assets/images/svg/rightarrow.svg";
import nextBtnFade from "../../../assets/images/svg/rightarrowfade.svg";
import Tooltip from '@mui/material/Tooltip';

//css
import classes from "./Pagination.module.css";

const PaginationComponent = ({
  currentPage,
  itemsPerPage,
  totalItems,
  disableNext,
  disablePrevious,
  previouPageClick,
  nextPageClick,
  pageNumber,
  setPageNumber,
  localStorageKey,
}) => {
  const onPageChange = (e) => {
    const {
      target: { value },
    } = e;
    let numberRegex = /^\d+$/;
    if (
      (numberRegex.test(value) &&
        parseInt(value) >= 1 &&
        parseInt(value) <= parseInt(totalItems)) ||
      value === ""
    ) {
      if (value) {
        localStorage[localStorageKey] = value;
      }
      setPageNumber(value);
    }
  };  
  return (
    <div className={`${classes.pagination}`}>
      {/* Total Count: 10 | Page 3 of 5 < > */}

      <span className={`${classes.pagination_text}`}>
        {`Total count`} <span>{itemsPerPage}</span>
      </span>
      <span className={`${classes.pagination_text}`}>|</span>

      <span className={`${classes.pagination_text}`}>
        {`Page`}{" "}
        <Tooltip title="Search Page hear"><input type="text" value={pageNumber} onChange={onPageChange} /></Tooltip> of{" "}
        {totalItems}{" "}
      </span>

      <button
        className={`${classes.pagination_button}`}
        onClick={() => previouPageClick()}
        disabled={disablePrevious}
      >
        <img
          src={!disablePrevious ? previousBtn : previousBtnFade}
          alt="Previous"
        />
      </button>

      <button
        className={`${classes.pagination_button}`}
        onClick={() => nextPageClick()}
        disabled={disableNext}
      >
        <img src={!disableNext ? nextBtn : nextBtnFade} alt="Next" />
      </button>
    </div>
  );
};

export default memo(PaginationComponent);

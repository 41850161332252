import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Fade,
  Modal,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { createFilterOptions } from "@mui/material/Autocomplete";
import DefaultLabel from "../../../components/Dashboard/DefaultLabel/DefaultLabel";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
import DateBar from "../../../components/common/DateBar/DateBar";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import DataTable from "../../../components/common/DataTable/DataTableRfqDraf";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
import useFetchData from "../../../hooks/useFetchData";
import { API_ENDPOINT } from "../../../services/api/resources";
import DRadioInput from "../../../components/Dashboard/DashboardInputs/DRadioInput";
import DSelectInput from "../../../components/Dashboard/DashboardInputs/DSelectInput";
import {
  emptyCheck,
  validateEmail,
  validateSelect,
} from "../../../utils/validations";
import warningPopupImg from "../../../assets/images/png/warning.png";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";
import DTextArea from "../../../components/Dashboard/DashboardInputs/DTextArea";
import DFileUpload from "../../../components/Dashboard/DashboardInputs/DFileUpload";
import DetailsTab from "../../../components/Dashboard/DetailsTab/DetailsTab";
import Dbutton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DDateInput from "../../../components/Dashboard/DashboardInputs/DDateInput";
import DaysReminder from "../../../components/Dashboard/DaysReminder/DaysReminder";
import DSearchInputDropdown from "../../../components/Dashboard/DashboardInputs/DSearchInputDropdown";
import DPhoneNumberInput from "../../../components/Dashboard/DashboardInputs/DPhoneNumberInput";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import PopupFrame from "../../../components/Popups/PopupFrame/PopupFrame";

//css
import classes from "./RFQDraftTab.module.css";
import styles from "../../../components/Dashboard/DashboardInputs/DashboardInput.module.css";
import vendorClasses from "../VendorsTab/VendorsTab.module.css";

//image
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
import deletePopupImg from "../../../assets/images/png/Group 2@2x.png";
import plusIcon from "../../../assets/images/svg/plusIcon.png";
import DFreeSoloInput from "../../../components/Dashboard/DashboardInputs/DFreeSoloInput";
import { error } from "pdf-lib";

function RFQDraftTab() {
  const regexForAlphaNumeric = /[^a-zA-Z0-9\s]+/g;
  const regexForNumber = /[^\d\s]/g;
  const numberOnlyRegex = /^\d+$/;
  const theme = createTheme({
    typography: {
      // fontSize: "1.4rem"
      htmlFontSize: 9.8,
    },
  });
  const filter = createFilterOptions();
  //units select options
  const unitsOptions = [
    "Assemblies",
    "Dozen",
    "Each",
    "Feet",
    "Gallons",
    "Kilo",
    "Liters",
    "Metric Tons",
    "Meters",
    "Pieces",
    "Pounds",
    "Sets",
    "Tons",
    "Yards  ",
  ];
  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  const RFQData = useSelector((state) => state.buyerRFQData.value);
  const AdminData = useSelector((state) => state.adminData.value);

  // table state
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();
  const [rfqDraftData, setRfqDraftData] = useState([]);
  //   const [tableData,setTableData] = useState([])
  const [tableProps, setTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });
  //delete state
  const [openDltConfirmation, setOpenDltConfirmation] = useState(false);
  const [isRfqDraftDeleting, setIsRfqDraftDeleting] = useState(false);
  const [isPoDeleting, setIsPoDeleting] = useState(false);
  const [deleteItem, setDeleteItem] = useState([]);
  // create RFQ
  const [isNewRFQ, setIsNewRFQ] = useState(false);
  // modal success
  const [open, setOpen] = useState(false);
  // Veridy RFQ
  const [uploadItemDocument, setUploadItemDocument] = useState({
    uploadFile: true,
    inputs: false,
  });
  const [successModalContent, setSuccessModalContent] = useState({
    title: "",
    description: "",
    icon: successPopupImg,
  });
  const [itemCSV, setItemCSV] = useState({
    value: "",
    hasFile: false,
    isValid: true,
  });
  const [RFQItemsData, setRFQItemsData] = useState([]);
  const [itemsInput, setItemsInput] = useState([
    {
      itemToken: "",
      itemTokenisValid: false,
      itemMappedToken: "",
      itemCode: "",
      itemCodeisValid: false,
      itemName: "",
      itemNameisValid: false,
      itemQuantity: "",
      itemQuantityisValid: false,
      itemUnit: "",
      itemUnitisValid: false,
      itemDescription: "",
      itemDescriptionisValid: false,
      itemPartNumber: "",
    },
  ]);
  const [itemList, setItemList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });
  const [itemNameList, setItemNameList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });
  const [editRFQForm, setEditRFQForm] = useState(true);
  const [fileName, setFileName] = useState();
  // const [proposalDocument, setProposalDocument] = useState({
  //   value: "",
  //   isValid: true,
  //   hasFile: true,
  // });
  const [requestNumber, setRequestNumber] = useState({
    value: "",
    isValid: true,
  });
  const [RFQCategory, setRFQCategory] = useState({
    value: "",
    id: "",
    categoryList: [],
    isValid: true,
  });
  // new rfq tabs
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [country, setCountry] = useState({
    value: "",
    countryList: [],
    isValid: true,
  });
  const [stateName, setStateName] = useState({
    value: "",
    stateList: [],
    isValid: true,
  });

  const [countryToken, setCountryToken] = useState(0);
  const [stateToken, setstateToken] = useState(0);
  const [city, setCity] = useState({
    value: "",
    cityList: [],
    isValid: true,
  });
  // modal states
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [bidType, setBidType] = useState({
    value: true,
    isValid: true,
  });

  // const [bidStatus, setBidStatus] = useState({
  //   isValid: true,
  //   isChanged: false,
  //   value: "",
  // });

  const [fromDate, setFromDate] = useState({
    value: "",
    isValid: true,
  });

  const [toDate, setToDate] = useState({
    value: "",
    isValid: true,
  });

  const [time, setTime] = useState({
    value: null,
    isChanged: false,
    isValid: true,
  });
  const [vendor, setVendor] = useState({
    value: [],
    id: [],
    isValid: true,
  });
  const [vendorsList, setVendorsList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });
  const [vendorId, setVendorID] = useState([]);
  // user address state
  const [shippingAddress, setShippingAddress] = useState([]);

  const [address1, setAddress1] = useState({
    value: "",
    isValid: true,
  });

  const [address2, setAddress2] = useState({
    value: "",
    isValid: true,
  });
  const [pincode, setPincode] = useState({
    value: "",
    isValid: true,
  });
  const [mobileNumber, setMobileNumber] = useState({
    value: "",
    format: "",
    isValid: true,
  });
  const [newRFQForm, setNewRFQForm] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [btnErrorState, setBtnErrorState] = useState({
    error: false,
    errorMsg: "",
  });
  const [proposalDescription, setProposalDescription] = useState({
    value: "",
    isValid: true,
  });
  const [deletedItemsToken, setDeletedItemsToken] = useState([]);

  const [popupHeroText, setPopupHeroText] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [rfqToken, setRFQToken] = useState();
  //unique user
  const [uniqueData, setUniqueData] = useState();
  const [existingVendorList, setExistingVendorList] = useState([]);
  const [CompanyName, setCompanyName] = useState({
    value: "",
    errorMsg: "",
    isValid: true,
  });
  const [companyList, setCompanyList] = useState([]);
  const [org_token, setOrgToken] = useState(data?.user?.token);
  const [VendorRegisteredEmail, setVendorRegisteredEmail] = useState({
    value: "",
    errorMsg: "",
    isValid: true,
  });
  const [primaryBtnError, setPrimaryBtnError] = useState({
    error: false,
    errorMsg: "",
  });
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState({
    open: false,
    loading: false,
    errorMessage: "",
  });
  const [itemsListDataViewRFQ, setItemsListDataViewRFQ] = useState();
  const [uploadDocument, setUploadDocument] = useState({
    uploadFile: true,
    uploadDescripton: false,
  });
  useEffect(() => {
    getCategoryList();
    viewAllItem(org_token);
  }, []);
  // effect hook
  useEffect(() => {
    GetAllRFQDraftData(1);
  }, [pageNumber, searchQuery, isNewRFQ]);

  //Component state
  const auth_token = data?.auth_token;
  const buyer_token = data?.user?.token;
  // =====table variable===== //
  // pagination
  const currentPage = 1;
  const sortingColumnName = null;
  const sortingOrder = null;
  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;
  //table header
  const headings = [
    {
      label: "PR Number",
      field: "prNumber",
      isSingle: "single",
      col_width: "120px",
    },
    {
      label: "Item Code",
      field: "itemCode",
      isSingle: "multiple",
      col_width: "120px",
    },
    {
      label: "Item Name",
      field: "itemName",
      isSingle: "multiple",
      col_width: "200px",
    },
    {
      label: "Quantity",
      field: "quantity",
      isSingle: "multiple",
      col_width: "80px",
    },
    // { label: "Item Price", field: "itemPrice", isSingle: "multiple" },
    {
      label: "Net Value",
      field: "netValue",
      isSingle: "multiple",
      col_width: "120px",
    },
  ];
  let tableData = rfqDraftData.map((item) => {
    const { rfq_details = {}, pr_item_list = [] } = item;
    return {
      RFQDraftData: {
        data: item,
      },
      prNumber: {
        data: rfq_details?.pr_request_no,
        dataType: "Single",
      },
      itemCode: {
        data: pr_item_list?.map((item) => item?.item_code),
        dataType: "multiple",
      },
      itemName: {
        data: pr_item_list?.map((item) => item?.item_name),
        dataType: "multiple",
      },
      quantity: {
        data: pr_item_list?.map((item) => item?.quantity),
        type: "multiple",
      },
      // itemPrice: pr_item_list.map((item) => item.item_price),
      netValue: {
        data: pr_item_list.map((item) => item.net_value),
        type: "multiple",
      },
    };
  });
  // table API call
  const GetAllRFQDraftData = (pageNumber, rfq_code) => {
    //api param
    let idParams = [
      {
        key: "buyer_token",
        value: buyer_token ? buyer_token : "",
      },
    ];
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_pr_rfq_draft}`,
      idParams: idParams,
      searchQuery: searchQuery,
      auth_token: auth_token,
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: sortingOrder }),
      setData: setRfqDraftData,
      setPaginationData: setPaginationData,
      tableProps,
      setTableProps,
    });
  };
  // Delete function
  function handleDelete(tokem, rows) {
    setOpenDltConfirmation(true);
    setDeleteItem(rows?.RFQDraftData?.data);
  }
  function handleDeleteRfqDraft() {
    setIsRfqDraftDeleting(true);
    axios({
      method: "POST",
      url: API_ENDPOINT.delete_pr_rfq_draft,
      data: { pr_token: deleteItem?.rfq_details?.token },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.response === "Purchase request is deleted successfully") {
          setOpenDltConfirmation(false);
          setSuccessModalContent({
            title: "Deleted!",
            description: "Purchase request is deleted successfully.",
            icon: successPopupImg,
          });
          setOpen(true);
        }
        setIsRfqDraftDeleting(false);
      })
      .catch((error) => {
        setOpenDltConfirmation(false);
        if (error?.response?.data?.response) {
          setSuccessModalContent({
            title: "Error!",
            description: error?.response?.data?.response,
            icon: warningPopupImg,
          });
          setOpen(true);
        }
      });
  }
  // Edit function
  function handleEdit(rfq_token, row) {
    setIsNewRFQ(true);
    setEditRFQForm(true);
    setRFQToken(rfq_token);
    getCountriesList();
    tab1Click();
    getIndividualData(rfq_token, row);
  }
  // modal helper function
  function handleCloseDltConfirmation() {
    setOpenDltConfirmation(false);
  }
  function CloseRfqDraftSuccessModal() {
    setOpen(false);
    setSuccessModalContent({
      title: "",
      description: "",
      icon: successPopupImg,
    });
    setIsNewRFQ(false);
  }

  const getCompanyList = async (searchValue) => {
    let abortController = new AbortController();
    try {
      abortController.abort();

      const newAbortController = new AbortController();
      abortController = newAbortController;

      const formData = new FormData();
      formData.append("buyer_token", org_token);
      formData.append("search", searchValue);

      const response = await axios({
        method: "POST",
        url: `${API_ENDPOINT.getCompanyList}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
        signal: abortController.signal,
      });

      let responseData = response?.data?.response;
      setCompanyList(responseData?.map((e) => e?.company_name));
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted:", error.message);
      } else {
        console.log(error);
      }
    }
  };
  // verify function

  function handleClose1() {
    setOpen1(false);
    setVendorRegisteredEmail({
      value: "",
      errorMsg: "",
      isValid: true,
    });
    setCompanyName({
      value: "",
      errorMsg: "",
      isValid: true,
    });
  }

  const handleSubmit1 = (e) => {
    e.stopPropagation();

    const isEmailValid = validateEmail(VendorRegisteredEmail.value);
    const isCompanyValid = emptyCheck(CompanyName.value);

    setCompanyName((prev) => ({ ...prev, isValid: isCompanyValid }));
    setVendorRegisteredEmail((prev) => ({ ...prev, isValid: isEmailValid }));

    if (isEmailValid && isCompanyValid) {
      setLoading(true);
      axios({
        method: "POST",
        url: `${API_ENDPOINT.rfq_vendor_email_verification}`,
        data: {
          email: VendorRegisteredEmail.value,
          buyer_token: buyer_token,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            vendor.value.length > 0
              ? setVendor((prev) => ({
                  ...prev,
                  isValid: true,
                  value: [
                    ...prev?.value,
                    `${CompanyName.value} (${VendorRegisteredEmail.value})`,
                  ],
                }))
              : setVendor({
                  isValid: true,
                  value: [
                    `${CompanyName.value} (${VendorRegisteredEmail.value})`,
                  ],
                });
          }
          handleClose1();
          setLoading(false);
        })
        .catch((e) => {
          console.log("e", e);
          if (!e.response) return;
          setVendorRegisteredEmail((prev) => ({
            ...prev,
            message: e.response.data.response,
            isValid: false,
          }));
          setLoading(false);
        });
    }
  };
  const vendorsTabInputs = [
    <DFreeSoloInput
      label="Company Name"
      value={CompanyName.value}
      options={companyList}
      onChange={(value) => {
        if (open1) {
          setCompanyName({
            value: value,
            isValid: emptyCheck(value),
          });
        }
        if (value?.length > 2) {
          getCompanyList(value);
        } else {
          setCompanyList([]);
        }
      }}
      error={!CompanyName.isValid}
      errorMsg={"Company name is required"}
      size={"100%"}
    />,
    <DTextInput
      label={"Email"}
      value={VendorRegisteredEmail.value}
      onChange={(e) => {
        let val = e.target.value;
        const arr = [];
        vendorId.forEach((item, i) => {
          if (item === undefined) {
            //extracting mail id from vendor
            arr.push(vendor.value[i]?.split("(")[1]?.replace(")", ""));
          }
        });
        //checking if the vendor mail exists
        if (arr.some((item) => item === val)) {
          setVendorRegisteredEmail({
            value: val,
            isValid: false,
            message: "vendor already selected",
          });
        } else {
          setVendorRegisteredEmail({
            value: val,
            isValid: validateEmail(val),
          });
        }
      }}
      error={!VendorRegisteredEmail.isValid}
      errorMsg={
        !emptyCheck(VendorRegisteredEmail.value)
          ? "Email is required"
          : VendorRegisteredEmail?.message
          ? VendorRegisteredEmail?.message
          : !VendorRegisteredEmail.isValid
          ? "Invalid email format"
          : "" || ""
      }
      customClassName={vendorClasses.addVendorPopupInput}
      size={"100%"}
    />,
  ];
  const vendorsTabHeading = (
    <HeaderTitle
      titleText={"Add Vendors"}
      customclass={vendorClasses.popupHeading}
    />
  );
  const popupActions = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleClose1}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      buyerBtn={true}
    />,
    <DButton
      label={"Add"}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      buyerBtn={true}
      error={primaryBtnError.error}
      errorMsg={primaryBtnError.errorMsg}
      loadingState={loading}
      onClick={handleSubmit1}
    />,
  ];
  const getItemDetailsByItemCode = async (item_token, index) => {
    await axios({
      method: "GET",
      url: `${API_ENDPOINT.get_individual_item_by_item_code}${item_token}`,
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        let itemName = res?.data?.Item[0]?.item_name;
        handleItemInputChanges(itemName, "itemName", index);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleItemInputChanges = (e, elementName, index) => {
    const newInputs = [...itemsInput];
    newInputs[index][elementName] = e;
    setItemsInput(newInputs);
  };
  const getItemDetailsByNameCode = async (item_token, index) => {
    await axios({
      method: "GET",
      url: `${API_ENDPOINT.get_individual_item_by_item_code}${item_token}`,
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        let itemCode = res?.data?.Item[0]?.item_code;
        handleItemInputChanges(itemCode, "itemCode", index);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeItemsInput = (index) => {
    let newInputs = [...itemsInput];
    newInputs.splice(index, 1);
    setItemsInput(newInputs);
  };
  const addItemsInputs = () => {
    setItemsInput([
      ...itemsInput,
      {
        itemToken: "",
        itemTokenisValid: false,
        itemMappedToken: "",
        itemCode: "",
        itemCodeisValid: false,
        itemName: "",
        itemNameisValid: false,
        itemQuantity: "",
        itemQuantityisValid: false,
        itemUnit: "",
        itemUnitisValid: false,
        itemDescription: "",
        itemDescriptionisValid: false,
        itemPartNumber: "",
      },
    ]);
  };
  const validateFile = (
    file,
    notRequired,
    allowedFormats,
    maxFileSizeMB,
    fileName
  ) => {
    const result = {
      isValid: true,
      errorMessage: null,
    };

    if (!file && !notRequired) {
      result.isValid = false;
      result.errorMessage = `${fileName || "File"} is required.`;
    } else if (
      notRequired &&
      (file === null || file === "" || typeof file === "string")
    ) {
      // Scenario 1: File not required and can be missing, empty, or a string
      result.isValid = true;
      result.errorMessage = "";
    } else {
      // Scenario 2: File not required but should be valid if provided
      // Scenario 3: File is required, and it should be valid

      if (file && typeof file === "object") {
        // File object (file input)
        result.isValid = true;
        result.errorMessage = "";

        // Check file format (extension)
        if (allowedFormats && Array.isArray(allowedFormats)) {
          const fileExtension = file?.name?.split(".").pop().toLowerCase();
          if (!allowedFormats.includes(fileExtension)) {
            result.isValid = false;
            result.errorMessage = "Invalid file format.";
          }
        }

        // Check file size
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
        if (file.size > maxFileSizeBytes) {
          result.isValid = false;
          result.errorMessage = `File size must be less than ${maxFileSizeMB} MB.`;
        }
      }
    }

    return result;
  };
  const getCountriesList = async () => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_countries,
    })
      .then((res) => {
        setCountry((prev) => ({
          ...prev,
          countryList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStatesList = async (country_token) => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_state,
      data: {
        country_token: country_token,
      },
    })
      .then((res) => {
        setStateName((prev) => ({
          ...prev,
          stateList: res?.data?.response,
        }));
        let filterStates = res?.data?.response?.filter(
          (el, i) => el.name?.toLowerCase() === stateName.value.toLowerCase()
        );

        let state_token = filterStates && filterStates[0]?.token;
        filterStates && setstateToken(filterStates[0]?.token);
        if (state_token && state_token !== null && state_token !== "") {
          getCityList(state_token);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCityList = async (state_token) => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_cities,
      data: {
        state_token: state_token,
      },
    })
      .then((res) => {
        setCity((prev) => ({
          ...prev,
          cityList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const tab1Click = () => {
    setTab1(true);
    setTab2(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const tab2Click = () => {
    setTab1(false);
    setTab2(true);

    //gets states list when the shipping address tab opens
    let filterCountry = country.countryList?.filter(
      (el) => el.name?.toLowerCase() === country?.value?.toLowerCase()
    );
    let country_token = filterCountry && filterCountry[0]?.token;

    filterCountry && setCountryToken(country_token);
    country_token && getStatesList(country_token);
  };
  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen1 = () => setOpen1(true);
  const getStatesListAutoFillCall = async (country_token) => {
    try {
      const response = await axios({
        method: "POST",
        url: API_ENDPOINT.view_state,
        data: {
          country_token: country_token,
        },
      });

      setStateName((prev) => ({
        ...prev,
        stateList: response?.data?.response,
      }));

      let filterStates = response?.data?.response?.filter(
        (el, i) => el.name === stateName.value
      );

      let state_token = filterStates && filterStates[0]?.token;
      filterStates && setstateToken(filterStates[0]?.token);

      if (state_token && state_token !== null && state_token !== "") {
        getCityList(state_token);
      }

      return response.data; // Return the response data
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to handle it outside this function if needed
    }
  };

  const handleSelectedAddress = async (addressData) => {
    let { city, pincode, shipping_address1, shipping_address2, state } =
      addressData;
    setAddress1((prev) => ({
      ...prev,
      value: shipping_address1 ? shipping_address1 : "",
      isValid: validateSelect("Select", shipping_address1),
    }));
    setAddress2({
      value: shipping_address2 ? shipping_address2 : "",
    });
    setCountry((prev) => ({
      ...prev,
      value: addressData.country ? addressData.country : "",
      isValid: validateSelect("Select", addressData.country),
    }));
    setStateName((prev) => ({
      ...prev,
      value: state ? state : "",
      isValid: validateSelect("Select", state),
    }));
    setCity((prev) => ({
      ...prev,
      value: city ? city : "",
      isValid: validateSelect("Select", city),
    }));
    setPincode({
      value: pincode ? pincode : "",
      isValid: emptyCheck(pincode),
    });
    let filterObj = country?.countryList?.filter(
      (el) => el?.name === addressData.country
    );
    filterObj && setCountryToken(filterObj[0]?.token);
    getStatesList(filterObj[0]?.token);

    try {
      const countryToken = filterObj[0]?.token;
      const statesListResponse = await getStatesListAutoFillCall(countryToken);
      let filterObj1 = statesListResponse.response.filter(
        (el) => el.name === state
      );
      if (filterObj1 && filterObj1?.length > 0) {
        filterObj1 && setstateToken(filterObj1[0]?.token);
        getCityList(filterObj1[0]?.token);
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };
  const openRFQMainCancel = () => {
    setIsNewRFQ(false);
    setBtnErrorState({
      error: false,
      errorMsg: "",
    });
  };
  const validateForm = () => {
    let isItemListIsValid = true;

    itemsInput.forEach((el, index) => {
      if (!emptyCheck(el.itemCode)) {
        itemsInput[index].itemCodeisValid = true;
        isItemListIsValid = false;
      }
      if (!emptyCheck(el.itemName)) {
        itemsInput[index].itemNameisValid = true;
        isItemListIsValid = false;
      }
      if (!emptyCheck(el.itemQuantity)) {
        itemsInput[index].itemQuantityisValid = true;
        isItemListIsValid = false;
      }
      if (!emptyCheck(el.itemUnit)) {
        itemsInput[index].itemUnitisValid = true;
        isItemListIsValid = false;
      }
      if (
        !el?.itemDescription ||
        el.itemDescription.trim() === "" ||
        el.itemDescription.length > 2550
      ) {
        itemsInput[index].itemDescriptionisValid = true;
        isItemListIsValid = false;
      }
      if (
        emptyCheck(el.itemCode) &&
        emptyCheck(el.itemName) &&
        emptyCheck(el.itemQuantity) &&
        emptyCheck(el.itemUnit) &&
        el?.itemDescription &&
        (el.itemDescription.trim() !== "") & (el.itemDescription.length <= 2550)
      ) {
        isItemListIsValid = true;
      } else {
        isItemListIsValid = false;
      }
    });
    setItemsInput([...itemsInput]);

    const isItemCSVIsValid = itemCSV.value !== null && itemCSV.value !== "";
    const isRequestNumber = emptyCheck(requestNumber.value);
    let isRFQCategory = true;
    // if (!editRFQForm) {
    //   //not for edit
    //   isRFQCategory = emptyCheck(RFQCategory.value);
    // }
    const isBidType = bidType.value !== "";
    const isDescription =
      proposalDescription.value && proposalDescription.value?.trim() !== "";
    // const isProposalDocument = validateFile(
    //   proposalDocument.value,
    //   true,
    //   ["pdf", "docx", "xlsx", "xls", "ppt", "pptx"],
    //   5
    // ).isValid;
    const isfromDate = fromDate.value !== null && fromDate.value !== "";
    const istoDate = toDate.value !== null && toDate.value !== "";
    const istime = time.value !== "" && time?.value !== null;
    const isVendorList =
      vendor.value && vendor.value?.length > 0 && vendorId?.length > 0;
    const isAddress1 = emptyCheck(address1.value);
    const isCountry = emptyCheck(country.value);
    const isState = emptyCheck(stateName.value);
    const isCity = emptyCheck(city.value);
    const isMobileNumber =
      emptyCheck(mobileNumber.value) && mobileNumber.isValid;
    const isPincode = emptyCheck(pincode.value);

    setItemCSV((prev) => ({ ...prev, isValid: isItemCSVIsValid }));
    setRequestNumber((prev) => ({ ...prev, isValid: isRequestNumber }));
    // !editRFQForm && //not for edit
    //   setRFQCategory((prev) => ({ ...prev, isValid: isRFQCategory }));
    setBidType((prev) => ({ ...prev, isValid: isBidType }));
    setFromDate((prev) => ({ ...prev, isValid: isfromDate }));
    setToDate((prev) => ({ ...prev, isValid: istoDate }));
    setTime((prev) => ({ ...prev, isValid: istime, isChanged: false }));
    setVendor((prev) => ({ ...prev, isValid: isVendorList }));
    setAddress1((prev) => ({ ...prev, isValid: isAddress1 }));
    setCountry((prev) => ({ ...prev, isValid: isCountry }));
    setStateName((prev) => ({ ...prev, isValid: isState }));
    setCity((prev) => ({ ...prev, isValid: isCity }));
    setMobileNumber((prev) => ({ ...prev, isValid: isMobileNumber }));
    // setProposalDocument((prev) => ({ ...prev, isValid: isProposalDocument }));
    setPincode((prev) => ({ ...prev, isValid: isPincode }));

    // let itemType = uploadItemDocument.uploadFile
    //   ? isItemCSVIsValid
    //   : uploadItemDocument.inputs && isItemListIsValid;

    let proposalDoc;

    if (itemCSV.value || itemsInput.length > 1) {
      proposalDoc = true;
      setProposalDescription((prev) => ({ ...prev, isValid: true }));
    } else {
      proposalDoc = true;
      setProposalDescription((prev) => ({
        ...prev,
        isValid: isDescription,
      }));
    }
    return (
      isRequestNumber &&
      isRFQCategory &&
      isBidType &&
      isfromDate &&
      istoDate &&
      istime &&
      isVendorList &&
      isAddress1 &&
      isCountry &&
      isState &&
      isCity &&
      isMobileNumber &&
      proposalDoc &&
      isPincode
    );
  };
  const submitNewVendors = async () => {
    let vendor_details = [];
    vendorId.forEach((itm, i) => {
      if (itm === undefined) {
        let detailStr = vendor.value[i]?.split("(");
        vendor_details.push(
          `${detailStr[0]?.trim()}|${detailStr[1].replace(")", "")}`
        );
      }
    });
    return axios({
      method: "POST",
      url: API_ENDPOINT.rfq_add_new_vendor,
      data: {
        vendor_details,
        buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        setVendorID((prev) => ({
          ...prev,
          value: [
            ...prev?.value?.filter(Boolean),
            ...response?.data?.response?.map((item) => item.token),
          ],
        }));
        return response?.data?.response?.map((item) => item.token);
      })
      .catch((e) => {});
  };
  const sendNewRFQToken = async (token) => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.rfq_newversion_chatgpt}`,
      data: {
        rfq_token: token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const submitNewRFQ = async (formData) => {
    setLoadingState(true);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.new_RFQ} `,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        handleOpen();

        setSuccessModalContent({
          title: "Purchase request completed successfully",
          description: res?.data?.response,
          icon: successPopupImg,
        });
        // setPopupHeroText("Purchase request completed successfully");
        // setPopupMessage(res?.data?.response);
        setLoadingState(false);
        setBtnErrorState({
          error: false,
          errorMsg: "",
        });
        callPrReqDraft(rfqToken);
        if (res?.data?.item_status) {
          sendNewRFQToken(res?.data?.rfq_token);
        }
      })
      .catch((err) => {
        handleClose();
        setBtnErrorState({
          error: true,
          errorMsg:
            Object.values(err?.response?.data)[0] ||
            "Please fill out all fields",
        });
        setLoadingState(false);
      });
  };
  const callPrReqDraft = async (proToken) => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.completed_pr_rfq_draft}`,
      data: { pr_token: proToken },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        isNewRFQ(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // formdata
  const formData = new FormData();
  formData.append("buyer_token", buyer_token);
  formData.append("category_compare", RFQCategory.id);
  formData.append("purchase_request_number", requestNumber.value);
  formData.append("bidding_type", bidType.value);
  const formattedFromDate =
    fromDate.value && new Date(fromDate.value).toISOString().slice(0, 19) + "Z";
  formData.append("bid_from_date", formattedFromDate);
  formData.append("bid_to_date", toDate.value);
  formData.append("bid_time", time.value);
  formData.append("proposal_document", "");
  // formData.append("bid_status", "");
  const selectedIdsList =
    vendorId && vendorId?.length === 1 ? [vendorId[0]] : vendorId;
  formData.append("select_vendor", selectedIdsList);

  formData.append("shipping_address1", address1.value);
  formData.append("shipping_address2", address2.value ? address2.value : "");
  formData.append("country", country.value);
  formData.append("city", city.value);
  formData.append("pincode", pincode.value);
  formData.append("state", stateName.value);
  formData.append("mobile_number", mobileNumber.value);
  let itemsListFormatted =
    itemsInput.length >= 1 &&
    itemsInput?.map((e, i) => {
      return {
        item_token: e.itemToken,
        quantity: e.itemQuantity,
        unit: e.itemUnit,
        part_number: e.itemPartNumber ? e.itemPartNumber : "",
        description: e?.itemDescription,
      };
    });
  formData.append("item_code", JSON.stringify(itemsListFormatted));
  // let itemsListFormattedForUpdate =
  //   itemsInput.length >= 1 &&
  //   itemsInput?.map((e, i) => {
  //     return {
  //       item_token: e.itemToken,
  //       quantity: e.itemQuantity,
  //       item_mapped_token: e.itemMappedToken,
  //       unit: e.itemUnit,
  //       description: e.itemDescription,
  //       part_number: e.itemPartNumber,
  //     };
  //   });

  // formData.append(
  //   "items_data_list",
  //   JSON.stringify(itemsListFormattedForUpdate)
  // );
  // deletedItemsToken.length >= 1 &&
  //   formData.append("delete_item", deletedItemsToken);

  //RFQ submit click
  const handleSubmit = async () => {
    let biddingDetails =
      bidType.value &&
      fromDate.value &&
      toDate.value &&
      time.value &&
      vendorId.length > 0;

    let shippingDetails =
      address1.value &&
      country.value &&
      stateName.value &&
      city.value &&
      mobileNumber.value &&
      pincode.value;

    const validation = validateForm();
    const newVendorEmails = vendorId.filter((item) => item === undefined);

    if (validation) {
      // setLoadingState(true);
      if (newVendorEmails.length !== 0) {
        let res = await submitNewVendors();
        if (res && Boolean(res?.length)) {
          formData.delete("select_vendor");
          formData.append("select_vendor", [
            ...vendorId.filter(Boolean),
            ...res,
          ]);
        }
      }
      await submitNewRFQ(formData);
    } else if (biddingDetails && !shippingDetails) {
      tab2Click();
    } else if (!biddingDetails && shippingDetails) {
      tab1Click();
    } else {
      setBtnErrorState({
        error: true,
        errorMsg: "Please fill out all fields.",
      });
    }
  };
  //get individual data
  const viewAllItem = async (org_token) => {
    setOpenForm({
      open: false,
      loading: true,
      errorMessage: "",
    });
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.view_all_item}`,
      data: {
        org_token: org_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        setOpenForm({
          open: true,
          loading: false,
          errorMessage: "",
        });
        getAllVendorsData();
        setRFQItemsData(res?.data?.response);
        setItemList({
          values: {
            tokens: res?.data?.response?.map((e, i) => {
              return e?.token;
            }),
            visibleOption: res?.data?.response?.map((e, i) => {
              return e?.item_code;
            }),
          },
        });
        setItemNameList({
          values: {
            tokens: res?.data?.response?.map((e, i) => {
              return e?.token;
            }),
            visibleOption: res?.data?.response?.map((e, i) => {
              return e?.item_name;
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setOpenForm({
          open: false,
          loading: false,
          errorMessage: Object.values(err?.response?.data),
        });
      });
  };
  //get all data
  const getAllVendorsData = async () => {
    setOpenForm({
      open: false,
      loading: true,
      errorMessage: "",
    });
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.get_all_vendor}`,
      data: {
        org_token: buyer_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setOpenForm({
          open: true,
          loading: false,
          errorMessage: "",
        });
        setVendorsList({
          values: {
            tokens: res?.data?.users?.map((e) => {
              return e?.token;
            }),
            visibleOption: res?.data?.users?.map((e) => {
              return e?.company_name + " (" + e?.company_official_email + ")";
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setOpenForm({
          open: false,
          loading: false,
          errorMessage: Object.values(err?.response?.data),
        });
      });
  };
  const getIndividualData = async (rfqToken, RfqDraftData) => {
    setOpenForm({
      open: false,
      loading: true,
      errorMessage: "",
    });
    const { pr_item_list, rfq_details } = RfqDraftData.RFQDraftData.data;
    let responseData = rfq_details;
    let itemsData = pr_item_list;
    setItemsListDataViewRFQ(itemsData);
    setUniqueData(responseData);
    setRFQToken(rfqToken);
    setOpenForm({
      open: true,
      loading: false,
      errorMessage: "",
    });
    let mappedItemsList = itemsData?.map((el, i) => {
      // check if item code is availabe or not
      let availableItem = RFQItemsData.filter(
        (item) => item.item_code === el.item_code
      );
      const { ...item } = availableItem.length > 0 && availableItem[0];
      let isItemAvailable =
        availableItem.length > 0 && Object.keys(item).length > 0;
      return {
        itemToken: isItemAvailable ? item.token : "",
        itemTokenisValid: !isItemAvailable,

        itemMappedToken: el.item_mapped_token,

        itemCode: isItemAvailable ? item.item_code : "",
        itemCodeisValid: !isItemAvailable,

        itemName: isItemAvailable ? item.item_name : "",
        itemNameisValid: isItemAvailable,

        itemQuantity: el.quantity,
        itemQuantityisValid: false,

        itemUnit: el.unit,
        itemUnitisValid: false,

        itemDescription: el.description,
        itemDescriptionisValid: false,

        itemPartNumber: el.part_number,
      };
    });
    setItemsInput(mappedItemsList);
    setRequestNumber({
      value: responseData?.pr_request_no,
      isValid: true,
    });
    let category = RFQCategory.categoryList.filter(
      (item) => item.id === responseData.pr_category
    );
    setRFQCategory({
      ...RFQCategory,
      value: category[0].category,
      id: category[0].id,
    });
    setBidType({
      value: responseData?.bidding_type,
      isValid: true,
    });

    let startDate = dayjs(responseData?.bid_from_date).format(
      "YYYY-MM-DD HH:mm:ss.SSSSSS"
    );
    let endDate = dayjs(responseData?.bid_to_date).format(
      "YYYY-MM-DD HH:mm:ss.SSSSSS"
    );
    dayjs.extend(utc);

    const endTime = dayjs
      .utc(responseData?.bid_time)
      .local()
      .format("YYYY-MM-DD HH:mm:ss.SSSSSS");

    setFromDate({
      value: startDate,
      isValid: true,
    });
    setToDate({
      value: endDate,
      isValid: true,
    });
    setTime({
      value: endTime,
      isChanged: false,
      isValid: true,
    });
    let finalVendorArray = responseData?.select_vendor?.split(",");
    // setVendor({
    //   value: finalVendorArray,
    //   isValid: true,
    // });
    setExistingVendorList(finalVendorArray);
    setAddress1({
      value: responseData?.shipping_address1,
      isValid: true,
    });
    setAddress2({
      value: responseData?.shipping_address2,
      isValid: true,
    });
    setCountry((prev) => ({
      ...prev,
      value: responseData?.country,
      countryList: [],
      isValid: true,
    }));
    setStateName((prev) => ({
      ...prev,
      value: responseData?.state,
      stateList: [],
      isValid: true,
    }));
    setCity((prev) => ({
      ...prev,
      value: responseData?.city,
      cityList: [],
      isValid: true,
    }));
    setMobileNumber({
      value: responseData?.mobile_number,
      isValid: true,
    });
    setUploadDocument({
      uploadDescripton: responseData?.proposal_description ? true : false,
      uploadFile: responseData?.proposal_document ? true : false,
    });
    setProposalDescription({
      value: responseData?.proposal_description,
      isValid: true,
    });
    setPincode({
      value: responseData?.pincode,
      isValid: true,
    });
  };
  //get RFQ category list
  const getCategoryList = () => {
    axios({
      method: "GET",
      url: `${API_ENDPOINT.list_of_prompt_category}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        let promptList = response.data.response;
        setRFQCategory({
          ...RFQCategory,
          categoryList: promptList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={classes.users_tab_wrapper}>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} flex`}>
          <HeaderTitle titleText="RFQ Draft" customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} flex`}>
          <DateBar />
          <NotificationBell />
          <ProfileCard
            buyerBtn={true}
            buyerBtnOutline={true}
            profilePath={"/Buyer_dashboard/My_profile"}
          />
        </div>
      </div>
      {!isNewRFQ ? (
        <>
          <div className={classes.rfqDrafSection}>
            <div className={classes.poListRow}>
              <div className={classes.search_add_wrapper}>
                <div
                  className={`${classes.flex} ${classes.flex_align_end}  ${classes.search_add_wrapper_content_left}`}
                >
                  <SearchBar
                    value={searchQuery}
                    onChange={(query) => {
                      setSearchQuery(query);
                      setPageNumber(1);
                    }}
                  />
                </div>
              </div>
              <div className={`${classes.d_dataTable} `}>
                <DataTable
                  headings={headings}
                  tableData={tableData}
                  tableSize={"100%"}
                  actions={true}
                  errorMessage={tableProps.errorMessage}
                  isLoading={tableProps.isLoading}
                  viewBtn={false}
                  editBtn={true}
                  deleteBtn={true}
                  sorting={false}
                  viewClick={() => {}}
                  editClick={handleEdit}
                  deleteClick={handleDelete}
                  setSortingColumnName={() => {
                    return null;
                  }}
                  sortingColumnName={null}
                  setSortingOrder={() => {
                    return null;
                  }}
                  sortingOrder={null}
                />
                <PaginantionComponent
                  pageNumber={pageNumber}
                  currentPage={currentPage || 0}
                  itemsPerPage={itemsPerPage || 0}
                  totalItems={totalItems || 0}
                  previouPageClick={() => {
                    previouPageClick(pageNumber, setPageNumber);
                  }}
                  nextPageClick={() => {
                    nextPageClick(pageNumber, setPageNumber, paginationData);
                  }}
                  setPageNumber={setPageNumber}
                  disablePrevious={
                    parseInt(pageNumber) === 1 || pageNumber === ""
                  }
                  disableNext={
                    parseInt(pageNumber) === paginationData?.page_count ||
                    !paginationData ||
                    pageNumber === ""
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.rfqCreateSection}>
          <div className={classes.tabsScreens}>
            <div className={classes.docField}>
              <div className={classes.input_container}>
                <div className={classes.itemsListWrapper}>
                  <span className={classes.headingSm}>Items</span>
                  <div className={`${classes.multiForm}`}>
                    {itemsInput?.length >= 1 &&
                      itemsInput?.map((el, index) => {
                        return (
                          <div
                            className={`${classes.items_form_wrapper}`}
                            key={index}
                          >
                            <div className={`${classes.itemCount}`}>
                              <span>Item #{index + 1}</span>
                            </div>
                            <div
                              className={
                                classes.input_fields_wrapper_multi_line1
                              }
                            >
                              <DSelectInput
                                customClass={classes.fields}
                                label="Item Code"
                                value={el.itemCode}
                                options={itemList.values.visibleOption}
                                onChange={(e) => {
                                  handleItemInputChanges(e, "itemCode", index);

                                  let itemToken =
                                    itemList.values.tokens[
                                      itemList.values.visibleOption.indexOf(e)
                                    ];

                                  itemToken !== null &&
                                    itemToken !== undefined &&
                                    itemToken !== "" &&
                                    getItemDetailsByItemCode(itemToken, index);
                                  handleItemInputChanges(
                                    itemToken,
                                    "itemToken",
                                    index
                                  );

                                  let isValid =
                                    !emptyCheck(el.itemCode) &&
                                    el?.itemCode === null;
                                  handleItemInputChanges(
                                    isValid,
                                    "itemCodeisValid",
                                    index
                                  );

                                  handleItemInputChanges(
                                    false,
                                    "itemNameisValid",
                                    index
                                  );

                                  if (!e) {
                                    handleItemInputChanges(
                                      "",
                                      "itemName",
                                      index
                                    );
                                    handleItemInputChanges(
                                      true,
                                      "itemNameisValid",
                                      index
                                    );
                                  }
                                }}
                                error={el.itemCodeisValid}
                                errorMsg={"Item Code is required"}
                                size={"100%"}
                              />
                              <DSelectInput
                                customClass={classes.fields}
                                label="Item Name"
                                value={el.itemName}
                                options={itemNameList.values.visibleOption}
                                onChange={(e) => {
                                  handleItemInputChanges(e, "itemName", index);

                                  let itemToken =
                                    itemNameList.values.tokens[
                                      itemNameList.values.visibleOption.indexOf(
                                        e
                                      )
                                    ];
                                  itemToken !== null &&
                                    itemToken !== undefined &&
                                    itemToken !== "" &&
                                    getItemDetailsByNameCode(itemToken, index);
                                  handleItemInputChanges(
                                    itemToken,
                                    "itemToken",
                                    index
                                  );

                                  let isValid = !emptyCheck(el.itemCode);
                                  handleItemInputChanges(
                                    isValid,
                                    "itemNameisValid",
                                    index
                                  );

                                  handleItemInputChanges(
                                    false,
                                    "itemCodeisValid",
                                    index
                                  );

                                  if (!e) {
                                    handleItemInputChanges(
                                      "",
                                      "itemCode",
                                      index
                                    );
                                    handleItemInputChanges(
                                      true,
                                      "itemCodeisValid",
                                      index
                                    );
                                  }
                                }}
                                error={el.itemCodeisValid}
                                errorMsg={"Item Name is required"}
                                size={"100%"}
                              />
                            </div>
                            <div
                              className={`${classes.input_fields_wrapper_multi_line2}`}
                            >
                              <DTextInput
                                customClassName={classes.fields}
                                label="Quantity"
                                maxLength={250}
                                type="text"
                                value={el.itemQuantity}
                                onChange={(e) => {
                                  let val = e?.target?.value?.replace(
                                    regexForNumber,
                                    ""
                                  );
                                  let isValid = !emptyCheck(e?.target?.value);
                                  handleItemInputChanges(
                                    isValid,
                                    "itemQuantityisValid",
                                    index
                                  );
                                  handleItemInputChanges(
                                    val,
                                    "itemQuantity",
                                    index
                                  );
                                }}
                                error={el.itemQuantityisValid}
                                errorMsg={"Quantity is required"}
                                size={"100%"}
                              />

                              <DSelectInput
                                customClass={classes.fields}
                                label="Unit"
                                value={el.itemUnit}
                                options={unitsOptions}
                                onChange={(e) => {
                                  let val = e;
                                  let isValid = !emptyCheck(val);
                                  handleItemInputChanges(
                                    isValid,
                                    "itemUnitisValid",
                                    index
                                  );
                                  handleItemInputChanges(
                                    val,
                                    "itemUnit",
                                    index
                                  );
                                }}
                                error={el.itemUnitisValid}
                                errorMsg={"Unit is required"}
                                size={"100%"}
                              />

                              <DTextInput
                                customClass={classes.fields}
                                label="Part Number (Optional)"
                                value={el.itemPartNumber}
                                onChange={(e) => {
                                  let val = e.target.value?.replace(
                                    regexForAlphaNumeric,
                                    ""
                                  );
                                  // setPartNumber({
                                  //   value: val,
                                  // });
                                  handleItemInputChanges(
                                    val,
                                    "itemPartNumber",
                                    index
                                  );
                                }}
                                size={"100%"}
                              />
                            </div>

                            <DTextArea
                              label="Item Description"
                              type="text"
                              placeholder={"description"}
                              value={el.itemDescription}
                              onChange={(e) => {
                                let val = e?.target?.value;
                                let isValid;
                                if (val && val.length > 2550) {
                                  isValid = true;
                                } else if (val?.trim() === "") {
                                  isValid = true;
                                } else {
                                  isValid = false;
                                }
                                handleItemInputChanges(
                                  isValid,
                                  "itemDescriptionisValid",
                                  index
                                );
                                handleItemInputChanges(
                                  val,
                                  "itemDescription",
                                  index
                                );
                              }}
                              containerClass={classes.itemDescription}
                              customClassName={classes.itemDescriptionTextArea}
                              inputWrapperClass={classes.textAreaWrapper}
                              error={el.itemDescriptionisValid}
                              errorMsg={
                                el?.itemDescription &&
                                el?.itemDescription.length > 2550
                                  ? "Item Description should be less than 2550 characters"
                                  : el.itemDescriptionisValid
                                  ? "Item Description is required"
                                  : ""
                              }
                              size={"95.5%"}
                            />

                            {index !== 0 && (
                              <button
                                onClick={() => {
                                  // let dlt= deletedItemsToken.push(el.itemToken);
                                  if (
                                    el.itemToken !== null &&
                                    el.itemToken !== "" &&
                                    el.itemToken &&
                                    el.itemMappedToken !== null &&
                                    el.itemMappedToken !== "" &&
                                    el.itemMappedToken
                                  ) {
                                    setDeletedItemsToken([
                                      ...deletedItemsToken,
                                      el.itemMappedToken,
                                    ]);
                                  }
                                  removeItemsInput(index);
                                }}
                                className={`${classes.removeFormButton}`}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        );
                      })}

                    <div className={`${classes.addNewForm}`}>
                      <button
                        onClick={addItemsInputs}
                        className={`${classes.addNewFormButton}`}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.docField}>
              <div className={classes.unitsInputs}>
                <DTextInput
                  customClass={classes.uniqueSelect}
                  label="Request Number"
                  value={requestNumber.value}
                  onChange={(e) => {
                    let val = e?.target?.value;
                    setRequestNumber({
                      value: val,
                      isValid: emptyCheck(e.target.value),
                    });
                  }}
                  error={!requestNumber.isValid}
                  errorMsg={"Request Number is required"}
                  size={"50%"}
                />
                <ThemeProvider theme={theme}>
                  <div
                    className={`${styles.select_container}`}
                    style={{ width: "50%" }}
                  >
                    <label>Select Quotation Category</label>
                    <Autocomplete
                      value={RFQCategory.value}
                      onChange={(event, newValue) => {
                        setRFQCategory((state) => ({
                          ...state,
                          value: newValue?.category ? newValue?.category : "",
                          id: newValue?.id ? newValue?.id : "",
                          isValid: newValue?.category ? true : false,
                        }));
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.category
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      options={RFQCategory.categoryList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.category) {
                          return option.category;
                        }
                        // Regular option
                        return option.category;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.category}</li>
                      )}
                      freeSolo
                      forcePopupIcon
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          placeholder="Select quotation category"
                        />
                      )}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="quotation_category"
                      size="small"
                      className={`${styles.select_wrapper_multiSelect} ${
                        !RFQCategory.isValid ? styles.error : ""
                      }`}
                      sx={{
                        minHeight: "4.2rem",
                        height: "auto",
                        fontSize: "1.4rem",
                        transition: "0",
                        ".Mui-expanded": {
                          transition: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "0",
                        },
                        ".MuiAutocomplete-input": {
                          fontSize: "1.4rem",
                          fontFamily: "GilroyMedium",
                          height: "2.6rem",
                        },
                        ".MuiFormLabel-root": {
                          display: "none",
                        },
                        ".MuiChip-root": {
                          height: "30px",
                          padding: "5px",
                        },
                      }}
                      componentsProps={{
                        popper: {
                          sx: {
                            fontFamily: "gilroyMedium",
                          },
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false,
                            },
                            {
                              name: "preventOverflow",
                              enabled: true,
                            },
                          ],
                        },
                      }}
                    />
                    {!RFQCategory.isValid && (
                      <p className={styles.error_message}>
                        Quotation Category is required
                      </p>
                    )}
                  </div>
                </ThemeProvider>
              </div>
            </div>

            <div className={classes.ViewVendorHeaderSM}>
              <DetailsTab
                small={true}
                activeColor={"#3B86C7"}
                tabName={"Bidding Details"}
                currentTab={tab1}
                clickFn={tab1Click}
              />
              <DetailsTab
                small={true}
                activeColor={"#3B86C7"}
                tabName={"Shipping Address"}
                currentTab={tab2}
                clickFn={tab2Click}
              />
            </div>
            {tab1 && (
              <>
                <div className={classes.bidType}>
                  <div className={classes.statusSelect}>
                    <div className={classes.input_container}>
                      <DefaultLabel label={"Bid Type"} />

                      <div className={classes.util}>
                        <DRadioInput
                          label={"Auction"}
                          id={"open"}
                          value={"open"}
                          checked={bidType.value === true}
                          customClass={classes.radioTag}
                          onChange={() => {
                            setBidType({
                              value: true,
                            });
                          }}
                          name={"bidType"}
                        />

                        <DRadioInput
                          label={"Closed"}
                          id={"close"}
                          checked={bidType.value === false}
                          customClass={classes.radioTag}
                          value={"close"}
                          onChange={() => {
                            setBidType({
                              value: false,
                            });
                          }}
                          name={"bidType"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={classes.bidStatus}>
                    <DDateInput
                      customClassName={classes.bidInputs}
                      label={"From Date"}
                      value={fromDate}
                      disabledInput={editRFQForm}
                      onChange={(e) => {
                        setFromDate({
                          value: e,
                          isValid:
                            e !== null ||
                            e !== null ||
                            e !== "" ||
                            e !== undefined ||
                            fromDate.value !== "",
                        });
                      }}
                      disablePast={true}
                      error={!fromDate.isValid}
                      errorMsg={"From Date is required"}
                      size={"26rem"}
                    />

                    <DDateInput
                      customClassName={classes.bidInputs}
                      label={"To Date"}
                      value={toDate}
                      fromDate={
                        fromDate.value !== ""
                          ? uniqueData?.bid_from_date
                          : fromDate.value
                      }
                      onChange={(e) => {
                        setToDate({
                          value: e,
                          isValid:
                            e !== null ||
                            e !== "" ||
                            e !== undefined ||
                            fromDate.value !== "",
                        });
                        setTime({
                          value: null,
                          isValid: true,
                        });
                      }}
                      disablePast={true}
                      error={!toDate.isValid}
                      errorMsg={"To Date is required"}
                      size={"26rem"}
                    />

                    <DTextInput
                      customClassName={classes.bidInputs}
                      label={"End Time"}
                      value={time.value}
                      endDate={toDate.value}
                      onChange={(e) => {
                        setTime({
                          value: e,
                          isChanged: true,
                          isValid:
                            e !== null ||
                            e !== "" ||
                            e !== undefined ||
                            time.value !== "",
                        });
                      }}
                      error={!time.isValid}
                      errorMsg={"Time is required"}
                      type={"time"}
                      size={"26rem"}
                    />
                  </div>

                  {fromDate.value && toDate.value && time.value && (
                    <DaysReminder
                      startDate={fromDate.value}
                      endDate={toDate.value}
                      endTime={time.value}
                    />
                  )}

                  <DSelectInput
                    isEdit={false}
                    customClass={classes.fields}
                    multiselect={true}
                    label="Select Vendor"
                    value={vendor.value}
                    existingVendorList1={existingVendorList}
                    options={vendorsList.values}
                    setIDValue={setVendorID}
                    onChange={(value) => {
                      setVendor({
                        value: value,
                        isValid: value?.length !== 0,
                      });
                    }}
                    error={!vendor.isValid}
                    errorMsg={"Vendor is required"}
                    size={"100%"}
                  />
                </div>
                <Dbutton
                  labelImg={plusIcon}
                  iconHeight={"15px"}
                  iconwidth={"15px"}
                  label="ADD VENDOR"
                  btnHeight="4rem"
                  buyerBtn={true}
                  onClick={() => {
                    handleOpen1();
                  }}
                />
              </>
            )}
            {tab2 && (
              <>
                <div className={classes.input_fields_wrapper}>
                  {!shippingAddress ? (
                    <DTextInput
                      customClassName={classes.fields}
                      label="Address Line 1"
                      type="text"
                      value={address1.value}
                      onChange={(e) => {
                        let val = e.target.value;
                        setAddress1({
                          value: val,
                          isValid: emptyCheck(val),
                        });
                      }}
                      error={!address1.isValid}
                      errorMsg={"Address Line 1 is required"}
                      size={"100%"}
                    />
                  ) : (
                    <DSearchInputDropdown
                      addresses={shippingAddress}
                      selectHandler={handleSelectedAddress}
                      hanlderSearch={(e) => {
                        let val = e;
                        setAddress1({
                          value: val,
                          isValid: emptyCheck(val),
                        });
                      }}
                      searchTerm={address1}
                    />
                  )}

                  <DTextInput
                    customClassName={classes.fields}
                    label="Address Line 2 (Optional)"
                    type="text"
                    value={address2.value}
                    onChange={(e) => {
                      let val = e.target.value;
                      setAddress2({
                        value: val,
                      });
                    }}
                    size={"100%"}
                  />

                  <DSelectInput
                    label="Country"
                    defaultDropdown={true}
                    value={country.value}
                    noInit={true}
                    onChange={(e) => {
                      setCountry((prev) => ({
                        ...prev,
                        value: e,
                        isValid: validateSelect("Select", e),
                      }));
                      setStateName({
                        value: "",
                        stateList: [],
                        isValid: true,
                      });
                      setCity({
                        value: "",
                        cityList: [],
                        isValid: true,
                      });
                      setstateToken();
                      let filterObj =
                        e && country.countryList?.filter((el) => el.name === e);
                      filterObj && setCountryToken(filterObj[0]?.token);
                      e && getStatesList(filterObj[0]?.token);
                    }}
                    options={
                      country.countryList?.map((el) => el.name) || ["Select"]
                    }
                    error={!country.isValid}
                    errorMsg={country.isValid ? "" : "Country is required"}
                    customClassName={classes.field}
                  />

                  <DSelectInput
                    label="State/Province/Region"
                    defaultDropdown={true}
                    value={stateName.value}
                    disabled={!countryToken && !editRFQForm}
                    noInit={true}
                    onChange={(e) => {
                      setStateName((prev) => ({
                        ...prev,
                        value: e,
                        isValid: validateSelect("Select", e),
                      }));
                      setCity({
                        value: "",
                        cityList: [],
                        isValid: true,
                      });
                      let filterObj =
                        e && stateName.stateList?.filter((el) => el.name === e);
                      filterObj && setstateToken(filterObj[0]?.token);
                      e && getCityList(filterObj[0]?.token);
                    }}
                    options={
                      stateName.stateList?.length >= 1
                        ? stateName.stateList?.map((el) => el.name)
                        : ["No states available"]
                    }
                    error={!stateName.isValid}
                    errorMsg={
                      stateName.isValid
                        ? ""
                        : "State/Province/Region is required"
                    }
                    customClassName={classes.field}
                  />

                  <DSelectInput
                    label="City"
                    noInit={true}
                    defaultDropdown={true}
                    value={city.value}
                    disabled={!stateToken && !editRFQForm}
                    onChange={(e) => {
                      setCity((prev) => ({
                        ...prev,
                        value: e,
                        isValid: validateSelect("Select", e),
                      }));
                    }}
                    options={
                      city.cityList?.length >= 1
                        ? city.cityList?.map((el) => el.name)
                        : ["No cities available"]
                    }
                    error={!city.isValid}
                    errorMsg={city.isValid ? "" : "City is required"}
                    customClassName={classes.field}
                  />

                  <DTextInput
                    customClassName={classes.fields}
                    label="Postal/Zipcode"
                    type="text"
                    maxLength={50}
                    value={pincode.value}
                    onChange={(e) => {
                      let val = e.target.value?.replace(regexForNumber, "");
                      setPincode({
                        value: val,
                        isValid: emptyCheck(e.target.value),
                      });
                    }}
                    error={!pincode.isValid}
                    errorMsg={"Postal/Zipcode is required"}
                    size={"100%"}
                  />

                  <DPhoneNumberInput
                    label="Mobile Number"
                    value={mobileNumber.value}
                    placeholder={mobileNumber.value}
                    onChange={(
                      value,
                      data,
                      isValidMobileNumber,
                      formattedValue
                    ) => {
                      // checking the value only for numbers
                      if (
                        (editRFQForm &&
                          (value === "" ||
                            value?.includes("+") ||
                            numberOnlyRegex.test(value))) ||
                        (newRFQForm &&
                          (value === "" || numberOnlyRegex.test(value)))
                      ) {
                        setMobileNumber({
                          value: formattedValue,
                          format: data?.dialCode,
                          isValid:
                            emptyCheck(formattedValue) && isValidMobileNumber,
                        });
                      }
                    }}
                    error={!mobileNumber.isValid}
                    errorMsg={
                      !emptyCheck(mobileNumber.value)
                        ? "Mobile Number is required"
                        : !mobileNumber.isValid
                        ? "Invalid phone number"
                        : ""
                    }
                    size={"100%"}
                  />
                </div>
              </>
            )}
            <div className={classes.actions}>
              <DButtonOutlined
                buyerBtn={true}
                label="Cancel"
                btnHeight="4rem"
                btnWidth={"14.4rem"}
                onClick={openRFQMainCancel}
              />
              <DButton
                buyerBtn={true}
                label={"save"}
                loadingState={loadingState}
                error={btnErrorState.error}
                errorMsg={btnErrorState.errorMsg}
                btnHeight="4rem"
                btnWidth={"14.4rem"}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
      {/* delete error modal two states - confirmation and deletion */}
      {/* confirmation */}
      <Modal open={openDltConfirmation}>
        <Fade in={openDltConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText="Are you sure want to delete?"
              popupDescription={""}
              popBtn={true}
              btnText={"Delete"}
              loadingPrimaryBtn={isPoDeleting}
              secondaryBtn={true}
              vendorBtn={true}
              vendorBtnOutlined={true}
              btnTextSecondary={"Cancel"}
              btnClick={handleDeleteRfqDraft}
              btnClickSecondary={handleCloseDltConfirmation}
            />
          </Box>
        </Fade>
      </Modal>
      {/* success modal popup */}
      <Modal open={open}>
        <Fade in={open}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successModalContent.icon}
              popupHeroText={successModalContent.title}
              popupDescription={successModalContent.description}
              popBtn={true}
              vendorBtn={true}
              btnText={"Okay"}
              btnClick={CloseRfqDraftSuccessModal}
            />
          </Box>
        </Fade>
      </Modal>
      {/* add vendor modal popup */}
      <Modal
        open={open1}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={open1}>
          <div
            className={classes.flex}
            style={{
              height: "100vh",
            }}
          >
            <PopupFrame
              customClass={vendorClasses.InviteVendorPopup}
              styleCenterDiv={classes.centerDiv}
              inputChildren={vendorsTabInputs}
              poupHeading={vendorsTabHeading}
              actionButtons={popupActions}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default RFQDraftTab;

import React, { useEffect, useState } from 'react'
import classes from './NotificationBell.module.css'
import { Skeleton } from '@mui/material'
import { Check } from '@mui/icons-material'

const NotificationCard = ({ notificationsData, filter, isLoading, notificationClick }) => {

    const [localCopy, setLocalCopy] = useState(notificationsData);
    useEffect(() => {
        if (filter === "All") {
            setLocalCopy(notificationsData);
        }
        else if (filter === 'Read') {
            setLocalCopy(notificationsData?.filter((e) => e?.status === true));
        }
        else if (filter === "Unread") {
            setLocalCopy(notificationsData?.filter((e) => e?.status === false));
        }
    }, [filter, notificationsData])

    return (
        <div>
            {localCopy?.length > 0 ? localCopy?.map((e, i) => {
                return (
                    <div className={`${classes.notificationCard} ${e?.status === false && classes.unread}`} key={i} onClick={() => {
                        e?.status === false && notificationClick(e?.id)
                    }}>
                        {
                            !isLoading ? <div style={{ width: '100%' }}>
                                <p>
                                    {e?.message}
                                </p>
                                <div className={classes.notificationCardFooter}>
                                    <span className={classes.createdDate}> {e?.created}</span>
                                    {e?.status === true && <Check />}
                                </div>
                            </div> :
                                <>
                                    <Skeleton height={"25px"} />
                                    <Skeleton height={"25px"} width={"40%"} />
                                </>
                        }
                    </div>
                )
            }) : <div className={classes.notificationError}>
                <p>No Notification found!</p>
            </div>}
        </div>
    )
}

export default NotificationCard
import React, { useEffect, useState } from "react";

//img
import profileImg from "../../../assets/images/png/Profile.png";
import btnImg from "../../../assets/images/svg/Search.svg";
import totalPOIMg from "../../../assets/images/svg/sales order.svg";
import totalPOIMg2 from "../../../assets/images/svg/totalRfq.svg";
import totalPOIMg3 from "../../../assets/images/svg/Total Bids.svg";
import totalPOIMg4 from "../../../assets/images/svg/confirm order.svg";

//compnents
import DateBar from "../../../components/common/DateBar/DateBar";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";

//css
import classes from "../../TabStyles/DashboardTab.module.css";

//components
import DSelectInput from "../../../components/Dashboard/DashboardInputs/DSelectInput";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DDateInput from "../../../components/Dashboard/DashboardInputs/DDateInput";

//redux toolkit
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
//charts and metics
import Reportcard from "../../../components/Dashboard/ReportCards/ReportCard";
import DonutChart from "../../../components/Dashboard/Charts/DonutChart/DonutChart";
import LineChart from "../../../components/Dashboard/Charts/LineChart/LineChart";
import BarChart from "../../../components/Dashboard/Charts/BarChart/BarChart";
import { API_ENDPOINT } from "../../../services/api/resources";
import axios from "axios";
import FullDonutChart from "../../../components/Dashboard/Charts/FullDonutChart/FullDonutChart";
import { emptyCheck } from "../../../utils/validations";
import { weeklyReportObjFormate } from "../../../utils/chartKeyFormat";

const VendorDashboardTab = () => {
  //dispatcher
  const dispatch = useDispatch();

  //get data from use selector
  const data = useSelector((state) => state.loginData.value);

  //credentials
  const [auth_token, setAuthToken] = useState(data?.auth_token);
  const [vendor_token, setVendorToken] = useState(data?.user?.token);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    getAllItems();
    getAllBuyersData();
    getAnalyticsValues();
  }, []);

  const [buyerList, setBuyersList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });

  const [customerList, setCustomerList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  })
  const [itemList, setItemList] = useState({
    values: {
      tokens: [],
      visibleOption: [],
    },
  });

  const [buyerDetails, setBuyerDetails] = useState({
    buyerName: "",
    buyerID: "",
  });

  const [customerDetails, setCustomerDetails] = useState({
    buyerName: "",
    buyerID: "",
  });
  const [itemCode, setItemCode] = useState({
    item_code: "",
    item_token: "",
  });
  const [duration, setDuration] = useState();
  const [fromDate, setFromDate] = useState({
    value: "",
    isValid: true,
  });
  const [toDate, setToDate] = useState({
    value: "",
    isValid: true,
  });

  const [analyticsData, setAnalyticsData] = useState({
    totalPrice: "",
    totalRFQCount: "",
    totalBidsCount: "",
    confirmedOrders: "",
    pendingVsCloseRFQ: [],
    submittedVsSelectedRFQ: [],
    totalRFQCreated: [],
    totalSalesOrderValueMonthWise: [],
    totalSalesOrderLabelMonthWise: [],
    totalSalesOrdersValueWeekWise: [],
    totalSalesOrdersLabelWeekWise: []
  });

  const [salesOrderOption, setSalesOrderOption] = useState('monthly')

  const validateFilters = () => {
    const isfromDate = emptyCheck(fromDate?.value);

    const isToDate = emptyCheck(toDate?.value);

    setFromDate((prev) => ({ ...prev, isValid: isfromDate }));
    setToDate((prev) => ({ ...prev, isValid: isToDate }));

    return isfromDate && isToDate;
  };

  const handleFilterSearch = () => {
    let validateForm = !duration ? validateFilters() : true;
    if (validateForm) {
      getAnalyticsValues();
    } else {
      setFromDate((prev) => ({ ...prev, isValid: prev.isValid }));
      setToDate((prev) => ({ ...prev, isValid: prev.isValid }));
    }
  };

  const getAnalyticsValues = async () => {
    const formData = new FormData();
    if (customerDetails.buyerID && customerDetails.buyerID.length !== 0) {
      formData.append("buyer_token", customerDetails.buyerID);
    } else if(buyerDetails.buyerID && buyerDetails.buyerID.length !== 0) {
      formData.append("buyer_company_token", buyerDetails.buyerID);
    }
    formData.append("vendor_token", vendor_token);
    itemCode.item_token &&
      itemCode.item_token !== "" &&
      itemCode.item_token !== null &&
      itemCode.item_token.length !== 0 &&
      formData.append("item_token", itemCode.item_token);
    duration && formData.append("month_period", duration);
    fromDate.value && !duration && formData.append("from_date", fromDate.value);
    toDate.value && !duration && formData.append("to_date", toDate.value);

    setLoadingState(true);
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.new_vendor_dashbord}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        setLoadingState(false);
        let responseData = res?.data;
        setAnalyticsData({
          totalPrice: responseData && responseData["total price"],
          totalRFQCount: responseData && responseData["total rfq count"],
          totalBidsCount: responseData && responseData["total bids count"],
          confirmedOrders: responseData && responseData["Confirmed Orders"],
          pendingVsCloseRFQ:
            responseData &&
            Object.values(responseData["pending and closed rfq"]),
          submittedVsSelectedRFQ:
            responseData &&
            Object.values(responseData["submitted vs selected bid"]),
          totalRFQCreatedValueMonthWise:
            responseData && Object.values(responseData["rfq monthly count"]).reverse(),
          totalRFQCreatedLabelMonthWise:
            responseData && Object.keys(responseData["rfq monthly count"]),
          totalSalesOrderValueMonthWise:
            responseData && Object.values(responseData["total_sales_order"]).reverse(),
          totalSalesOrderLabelMonthWise:
            responseData && Object.keys(responseData["total_sales_order"]),
          totalSalesOrdersValueWeekWise:
            responseData && Object.values(weeklyReportObjFormate(responseData["total sale weekly"])),
          totalSalesOrdersLabelWeekWise:
            responseData && Object.keys(weeklyReportObjFormate(responseData["total sale weekly"])),
        });
      })
      .catch((err) => {
        setLoadingState(false);
        console.log(err);
      });
  };

  //getAll vendor names
  const getAllBuyersData = async () => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.getBuyerList}`,
      data: {
        vendor_token: vendor_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        let uniqueDatas;
        if (Array.isArray(res?.data?.response)) {
          const uniqueValue = Array.from(new Set(res?.data?.response.map(item => item.company_name))).map(
            companyName => res?.data?.response.find(item => item.company_name === companyName)
          );
          uniqueDatas = uniqueValue;
        } else {
          console.error("The 'bidsData' is not an array or is undefined.");
          uniqueDatas = []; // Set a default value or handle it accordingly based on your use case
        }

        let vendor_list_shallow = new Set(
          res?.data?.response?.map((e) => {
            return e?.company_name + "";
          })
        );
        
        setBuyersList({
          values: {
            tokens: uniqueDatas.map((e) => {
              return e?.token;
            }),
            visibleOption: [...vendor_list_shallow],
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setBuyersList({
          values: {
            tokens: [null],
            visibleOption: ["No options"],
          },
        });
      });
  };

  const getAllItems = async () => {
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.getItemCodes}`,
      data: {
        vendor_token: vendor_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
      .then((res) => {
        setItemList({
          values: {
            tokens: res?.data?.response?.map((e) => {
              return e?.token;
            }),
            visibleOption: res?.data?.response?.map((e) => {
              return e?.item_code;
            }),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setItemList({
          values: {
            tokens: [null],
            visibleOption: ["No options"],
          },
        });
      });
  };
  const resetSearch = ()=>{ 
    // reset customer data
    setCustomerDetails({
      buyerName: "",
      buyerID: "",
    })
    setCustomerList({
      values: {
        tokens: [],
        visibleOption: [],
      },
    });
    setTimeout(() => {
      document.getElementById('customerName').value = ''
    }, 200);
  }
  const getCustomerName = async (companyToken)=> {
    resetSearch()
    if(!companyToken || companyToken.length === 0) return
    await axios({
      method: "POST",
      url: `${API_ENDPOINT.vendor_customer_list}`,
      data: {
        buyer_token: companyToken,
        vendor_token: vendor_token,
      },
      headers: {
        Authorization: `Bearer ${auth_token} `,
      },
    })
    .then((response) => {
      resetSearch()
      let customerList = response.data.response;
      let uniqueDatas;
      if (Array.isArray(customerList)) {
        const uniqueValue = Array.from(new Set(customerList.map(item => item.full_name))).map(
          companyName => customerList.find(item => item.full_name === companyName)
        );
        uniqueDatas = uniqueValue;
      } else {
        console.error("The 'bidsData' is not an array or is undefined.");
        uniqueDatas = []; // Set a default value or handle it accordingly based on your use case
      }

      let customer_list_shallow = new Set(
        customerList?.map((e) => {
          return e?.full_name + "";
        })
      );
      setCustomerList({
        values: {
          tokens: uniqueDatas.map((e) => {
            return e?.token;
          }),
          visibleOption: [...customer_list_shallow],
        },
      })
    })
    .catch((error)=> {
      console.log(error)
      setCustomerList({
        values: {
          tokens: [null],
          visibleOption: ["No options"],
        },
      });
    })
  }

  const totalSalesOrderDataValues = salesOrderOption === "monthly" ? analyticsData.totalSalesOrderValueMonthWise : analyticsData.totalSalesOrdersValueWeekWise;
  const totalSalesOrderDataLabel = salesOrderOption === "monthly" ? analyticsData.totalSalesOrderLabelMonthWise : analyticsData.totalSalesOrdersLabelWeekWise;

  const totalSalesOrderDataSets = {
    cardHeading: "Total Sales Order",
    chartLabels: ["Sales Order"],
    chartIndicators: [
      {
        value: "Sales Order",
        color: "#42BFAA",
      },
    ],
    barColor: ["#42BFAA"],
  };

  const submittedAndSelectedBidDataValue = analyticsData.submittedVsSelectedRFQ;
  const submittedAndSelectedBidDataSets = {
    cardHeading: "Submitted Bid,Selected Bid and Unsubmitted RFQ",
    chartText: (
      <span
        style={{
          fontSize: "100%",
          fontFamily: "gilroyBold",
        }}
      >
        {submittedAndSelectedBidDataValue[0]} /{" "}
        {submittedAndSelectedBidDataValue[1]}/{" "}
        {submittedAndSelectedBidDataValue[2]}{" "}
      </span>
    ),
    chartLabels: ["Submitted", "Selected", "Un Submitted"],
    barColors: ["#75B8D9", "#2E6194", "#4E61A4"],
    chartIndicators: [
      {
        value: "Submitted Bid",
        color: "#75B8D9",
      },
      {
        value: "Selected Bid",
        color: "#2E6194",
      },
      {
        value: "Unsubmitted RFQ",
        color: "#4E61A4",
      },
    ],
    progressLines: [
      {
        label: `Submitted Bid - ${submittedAndSelectedBidDataValue[0]}`,
        color: "#75B8D9",
        value: submittedAndSelectedBidDataValue[0] || 0,
      },
      {
        label: `Selected Bid - ${submittedAndSelectedBidDataValue[1]}`,
        color: "#2E6194",
        value: submittedAndSelectedBidDataValue[1] || 0,
      },
      {
        label: `Un Submitted RFQ - ${submittedAndSelectedBidDataValue[2]}`,
        color: "#4E61A4",
        value: submittedAndSelectedBidDataValue[2] || 0,
      },
    ],
  };

  const totalRFQCreatedDataValueMonthWise = analyticsData.totalRFQCreatedValueMonthWise;
  const totalRFQCreatedDataLabelMonthWise = analyticsData.totalRFQCreatedLabelMonthWise;

  const totalRFQCreatedDataSets = {
    cardHeading: "Total Bids Submitted",
    barColors: ["#42BFAA"],
    chartIndicators: [
      {
        value: "Total count",
        color: "#42BFAA",
      },
    ],
  };

  const pendingAndClosedRFQDataValues = analyticsData.pendingVsCloseRFQ;

  // const totalSum = pendingAndClosedRFQDataValues.reduce((a, b) => a + b, 0);
  // const largerValue = Math.max(...pendingAndClosedRFQDataValues);
  // const percentage = Math.round((largerValue / totalSum) * 100);

  //  cardHeading, chartIndicators, progressLines, barColors, chartLabels, chartText
  const pendingAndClosedRFQDataSets = {
    cardHeading: "Pending and Closed RFQ",
    // chartText: <span>{percentage}%</span>,
    chartText: (
      <span>
        Total RFQ <br />{" "}
        {pendingAndClosedRFQDataValues[0] + pendingAndClosedRFQDataValues[1]}
        {/* {analyticsData?.totalRFQCreated?.length > 1
          ? analyticsData?.totalRFQCreated?.reduce((a, b) => a + b)
          : analyticsData?.totalRFQCreated[
              analyticsData?.totalRFQCreated?.length - 1
            ]} */}
      </span>
    ),
    chartLabels: ["Pending RFQ", "Closed RFQ"],
    barColors: ["#749F94", "#D2E0E1"],
    chartIndicators: [
      {
        value: "Pending",
        color: "#749F94",
      },
      {
        value: "Closed",
        color: "#D2E0E1",
      },
    ],
    progressLines: [
      {
        label: `Pending RFQ - ${pendingAndClosedRFQDataValues[0]}`,
        color: "#749F94",
        value: pendingAndClosedRFQDataValues[0],
      },
      {
        label: `Closed RFQ - ${pendingAndClosedRFQDataValues[1]}`,
        color: "#D2E0E1",
        value: pendingAndClosedRFQDataValues[1],
      },
    ],
  };

  // //report card data
  // const reportCardData = [
  //     {
  //         cardName: 'Total Sales Order',
  //         cardImg: totalPOIMg,
  //         cardValue: 'Rs.1,60,56,40'
  //     },
  //     {
  //         cardName: 'Total RFQs ',
  //         cardImg: totalPOIMg2,
  //         cardValue: '20'
  //     },
  //     {
  //         cardName: 'Total Bids',
  //         cardImg: totalPOIMg3,
  //         cardValue: '13'
  //     },
  //     {
  //         cardName: 'Confirmed Orders',
  //         cardImg: totalPOIMg4,
  //         cardValue: '12'
  //     },

  // ]

  //report card data
  const reportCardData = [
    {
      cardName: "Total Sales Order",
      cardImg: totalPOIMg,
      cardValue: `Rs.${analyticsData?.totalPrice}`,
    },
    {
      cardName: "Total RFQ",
      cardImg: totalPOIMg2,
      cardValue: `${analyticsData?.totalRFQCount}`,
    },
    {
      cardName: "Total Bids",
      cardImg: totalPOIMg3,
      cardValue: `${analyticsData?.totalBidsCount}`,
    },
    {
      cardName: "Confirm Orders",
      cardImg: totalPOIMg4,
      cardValue: `${analyticsData?.confirmedOrders}`,
    },
  ];

  const durationList = ["last month", "last 3 months", "last 6 months"];
  const handleChooseSODuration = (option)=> {
    setSalesOrderOption(option)
  }
  return (
    <div className={`${classes.dashboard_tab_wrapper}`}>
      <div className={`${classes.topHeader}`}>
        <HeaderTitle
          titleText={`${data?.access_type === "vendor admin"
            ? "Vendor Admin Dashboard"
            : "Vendor Dashboard"
            }`}
        />

        <div className={`${classes.rhs} ${classes.flex}`}>
          <DateBar />
          <NotificationBell />
          <ProfileCard
            imageSrc={profileImg}
            vendorBtn={true}
            vendorBtnOutlined={true}
            profilePath={"/Vendor_dashboard/My_profile"}
          />
        </div>
      </div>

      <div className={`${classes.heading_inputs}`}>
        <div className={`${classes.filters} ${classes.flex}`}>
          <DSelectInput
            options={buyerList.values}
            getIDValue={true}
            customClass={classes.filterFields}
            label={"Customer Company Name"}
            setIDValue={(e) => {
              setBuyerDetails((prev) => ({
                ...prev,
                buyerID: e,
              }));
              getCustomerName(e) // get customer details
            }}
            onChange={(e) => {
              setBuyerDetails((prev) => ({
                ...prev,
                buyerName: e,
              }));
            }}
          />

          <DSelectInput
            id="customerName"
            options={customerList.values}
            getIDValue={true}
            customClass={classes.filterFields}
            label={"Customer Name"}
            disabled={!buyerDetails?.buyerID || buyerDetails?.buyerID?.length === 0}
            setIDValue={(e) => {
              setCustomerDetails((prev) => ({
                ...prev,
                buyerID: e,
              }));
            }}
            onChange={(e) => {
              setCustomerDetails((prev) => ({
                ...prev,
                buyerName: e,
              }));
            }}
          />

          <DSelectInput
            options={itemList.values}
            customClass={classes.filterFields}
            getIDValue={true}
            label={"Item Code"}
            setIDValue={(e) => {
              setItemCode((prev) => ({
                ...prev,
                item_token: e,
              }));
            }}
            onChange={(e) => {
              setItemCode((prev) => ({
                ...prev,
                item_code: e,
              }));
            }}
          />

          <DSelectInput
            options={durationList}
            customClass={classes.filterFields}
            label={"Duration"}
            onChange={(e) => {
              setDuration(e);
              setFromDate({
                value: "",
                isValid: true,
              });
              setToDate({
                value: "",
                isValid: true,
              });
            }}
          />

          <DDateInput
            customClass={classes.filterFields}
            type={"date"}
            label={"From Date"}
            disableFuture={true}
            onChange={(e) => {
              setFromDate({
                isValid: emptyCheck(e),
                value: e,
              });
            }}
            disabledInput={duration}
            value={fromDate}
            error={!fromDate?.isValid}
            errorMsg={"From date is required"}
          />

          <div className={`${classes.flex} ${classes.filterSearch}`}>
            <DDateInput
              customClass={classes.filterFields}
              type={"date"}
              label={"To Date"}
              disableFuture={true}
              onChange={(e) => {
                setToDate({
                  isValid: emptyCheck(e),
                  value: e,
                });
              }}
              disabledInput={duration}
              value={toDate}
              error={!toDate?.isValid}
              errorMsg={"To date is required"}
            />

            <DButton
              vendorBtn={true}
              labelImg={btnImg}
              customWrapperClass={classes.filterBtn}
              customClass={classes.button}
              iconWidth={"2rem"}
              iconHeight={"2rem"}
              onClick={handleFilterSearch}
            />
          </div>
        </div>
      </div>

      <div className={classes.mainContent}>
        <div className={classes.reports}>
          {reportCardData?.map((e, i) => (
            <Reportcard
              title={e.cardName}
              value={e.cardValue}
              reportImg={e.cardImg}
              customClass={classes.reportCard}
              loadingState={loadingState}
            />
          ))}
        </div>

        <div className={classes.metrics}>
          <div className={classes.topFour}>
            <DonutChart
              chartValues={submittedAndSelectedBidDataValue}
              customCardClass={classes.chartfirstRow}
              chartDataSet={submittedAndSelectedBidDataSets}
              loadingState={loadingState}
            />

            <FullDonutChart
              chartValues={pendingAndClosedRFQDataValues}
              customCardClass={classes.chartfirstRow}
              chartDataSet={pendingAndClosedRFQDataSets}
              loadingState={loadingState}
              textSm={true}
            />

            <BarChart
              labels={totalRFQCreatedDataLabelMonthWise} //data label
              mainData={totalRFQCreatedDataValueMonthWise} //data values
              vendorDashboard={true}
              customCardClass={classes.ChartSecondRow}
              chartDataSet={totalRFQCreatedDataSets}
              loadingState={loadingState}
              durationValue="monthly"
            />

            <LineChart
              dataLabel={totalSalesOrderDataLabel}
              dataValues={totalSalesOrderDataValues}
              chartDataSet={totalSalesOrderDataSets}
              vendorChart={true}
              customCardClass={classes.ChartSecondRow}
              loadingState={loadingState}
              durationValue={salesOrderOption}
              chooseChartDuration={handleChooseSODuration}
              disableDuration={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDashboardTab;

import React from "react";
import styles from "./HomeFooter.module.css";
import heroImg from "../../../assets/images/svg/Footer logo.svg";
import BrandLogo from "../../../components/common/BrandLogo/BrandLogo";
import { Link } from "react-scroll";
//img
import linkedinIcon from "../../../assets/images/svg/LinkedIn.svg";
import twitterIcon from "../../../assets/images/svg/twitter212.svg";
import ytIcon from "../../../assets/images/svg/Youtube.svg";
import ImgFrame from "../../../components/common/ImageFrame/ImgFrame";
import { useNavigate } from "react-router-dom";

const HomeFooter = () => {

  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className={`${styles.container}`}>
        <div className={styles.topSection}>
          <div className={styles.footerLogo}>
            <BrandLogo imgSrc={heroImg} />
          </div>
          <div className={styles.copyright}>
            Copyright ©2023 bidz.ai. All Rights Reserved.
          </div>
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.linkSection}>
            <div className={styles.legalHeading}>Legal</div>
            <button className={styles.link}>Terms and Conditions</button>
            <Link
              to="prp_content"
              offset={-120}
              duration={500}
              onClick={() => {
                navigate('/privacy')
              }}
              className={styles.link}
            >
              Privacy Policy
            </Link>
            <button className={styles.link}>Legal Disclaimer</button>
            <Link className={styles.link}
              to="cookie_content"
              offset={-120}
              duration={500}
              onClick={() => {
                navigate('/cookie')
              }}
            >Cookie Policy</Link>
          </div>
          <div className={styles.socialMedia}>
            <a
              href="https://www.linkedin.com/company/bidtoaster/"
              target="_blank"
              className={styles.icon}
            >
              <ImgFrame imgSrc={linkedinIcon} customClass={`${styles.smI}`} />{" "}
              LinkedIn
            </a>
            <a
              href="https://twitter.com/bidz_ai"
              target="_blank"
              className={styles.icon}
            >
              <ImgFrame
                imgSrc={twitterIcon}
                customClass={`${styles.smI} ${styles.twit}`}
              />{" "}
              Twitter
            </a>
            <a
              href="https://youtube.com/watch?v=gWqwdihEBoA&feature=share8"
              target="_blank"
              className={styles.icon}
            >
              <ImgFrame imgSrc={ytIcon} customClass={`${styles.smI}`} /> Youtube
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;

import React, { memo } from "react";
//img
import calendarImg from "../../../assets/images/svg/Calender.svg";
//css
import "./DateBar.css";
const DateBar = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const shortMonth = month?.slice(0, 3);
  const formattedDate = `${day} ${shortMonth}`;

  return (
    <div className="datebar_wrapper">
      <img src={calendarImg} />
      <p>{formattedDate}</p>
    </div>
  );
};

export default memo(DateBar);

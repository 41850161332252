import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    NotifyLogout: false,
    sessionExpired: false
}
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logIn: (state, action) => {
            state.isLoggedIn = true
        },
        logOut: (state, action) => {
            state.isLoggedIn = false
            state.NotifyLogout = false
            state.sessionExpired = false
        },
        setNotifyLogout: (state, action) => {
            state.NotifyLogout = true
        },
        clearLogout: (state, action) => {
            state.NotifyLogout = false
        },
        sessionTimeout: (state, action) => {
            state.sessionExpired = true
        },
        clearSessionTimeout: (state, action) => {
            state.sessionExpired = false
        }
    }
})

export const { logIn, logOut, setNotifyLogout, clearLogout, sessionTimeout, clearSessionTimeout } = authSlice.actions;
export default authSlice.reducer;
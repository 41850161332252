import React, { memo, useEffect } from 'react'
import classes from './Sidebar.module.css'
import { Link } from 'react-router-dom'

const SideBarTab = ({ roles, tabImg, tabName, active, activeTabImg, clickFunc, navigatePath }) => {
    return (
        <Link to={navigatePath} className={`${classes.sidebar_tab} ${(roles.Buyer || roles.User) && classes.buyer_sideBar} ${roles.SuperAdmin && active && classes.sidebar_tab_active} ${roles.Admin && active && classes.sidebar_tab_active} ${(roles.Buyer || roles.User) && active && classes.buyer_sidebar_tab_active}  ${roles.VendorAdmin && active && classes.Vendor_sidebar_tab_active} ${roles.VendorContact && active && classes.Vendor_sidebar_tab_active}`} onClick={clickFunc}>
            <img src={active ? activeTabImg : tabImg} alt="tab img" />
            <p>{tabName}</p>
        </Link>
    )
}

export default memo(SideBarTab);
import React, { memo, useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Fade,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

//img
import viewIcon from "../../../assets/images/svg/viewtech.svg";
import fileIcon from "../../../assets/images/svg/file@.svg";
import editIcon from "../../../assets/images/svg/File Edit.svg";
import dltIcon from "../../../assets/images/svg/Delete.svg";
import cnclIcon from "../../../assets/images/svg/cancel.svg"
import sortIcon from "../../../assets/images/svg/Sort.svg";
import profileImg from "../../../assets/images/png/profile-pic.jpg";
import ownBidIcon from "../../../assets/images/svg/vendorProfile/satuatoryblack.svg";
import bidSuccessImg from "../../../assets/images/svg/bidSuccessCheck.svg";
import timelineIcon from "../../../assets/images/svg/timeline.svg";
import downloadIcon from "../../../assets/images/svg/download-icon-black.svg";
//css
import styles from "./DataTable.module.css";
//components
import { saveAs } from "file-saver";
import ImgFrame from "../ImageFrame/ImgFrame";
import DButton from "../../Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../Dashboard/DashboardButtons/dButtonOutlined";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PopupFrame from "../../Popups/PopupFrame/PopupFrame";
import InfoLabel from "../InfoLabel/InfoLabel";
import SwitchButton from "../../Dashboard/SwitchButton/SwitchButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";
import StatusMessage from "../StatusMessage/StatusMessage";
import ApproverTooltip from "../Tooltip/ApprovalTooltip";
import CommentsTooltip from "../Tooltip/CommentsTooltip";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
// import

const DataTable = ({
  headings,
  tableData,
  actions,
  selection,
  viewBtn,
  editBtn,
  deleteBtn,
  viewClick,
  editClick,
  deleteClick,
  downloadClick,
  handleClickSendPoEmail,
  customClass,
  sorting,
  file,
  selectBidBtn,
  onSelectBid,
  onUnSelectBid,
  ownBid,
  vendorConfidential,
  vendorNameConfidential,
  ownBidHeading,
  tableSize,
  org,
  buyer,
  bulkData,
  isLoading,
  errorMessage,
  itemsTable,
  selectedRowsList,
  clearAllSelectedRows,
  hasBidIteration,
  maxBidIterationCount,
  disableSelectAll,
  stickyHead,
  tableFixedHeight,
  toggleOnChange,
  setSortingColumnName,
  sortingColumnName,
  setSortingOrder,
  sortingOrder,
  timeline,
  viewTimeLineClick,
  downloadSendmailForPo = false,
  sendMail = false,
  Download = false,
  onCheckboxChange,
  checkboxSelection,
  selectedvendorbids,
  openvendor,
  setOpenvendor,
  isempty,
  setisempty,
  RFQitemsListMapped,
  sendMessage,
  requestRequote,
  viewQuotes,
  selectVendors
}) => {
  const [data, setData] = useState(tableData);
  let checkIsBidSelected = bulkData?.map((e) => e.status);
  let quoteOptions = headings.filter((e) => e.label === "Quote Iteration")[0]
    ?.DropdownOptions;
  
  useEffect(() => {
    tableData && setData(tableData);
  }, [tableData,selectVendors,requestRequote,viewQuotes]);
  
  // console.log('tabledata', tableData)
  const [poPricesMap, setPoPricesMap] = useState({});


  useEffect(() => {
    if (Array.isArray(data)) {
      let partNumbers = data.map(item => item);
      let poPrices = {};
  
      if (Array.isArray(partNumbers) && partNumbers.length > 0) {
        partNumbers.forEach(part => {
          const poNumber = part.PO_number; 
          if (Array.isArray(part.item_list)) {
            const itemPrices = part.item_list.map(item => item.item_rfqtotal_prices); 
            if (!poPrices[poNumber]) {
              poPrices[poNumber] = []; 
            }
            poPrices[poNumber] = poPrices[poNumber].concat(itemPrices);
          } else {
            console.warn(`item_list is undefined or not an array for PO_number: ${poNumber}`);
          }
        });
      }
      setPoPricesMap(poPrices);  // Set the poPricesMap state
    } else {
      console.error("data is either undefined or not an array");
    }
  }, [data]);  // Re-run effect when `data` changes
  


  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  
  // Added starts ?

  const selectedVendors = Array.isArray(data) 
      ? data.filter(item => item.status === "selected")
      : [];
  const RFQ_quan = Array.isArray(RFQitemsListMapped)? RFQitemsListMapped.map(item => Number(item.quantity)):[]
  let RFQ_quantity = 0;

  for (let i = 0; i < RFQ_quan.length; i++) {
    RFQ_quantity += RFQ_quan[i];
  }
  const vendor_data =data
  const hasSelectedStatus = selectedVendors.length > 0;

  const location = useLocation();

  const [bidSelected, setBidSelected] = useState(false);
  const [invisible, setInvisible] = useState(true);

  const [selectcbox,setselectcbox] =useState(false); // added ?

  //roles
  const loginData = useSelector((state) => state.loginData.value);
  
  const po_header = headings && 
  headings.some(heading => heading.label === "PO Number" && heading.field === "PO_number") && 
  loginData.access_type === "User";
  const po_buyer = headings && 
  headings.some(heading => heading.label === "PO Number" && heading.field === "PO_number") && 
  loginData.access_type === "Buyer";
  const buyer_rfq = headings && 
  headings.some(heading => heading.label === "Quantity") && 
  loginData.access_type === "Buyer";
  const costcenter_codes = headings.some(heading => heading.label === "Cost Center Code" && heading.field === "costcenter_code") && loginData.access_type === "Admin";

  const gl_codes = headings.some(heading => heading.label === "Gl code" && heading.field === "gl_code") && loginData.access_type === "Admin";

  const projects_codes = headings.some(heading => heading.label === "Project Code" && heading.field === "project_code") && loginData.access_type === "Admin";

  const Tax_gst = headings.some(heading => heading.label === "Tax" && heading.field === "gst") && loginData.access_type === "Admin";
  
  const [totaldata,settotaldata] = useState([])
  
  const [roles, setRoles] = useState({
    Admin: "",
    Buyer: "",
    Vendor: "",
  });
  useEffect(() => {
    if (clearAllSelectedRows) {
      setSelectedRows([]);
      setSelectedRowObj([]);
    }
  }, [clearAllSelectedRows]);

  useEffect(() => {
    setRoles({
      Admin:
        loginData?.access_type === "admin" ||
        loginData?.access_type === "Admin",
      Buyer:
        loginData?.access_type === "buyer" ||
        loginData?.access_type === "Buyer",
      Vendor:
        loginData?.access_type === "vendor admin" ||
        data?.access_type === "Vendor admin" ||
        loginData?.access_type === "vendor contact" ||
        data?.access_type === "Vendor contact",
    });
  }, [location.pathname, data, bidSelected, totaldata]);

  const [selectedBidRows, setSelectedBidRows] = useState([]);

  const [sortedField, setSortedField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowObj, setSelectedRowObj] = useState([]);

  const [open, setOpen] = useState(false); 
  const [popupMessage, setPopupMessage] = useState("");
  const [popupHeroText, setPopupHeroText] = useState("");
  
  // const handleRowSelect = (rowId, row) => {
  //   const selectedIndex = selectedRows?.indexOf(rowId);
  //   let newSelectedRows = [];
  //   let newSelectedRowObj = [];

  //   if (selectedIndex === -1) {
  //     newSelectedRows = [...selectedRows, rowId];
  //     newSelectedRowObj.push(row);
  //   } else {
  //     newSelectedRows = selectedRows?.filter((id) => id !== rowId);
  //     newSelectedRowObj = newSelectedRowObj?.filter((e) => e.token !== row.token);
  //   }

  //   // in bid iteration selection have to select the each row using its token but have to send the vendor_id
  //   // of the each row in selection in specifically --> RFQ -> request resubmit quote

  //   // let filteredMain = newSelectedRows.flatMap((el) => (
  //   //   data?.filter((el2) => (el === el2?.token))
  //   // ))

  //   // let filteredVendorId = filteredMain.map((el) => el?.vendor_id);

  //   // selectedRowsList(filteredVendorId);
  //   setSelectedRows(newSelectedRows, newSelectedRowObj);
  //   selectedRowsList(newSelectedRows, newSelectedRowObj);

  //   // settotaldata(prevTotaldata => [
  //   //   ...prevTotaldata,
  //   //   ...(Array.isArray(newSelectedRowObj) ? newSelectedRowObj : [newSelectedRowObj])
  //   // ]);
    
  //   settotaldata(prevTotalData => [
  //     ...prevTotalData,
  //     ...(Array.isArray(newSelectedRowObj) ? newSelectedRowObj : [newSelectedRowObj])
  //   ]);

  // };

  const openRFQMainCancel = () => {
    setOpen(false);
  };
  

const handleRowSelect = (rowId, row) => {
  const selectedIndex = selectedRows.indexOf(rowId);
  let newSelectedRows;
  let newSelectedRowObj;
  
  if (selectedIndex === -1) {
      const isDuplicateVendor = selectedRowObj.find((obj) => obj.vendor_token === row.vendor_token);
      if(sendMessage == true){
        newSelectedRows = [...selectedRows, rowId];
        newSelectedRowObj = [...selectedRowObj, row];
      }else{
        if (!isDuplicateVendor) {
          newSelectedRows = [...selectedRows, rowId];
          newSelectedRowObj = [...selectedRowObj, row];
        } else {
          setOpen(true);
          setPopupMessage(`Already selected this vendor with: <strong>${isDuplicateVendor.bid_version}</strong>`);
          setPopupHeroText("Duplicate Record!");
          newSelectedRows = [...selectedRows];
          newSelectedRowObj = [...selectedRowObj];
        }
      }
  } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
      newSelectedRowObj = selectedRowObj.filter((obj) => obj.token !== rowId);
  }

  setSelectedRows(newSelectedRows);
  settotaldata(newSelectedRowObj);
  setSelectedRowObj(newSelectedRowObj);
  
  if (selectedvendorbids === 'true'){
    if (newSelectedRows.length === 0) {
      setisempty(true);
    } else {
      setisempty(false);
    }
  }    
  selectedRowsList(newSelectedRows, newSelectedRowObj);
};


const handleSelectAllRows = (event) => {
  let newSelectedRowTokens, newSelectedRows;
  if (event.target.checked) {
    // console.log('selectVendors',selectVendors1);
    // console.log('requestRequote',requestRequote1);
    // console.log('viewQuotes',viewQuotes1);
    if(sendMessage == true){
      newSelectedRowTokens = data && data?.map((row, i) => row.token);
      newSelectedRows = data && data?.map((row, i) => row);
    }else{
      const uniqueRows = data ? data.filter((row, index, self) => self.findIndex(r => r.vendor_token === row.vendor_token) === index) : [];    
      const newRowsAdd = uniqueRows ? uniqueRows.filter(uniqueRow => !selectedRowObj.some(selectedRow => selectedRow.vendor_token === uniqueRow.vendor_token)): [];
      const newTokensToAdd = newRowsAdd.map(row => row.token);
      newSelectedRowTokens = [...new Set([...selectedRows, ...newTokensToAdd])];
      newSelectedRows = [...new Set([...selectedRowObj, ...newRowsAdd])];
    }
  } else {
    if(sendMessage == true){
      newSelectedRowTokens = [];
      newSelectedRows = [];
    }else{
      const currentPageRowTokens = data ? data.map((row) => row.token) : [];
      newSelectedRowTokens = selectedRows.filter((token) => !currentPageRowTokens.includes(token));
      newSelectedRows = selectedRowObj.filter((row) => !currentPageRowTokens.includes(row.token));
    }
  }
  setSelectedRows(newSelectedRowTokens);
  setSelectedRowObj(newSelectedRows);
  settotaldata(newSelectedRows);
  selectedRowsList(newSelectedRowTokens, newSelectedRows);
    
};

const uniquePageTokens = data? data.filter((row, index, self) => self.findIndex(r => r.vendor_token === row.vendor_token) === index).map(row => row.token): [];

const isSelectAllChecked = uniquePageTokens.length > 0 && uniquePageTokens.some(token => selectedRows.includes(token));
const isSelectAllIndeterminate = !isSelectAllChecked && uniquePageTokens.some(token => selectedRows.includes(token));



useEffect(() => {
  
}, [selectedRowObj]);

  

  // added ?
  const [vendorname, setVendorname] = useState([]);
  const [quantityval,setquantityval] = useState('');
  const [vendor_slno,setVendor_slno] = useState([]);
  const [ventoken,setVentoken] = useState([]);
  const [select_bid_token,setselect_bid_token] = useState([]);
  const [row_status,setrow_status] = useState([]);



  // const handleSelectAllRows = (event) => {
  //   if (event.target.checked) {
  //     const newSelectedRowTokens = data && data?.map((row, i) => row.token);
  //     const newSelectedRows = data && data?.map((row, i) => row);
  //     setSelectedRows(newSelectedRowTokens, newSelectedRows);
  //     selectedRowsList(newSelectedRowTokens, newSelectedRows);

  //     // in bid iteration selection have to select the each row using its token but have to send the vendor_id
  //     // of the each row in selection in specifically --> RFQ -> request resubmit quote

  //     // let filteredMain = newSelectedRows.flatMap((el) => (
  //     //   data?.filter((el2) => (el === el2?.token))
  //     // ))

  //     // let filteredVendorId = filteredMain.map((el) => el?.vendor_id);

  //     // selectedRowsList(filteredVendorId);
  //   } else {
  //     setSelectedRows([]);
  //     selectedRowsList([]);
  //   }
  // };

  const handleSort = (field) => {
    let newSortOrder = "asc";
    if (sortedField === field && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortedField(field);
    setSortOrder(newSortOrder);

    // const sortedData = [...data];

    // sortedData.sort((a, b) => {
    //     const valueA = a[field];
    //     const valueB = b[field];

    //     if (typeof valueA === "number" && typeof valueB === "number") {
    //         return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    //     } else {
    //         console.log(valueA);
    //         const compareResult = valueA?.localeCompare(valueB);
    //         return sortOrder === "asc" ? compareResult : -compareResult;
    //     }
    // });

    // setData(sortedData);
  };

  const isSelected = (rowId) => selectedRows.indexOf(rowId) !== -1;
  const isSelectedBid = (rowId) => selectedBidRows.indexOf(rowId) !== -1;

  const handleSelectBid = (rowId, row) => { // added ?
    //onSelectBid && onSelectBid(rowId,quantityList);
    onSelectBid && onSelectBid(rowId,row);
    setSelectedBidRows([rowId]);
  };
  const handleUnselectBid = (rowId, row) => {
    onUnSelectBid && onUnSelectBid(row.token);
    setSelectedBidRows([]);
  };

  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_URL;

  // view description
  const [bidDescription, setBidDescription] = useState();
  const [descriptionPopup, setDescriptionPopup] = useState({
    open: false,
    close: false,
  });

  const handleOpenViewDescription = (description) => {
    setDescriptionPopup({
      open: true,
      close: false,
    });
    setBidDescription();
    setBidDescription(description);
  };

  const handleCloseViewDescription = () => {
    setDescriptionPopup({
      open: false,
      close: true,
    });
  };

  const viewDescriptionHeading = (
    <HeaderTitle titleText={"Description"} customclass={styles.popupHeading} />
  );

  const viewDescriptionContent = [
    // <p className={styles.bidDescription}>{bidDescription}</p>
    <InfoLabel
      customClass={styles.bidDescription}
      value={bidDescription}
      size={"100%"}
      maxLength={"350"}
    />,
  ];

  const viewDescriptionAction = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseViewDescription}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
  ];

  //open dropdown states and handlers
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDropdownHeading = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdownHeading = () => {
    setAnchorEl(null);
  };

  const sortfunction = () => {
    setSortingColumnName(sortedField);
    setSortingOrder(sortOrder);
  };
  const [IsPopupOpen,setIsPopupOpen] = useState();
  const [selectedItemList, setSelectedItemList] = useState([]);

  // Popup
  const handleNameClick = (itemlist) => {
    setIsPopupOpen(true);
    setSelectedItemList(itemlist)
  };
  // const closePopup = () => {
  //   setIsPopupOpen(false);
  // };
  // added start ?

  const [openModalsubmit, setOpenModalsubmit] = useState(false);
  const [message, setMessage] = useState('');
  const [updateUserFlag,setupdateUserFlag] =useState(false);

    const handleClose = () => {
      setOpenvendor(false);
      setisempty(false);
    };
    const [quantityList, setQuantityList] = useState([]);

    const handleQuantityChange = (index, name, value, vendor_slno,vendor_token,select_bid_token) => {
      const updatedList = [...quantityList];
      const vendorObject = {
        venname: name,
        quantity: value,
        sl_no: vendor_slno,
        ventoken:vendor_token,
        select_bid_token:select_bid_token
      };
  
      updatedList[index] = vendorObject;
      setQuantityList(updatedList);
    };

    const UserInputs = () => {
        return totaldata.map((name, index) => (
          <><h4>{name.company_vendor_code}</h4></>
          // <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          //   <DTextInput
          //     label="Vendor"
          //     type="text"
          //     value={name.vendor}
          //     size={"44%"}
          //     style={{ marginRight: '10px' }}
          //   />       
            /*<DTextInput
            label="Quantity"
            type="text"
            value={quantityList[index]?.quantity || ''}
            onChange={(e) => {
              handleQuantityChange(index, name.vendor, e.target.value, name.sl_no,name.vendor_token,name.token)
            }
            }
            size={"44%"}
          />*/
          // </div>
        ));
      };

     const UserInputsHeading = (
        <HeaderTitle 
        styleapply = {true}   
        titleText= {updateUserFlag ? "Add Quantity" :"Select Vendor(s) for PO Process"} 
          // customclass={classes.popupHeading}
        />
      ); 
      // const handleSubmit = (vendorname,quantityval) =>{
      //   const hasEmptyQuantities = quantityList.some(item => item.quantity === "");
      //   if (quantityList.length === 0 || hasEmptyQuantities) {
      //     setOpenModalsubmit(true);
      //     setMessage('Please enter quantity');
      //   }
      //   else{
      //     const totalQuantity = quantityList.reduce((sum, item) => sum + Number(item.quantity), 0);

      //     if(totalQuantity <= RFQ_quantity){
      //       setselectcbox(true);
      //       const row = ""
      //       handleSelectBid(row,quantityList)
      //       // setOpenvendor(false);
      //       setVendorname([]);
      //       setquantityval('');
      //       setSelectedRows([]);
      //       setVendor_slno([]);
      //       setOpenvendor(false);
      //       setQuantityList([]);
      //     }
      //     else{
      //       setOpenModalsubmit(true);
      //       setMessage("enter quantity less than"+ RFQ_quantity )
      //     }
      //   }
      // }

      const handleSubmit = (total_data) =>{
        const updatedList = total_data.map(item => {      
          return {
            venname: item.vendor,
            quantity: item.quantity, // Ensure you set the correct quantity
            sl_no: item.sl_no,
            ventoken: item.vendor_token,
            select_bid_token: item.token,
            // po_currency_value: po_currency_value
          };
        });
      
        // setQuantityList(updatedList);
      

        
          const totalQuantity = quantityList.reduce((sum, item) => sum + Number(item.quantity), 0);
          if(totalQuantity <= RFQ_quantity){
            setselectcbox(true);
            const row = ""
            handleSelectBid(row,updatedList)
            // setOpenvendor(false);
            setVendorname([]);
            setquantityval('');
            setSelectedRows([]);
            setVendor_slno([]);
            setOpenvendor(false);  
            setQuantityList([]);
           
          }
          else{
            setOpenModalsubmit(true);
            setMessage("enter quantity less than"+ RFQ_quantity )
          }
        // }
      }

    const UserInputsActionBtns = [
          <DButtonOutlined
            label={"Cancel"}
            onClick={handleClose}
            btnWidth={"17rem"}
            btnHeight={"4rem"}
          />,
          <DButton
            label={"Submit"}
            // onClick={() => handleSubmit(vendorname,quantityval)}
            onClick={() => handleSubmit(totaldata )}
          />,
        ];


  useEffect(() => {
    sortfunction();
  }, [sortedField, sortOrder]);
  return (
    <div className={styles.container}>
      <div
        className={`${styles.datatable_wrapper} ${customClass}`}
        style={{
          maxHeight: tableFixedHeight && tableFixedHeight,
        }}
      >
        <table
          style={{
            width: tableSize || "100vw",
          }}
        >
          <thead className={`${stickyHead && styles.stickyHead}`}>
            <tr>
              {selection && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                >
                  {/* <Checkbox
                    disabled={disableSelectAll}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: "22px" } }}
                    size="medium"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < data?.length
                    }
                    checked={
                      selectedRows.length > 0 &&
                      selectedRows.length === data?.length
                    }
                    onChange={handleSelectAllRows}
                  /> */}
                  <Checkbox
                    disabled={disableSelectAll}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: "22px" } }}
                    size="medium"
                    indeterminate={isSelectAllIndeterminate}  
                    checked={isSelectAllChecked}  
                    ref={(el) => el && (el.indeterminate = isSelectAllIndeterminate)}
                    onChange={handleSelectAllRows}
                  />
                </th>
              )}
              {headings.map((heading) => (
                <th
                  key={heading.field}
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                  style={{
                    textAlign:
                      heading.label === "Status" ||
                      heading.label === "Bid Status"
                        ? "left"
                        : "left",
                    width:
                      heading.label === "Status"
                        ? "15rem"
                        : heading.col_width && heading.col_width,
                  }}
                >
                  {/* show only field heading - default*/}
                  {!heading.selectable_header && heading.label}
                  {/* show only field heading - default*/}

                  {sorting &&
                    !heading.selectable_header &&
                    heading.label !== "Bids Status" && heading.label !== "Approval Status" && heading.label !== "Comments" && (
                      <button
                        onClick={() => {
                          handleSort(heading.field);
                        }}
                        style={{
                          outline: "none",
                        }}
                      >
                        <img
                          src={sortIcon}
                          className={styles.sortIcon}
                          alt=""
                        />
                      </button>
                    )}

                  {heading.selectable_header && (
                    <span
                      className={`${styles.flex} ${styles.pointer}`}
                      onClick={handleOpenDropdownHeading}
                    >
                      {heading.label}
                      <ArrowDropDownIcon
                        sx={{
                          width: "25px",
                          fontSize: "2rem",
                        }}
                      />
                    </span>
                  )}

                  {heading.selectable_header && (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      sx={{
                        ".MuiMenu-paper": {
                          marginTop: "0.6rem",
                          marginLeft: "2rem",
                          // top: '886px',
                          // left: '1058px',
                          boxShadow: "0px 6px 24px #00000017",
                        },
                      }}
                      onClose={handleCloseDropdownHeading}
                    >
                      {heading.selectable_header &&
                        quoteOptions?.map((option, index) => {
                          return (
                            <MenuItem
                              sx={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "1.4rem",
                                fontFamily: "gilroyMedium",
                              }}
                              key={index}
                              onClick={() => {
                                handleCloseDropdownHeading();
                                heading.selectable_header &&
                                  heading?.onClick &&
                                  heading?.onClick(
                                    option?.toLowerCase(),
                                    option
                                  );
                              }}
                            >
                              {option || <em></em>}
                            </MenuItem>
                          );
                        })}
                    </Menu>
                  )}
                </th>
              ))}
              {actions && (
                <th
                  className={`${styles.actionHead} ${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                >
                  Actions
                </th>
              )}
              {downloadSendmailForPo && !po_header &&(
                <>
                  <th
                    className={`${roles.Admin && styles.defaultHead} ${
                      roles.Buyer && styles.buyerHead
                    } ${roles.Vendor && styles.vendorHead}`}
                    style={{ textAlign: "center" }}
                  >
                    File Download
                  </th>
                  <th
                    className={`${roles.Admin && styles.defaultHead} ${
                      roles.Buyer && styles.buyerHead
                    } ${roles.Vendor && styles.vendorHead}`}
                    style={{ textAlign: "center" }}
                  ></th>
                </>
              )}
              {file && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                >
                  File
                </th>
              )}
              {timeline && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                >
                  Timeline
                </th>
              )}

              {selectBidBtn && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                ></th>
              )}
              {ownBidHeading && (
                <th
                  className={`${roles.Admin && styles.defaultHead} ${
                    roles.Buyer && styles.buyerHead
                  } ${roles.Vendor && styles.vendorHead}`}
                ></th>
              )}
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
                      
              {data &&
                data?.map((row, i) => (
                  <tr
                    key={row.id}
                    className={`${
                      row?.bid_version_int === maxBidIterationCount &&
                      isSelected(row.token)
                        ? styles.activeRow
                        : ""
                    }`}
                    // className={`${
                    //   isSelected(row.token) ? styles.activeRow : ""
                    // }`}
                  >
                    
                    {selection && (
                      <td>
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { fontSize: "22px",color:row.latest && selectVendors ? "blue" : "default"}}}
                          checked={(selectedvendorbids && row.status === "selected") ||
                            (row?.bid_version_int === maxBidIterationCount
                              ? isSelected(row.token)
                              : false)
                          }
                          onChange={() => handleRowSelect(row.token, row)}
                          disabled={
                           ( row?.bid_version_int !== maxBidIterationCount) ||
                           (selectedvendorbids && row.status === "unselected" || selectedvendorbids && row.status === null || selectedvendorbids && row.status === "") && 
                            !(!hasSelectedStatus && selectedVendors.every(vendor => vendor.status === "unselected"))
                          }
                        />
                      </td>
                    )}
                    
                    {headings.map((heading) => (
                      <td
                        key={heading.field}
                        style={{
                          width:
                            heading.label === "Status" ||
                            (heading.label === "Bid Status" && "150px"),
                          verticalAlign: heading.field === "role" && "middle",
                        }}
                      >
                        {heading.label === "Status" &&
                        heading.additional_data !== "toggle" ? (
                          <span
                            className={
                              row.bid_status === "open"
                                ? styles.activeStatus
                                : row.bid_status === "closed" ||
                                  row.bid_status === "cancelled" ||
                                  row.bid_status === "failed"
                                ? styles.InActiveStatus
                                : styles.activeStatus
                            }
                          >
                            {row.bid_status || "Active"}
                          </span>
                        ) : heading.label === "Bid Status" ? (
                          <span
                            className={
                              row.status === "success" && styles.successBid
                            }
                          >
                            {row.status && (
                              <img
                                src={bidSuccessImg}
                                className={styles.bidSuccessImg}
                                alt=""
                              />
                            )}{" "}
                            {row.status ? row.status : ""}
                          </span>
                        ) : heading.additional_data === "full_profile" ? (
                          <div className={styles.prName}>
                            <img
                              src={row.profile_image || profileImg}
                              alt={row.first_name}
                              className={styles.profile_image}
                            />
                            <span>{row[heading.field]}</span>
                          </div>
                        ) : heading.label === "Vendor" &&
                          vendorNameConfidential &&
                          ownBid &&
                          ownBid[0]?.vendor !== row?.vendor ? (
                          <span>
                            {typeof row[heading.field] === "string" &&
                              row[heading.field]?.slice(0, 1)}
                            *****
                          </span>
                        ) : vendorConfidential &&
                          ownBid &&
                          ownBid[0]?.vendor !== row?.vendor ? (
                          <span>
                            {typeof row[heading.field] === "string" &&
                              row[heading.field]?.slice(0, 1)}
                            ***
                          </span>
                        ) : heading.additional_data === "toggle" ? (
                          <span className={`${styles.flex}`}>
                            <SwitchButton
                              switchID={i}
                              onToggle={() => toggleOnChange(row.token, row)}
                              colorOn={"#59CE72"}
                              colorOff={"#CC0000"}
                              isOn={row.status}
                            />
                          </span>
                        ) : heading.dowloadFile ? (
                          <span>
                            <a
                              onClick={() => {
                                let fileName = heading.compare_analytics
                                  ? row.file_name
                                  : row[heading.field]?.split("/");
                                saveAs(
                                  row[heading.field],
                                  heading.compare_analytics
                                    ? fileName
                                    : fileName[fileName?.length - 1]
                                );
                              }}
                            >
                              <ImgFrame
                                imgSrc={fileIcon}
                                customClass={styles.fileBtn}
                              />
                            </a>
                          </span>
                        ) : heading.cellType === "view" ? (
                          <span
                            className={styles.viewDescriptionBtn}
                            onClick={() => {
                              handleOpenViewDescription(row[heading.field]);
                            }}
                          >
                            View
                          </span>
                        ) : 
                         heading.label === "Users"
                        ? ([...new Set(row[heading.field])].map((full_text, index) => (
                          <div key={index}>{full_text.full_text.trim()}</div>
                        ))
                        
                      ):
                      heading.label === "RFQ Code" ? (
                        <span>
                          {(() => {
                            let appendSuffix = false;
                            let cancelSuffix = false;
                            const nextAppStatus=row.next_app?.app_status;
                            const preAppStatus=row.pre_app?.app_status;
                            const currentStatus=row.current_status?.app_status;
                            const reGenerate = row.current_status?.re_generate;
                            const appStatus = row.approve_info?.app_status;
                            
                            if (loginData.access_type === "User") {
                              if(nextAppStatus === 1 || preAppStatus === 1 || (preAppStatus === null && currentStatus ===1 && reGenerate!==1) || (preAppStatus === undefined && currentStatus ===1 && reGenerate!==1 && appStatus!==1 && nextAppStatus!==1)){
                                appendSuffix = true;
                              }
                            }
                            if (loginData.access_type === "User") {
                              if(row.is_active === false){
                                cancelSuffix = true;
                              }
                            }
                            if (loginData.access_type === "Buyer" && 
                                row.is_active !== false &&
                                row.final_status === "in-progress" && 
                                row.current_status?.app_status === 1 && 
                                row.current_status?.re_generate !== 1) {
                                appendSuffix = true;
                            }
                            if (loginData.access_type === "Buyer" && 
                                row.is_active === false){
                                cancelSuffix = true;
                            }
                            
                          return `${row[heading.field]}${appendSuffix ? "_A" : cancelSuffix ? "_C": ""}`;
                          })()}
                        </span>
                      ):
                       heading.label === "PO Number" ? (
                        <span>{row[heading.field]}</span>
                      ) : heading.label === "Approval Status" ? (
                        <span>
                          {loginData.access_type === "User" &&
                          <>
                            {row.is_active === true ? (
                            <>
                              <ApproverTooltip approvers={row.approver}>
                                <span style={{
                                  color: row.approve_info?.app_status === 1
                                    ? 'red'
                                    : row.approve_info?.app_status === 2
                                    ? 'green'
                                    : row.approve_info?.app_status === null
                                    ? '#ffc107'
                                    : 'black'
                                  }}>{
                                  row.approve_info?.app_status === 1 ? "Rejected" :
                                  row.approve_info?.app_status === 2 ? "Approved" :
                                  row.approve_info?.app_status === null ? "Pending" : ""}
                                </span>
                              </ApproverTooltip>
                              <br></br>
                              {/* <span >{row.next_app?.app_status === 1 ? ` (Rejected by  ${row.next_app?.account_id__org__first_name})`:false}</span>
                              <span>{row.pre_app?.app_status === 1 ? ` (Rejected by ${row.pre_app?.account_id__org__first_name})`:false}</span>
                              <span >{row.pre_app?.app_status === null ? ` (Waiting for previous level approval)`:false}</span> */}
                              <StatusMessage 
                                      // appStatus={row.approve_info?.app_status} 
                                      nextAppStatus={row.next_app?.app_status}
                                      nextAppName={row.next_app?.account_id__org__first_name}
                                      preAppStatus={row.pre_app?.app_status}
                                      preAppName={row.pre_app?.account_id__org__first_name}
                                      currentStatus={row.current_status?.app_status}
                                      currentName={row.current_status?.account_id__org_id__first_name}
                                      reGenerate = {row.current_status?.re_generate}
                                      appStatus = {row.approve_info?.app_status}
                              />
                            </>):(
                              <span style={{ color: 'red' }}>Cancelled</span>
                            )}
                          </>
                          }
                          {  loginData.access_type === "Buyer" &&
                            <>
                              {/* <span>{row.approval_count === 0 ? `-`:false}</span> */}
                              {/* <LightTooltip title={`waiting for ${row.approver?.account_id__org_id__first_name}'s approval `} arrow placement="top">
                              <span style={{color :'#ffc107'}}>{row.approval_count && row.approval_count >0 && row.current_status === null ? `Waiting for approval`:false}</span>
                              </LightTooltip>
                              <span style={{color :'green'}}>{row.final_status === "completed" ? `Approved`:false}</span>
                              <span style={{color :'green'}}>{row.final_status === "in-progress" && row.current_status?.app_status === 2 ? `Approved by ${row.current_status?.account_id__org_id__first_name}`:false}</span>
                              <span style={{color :'red'}}>{row.final_status === "in-progress" && row.current_status?.app_status === 1 && row.current_status?.re_generate!==1? `Rejected by ${row.current_status?.account_id__org_id__first_name}`:false}</span>
                              <LightTooltip title={`waiting for ${row.approver?.account_id__org_id__first_name}'s approval `} arrow placement="top">
                              <span style={{color :'#ffc107'}}>{row.final_status === "in-progress" && row.current_status?.app_status === 1 && row.current_status?.re_generate===1? `Waiting for approval`:false}</span>
                              </LightTooltip> */}
                              <span>{row.approval_count === 0 ? `-` : false}</span>
                              
                              {/* <span style={{ color: 'red' }}>{row.is_active === false ? `Cancelled` : false}</span> */}

                              {row.is_active === true ? (
                                <>
                                  <span style={{ color: 'green' }}>
                                    {row.final_status === "completed" ? `Approved` : false}
                                  </span>
                                  <ApproverTooltip approvers={row.approver}>
                                    <span style={{ color: '#ffc107' }}>
                                      {row.approval_count && row.approval_count > 0 && row.current_status === null
                                        ? `Waiting for approval`
                                        : false}
                                    </span>
                                  </ApproverTooltip>

                                  <ApproverTooltip approvers={row.approver}>
                                    <span style={{ color: 'green' }}>
                                      {row.final_status === "in-progress" &&
                                      row.current_status?.app_status === 2
                                        ? `Approved by ${row.current_status?.account_id__org_id__first_name}`
                                        : false}
                                    </span>
                                  </ApproverTooltip>

                                  <ApproverTooltip approvers={row.approver}>
                                  <span style={{ color: 'red' }}>
                                    {row.final_status === "in-progress" &&
                                    row.current_status?.app_status === 1 &&
                                    row.current_status?.re_generate !== 1
                                      ? `Rejected by ${row.current_status?.account_id__org_id__first_name}`
                                      : false}
                                  </span>
                                  </ApproverTooltip>

                                  <ApproverTooltip approvers={row.approver}>
                                    <span style={{ color: '#ffc107' }}>
                                      {row.final_status === "in-progress" &&
                                      row.current_status?.app_status === 1 &&
                                      row.current_status?.re_generate === 1
                                        ? `Waiting for approval`
                                        : false}
                                    </span>
                                  </ApproverTooltip>
                                </>
                              ) :(
                                <span style={{ color: 'red' }}>Cancelled</span>
                              )}
                              

                            </>
                          }
                        </span>
                      ) : 
                      heading.label === "PO Date" ? (
                        <span>{row.purchase_order_date?
                          new Date(row.purchase_order_date).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                          }) :'-'
                        }
                        </span>
                      ) : 
                      heading.label === "Comments" ? (
                        // <span>{row.current_status?.comment && row.current_status?.re_generate!==1 ?row.current_status?.comment : '-' }</span>
                        <CommentsTooltip approvers={row.approver}>
                        <span>{row.current_status?.comment && row.current_status?.re_generate!==1 ?row.current_status?.comment : '-' }</span></CommentsTooltip>
                      ) : 
                      heading.label === "Item Name" && heading.field === "name_list" ? (
                        <span onClick={()=>handleNameClick(row.item_list)}>{row.name_list}</span>
                      ) : 
                      // heading.label === "Item Price" ? (
                      //   <div>
                      //     {Object.keys(poPricesMap).length > 0 ? (
                            
                      //       Object.entries(poPricesMap).map(([poNumber, itemPrices], index) => {
                      //         if (poNumber === row.PO_number) {
                      //           return (
                      //             <div key={index}>
                      //                              <span>
                                      
                      //                 {itemPrices.join(', ')}</span><span>   -  {row.currency_type}</span>
                      //             </div>
                      //           );
                      //         }
                      //         return null; 
                      //       })
                      //     ) : (
                      //       <span>-</span> 
                      //     )}
                      //   </div>
                      // )
                      heading.label === "Grand Total" ? (
                        <div>
                          {Object.keys(poPricesMap).length > 0 ? (
                            
                            Object.entries(poPricesMap).map(([poNumber, itemPrices], index) => {
                              if (poNumber === row.PO_number) {
                                const grandTotal = itemPrices.reduce((total, price) => total + Number(price), 0);
                                return (
                                  <div key={index}>
                                                   <span>
                                      
                                      {grandTotal.toFixed(2)}</span><span>   -  {row.currency_type}
                                      </span>
                                  </div>
                                );
                              }
                              return null; 
                            })
                          ) : (
                            <span>-</span> 
                          )}
                        </div>
                      )
                       :
                      
                      (
                        <span>
                          {row[heading.field] ||
                            `${
                              (heading.label === "Quote Iteration" &&
                                row.version === null) ||
                              row.version === 0 ||
                              (row.version === "" && hasBidIteration)
                                ? "Initial"
                                : "-"
                            }`}
                        </span>
                      )
                      }
                      </td>
                    ))}

                    {actions && !po_header && !po_buyer && !buyer_rfq && !costcenter_codes && !gl_codes && !projects_codes && !Tax_gst &&(
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              itemsTable && !row.status
                                ? ""
                                : editClick(row.token ? row.token : row.id, row)
                            }
                            customClass={`${styles.actBtn} ${
                              itemsTable && !row.status && styles.disabled
                            }`}
                          />
                        )}

                        {deleteBtn && (
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token ? row.token : row.id, row)}
                            customClass={styles.actBtn}
                          />
                        )}
                      </td>
                    )}
                    {actions && !po_header && !po_buyer && !buyer_rfq && costcenter_codes && !gl_codes && !projects_codes &&!Tax_gst &&(
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              editClick(row.token ? row.token : row.id, row)
                              // itemsTable && !row.status
                              //   ? ""
                              //   : editClick(row.token ? row.token : row.id, row)
                            }
                            customClass={
                              styles.actBtn
                          }
                          />
                        )}

                        {deleteBtn && (
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token ? row.token : row.id, row)}
                            customClass={styles.actBtn}
                          />
                        )}
                      </td>
                    )}
                     {actions && !po_header && !po_buyer && !buyer_rfq && !costcenter_codes && gl_codes && !projects_codes &&!Tax_gst &&(
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              editClick(row.token ? row.token : row.id, row)
                              // itemsTable && !row.status
                              //   ? ""
                              //   : editClick(row.token ? row.token : row.id, row)
                            }
                            customClass={`${styles.actBtn} 
                            ${
                              itemsTable 
                              // && 
                              // !row.status
                              //  && styles.disabled
                            }`
                          }
                          />
                        )}

                        {deleteBtn && (
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token ? row.token : row.id, row)}
                            customClass={styles.actBtn}
                          />
                        )}
                      </td>
                    )}

                    {actions && !po_header && !po_buyer && !buyer_rfq && !costcenter_codes && !gl_codes && projects_codes && !Tax_gst &&(
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              editClick(row.token ? row.token : row.id, row)
                              // itemsTable && !row.status
                              //   ? ""
                              //   : editClick(row.token ? row.token : row.id, row)
                            }
                            customClass={`${styles.actBtn} 
                            ${
                              itemsTable 
                              // && 
                              // !row.status
                              //  && styles.disabled
                            }`
                          }
                          />
                        )}

                        {deleteBtn && (
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token ? row.token : row.id, row)}
                            customClass={styles.actBtn}
                          />
                        )}
                      </td>
                    )}
                    
                    {actions && !po_header && !po_buyer && !buyer_rfq && !costcenter_codes && !gl_codes && !projects_codes && Tax_gst &&(
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              editClick(row.token ? row.token : row.id, row)
                              // itemsTable && !row.status
                              //   ? ""
                              //   : editClick(row.token ? row.token : row.id, row)
                            }
                            customClass={
                              styles.actBtn
                          }
                          />
                        )}

                        {deleteBtn && (
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token ? row.token : row.id, row)}
                            customClass={styles.actBtn}
                          />
                        )}
                      </td>
                    )}
                    {actions && buyer_rfq &&(
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {editBtn && row.org_approval === "False" && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              itemsTable && !row.status
                                ? ""
                                : editClick(row.token ? row.token : row.id, row)
                            }
                            customClass={`${styles.actBtn} ${
                              itemsTable && !row.status && styles.disabled
                            }`}
                          />
                        )}

                        {/* {deleteBtn && row.org_approval === "False" &&(
                          <ImgFrame
                            imgSrc={dltIcon}
                            ImgClick={() => deleteClick(row.token ? row.token : row.id, row)}
                            customClass={styles.actBtn}
                          />
                        )} */}
                      </td>
                    )}
                    {actions && po_buyer &&(
                      <td className={styles.action}>
                        {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )}

                        {row.is_active ===true ?(
                        editBtn && (
                          <ImgFrame
                            imgSrc={editIcon}
                            ImgClick={() =>
                              itemsTable && !row.status
                                ? ""
                                : editClick(row.token ? row.token : row.id, row)
                            }
                            customClass={`${styles.actBtn} ${
                              itemsTable && !row.status && styles.disabled
                            }`}
                          />
                        )):('')}
                        {/* { row.is_active ===true && row.approval_count===0 ?( */}
                        {  row.is_active ===true ?(
                        cnclIcon && (
                          <ImgFrame
                            imgSrc={cnclIcon}
                            ImgClick={() => deleteClick(row.token ? row.token : row.id, row)}
                            customClass={styles.actBtn}
                          />
                        ) ):(<span>&nbsp; &nbsp; &nbsp; &nbsp;</span>)
                      }
                      </td>
                    )}
                    {actions && po_header &&(
                      <td className={styles.action}>
                        
                        {/* {viewBtn && (
                          <ImgFrame
                            imgSrc={viewIcon}
                            ImgClick={() => {
                              viewClick(row.token ? row.token : row.id, row);
                            }}
                            customClass={styles.actBtn}
                          />
                        )} */}
                        {row.is_active ===true ?(
                          editBtn && (
                            <ImgFrame
                              imgSrc={editIcon}
                              ImgClick={() =>
                                itemsTable && !row.status
                                  ? ""
                                  : editClick(row.token ? row.token : row.id, row)
                              }
                              customClass={`${styles.actBtn} ${
                                itemsTable && !row.status && styles.disabled
                              }`}
                            />
                          ) 
                      ):('')}
                      </td>
                    )}

                    {downloadSendmailForPo && !po_header && (
                      <>
                        <td style={{ width: "13rem" }}>
                          {sendMail && (
                            <div style={{ textAlign: "center" }}>
                              <ImgFrame
                                imgSrc={downloadIcon}
                                customClass={`${styles.fileBtn} ${styles.marginAuto}`}
                                ImgClick={() => downloadClick(row.token, row)}
                              />
                            </div>
                          )}
                        </td>
                        <td style={{ width: "16rem" }}>
                          {Download && (
                            <DButton
                              label="EMAIL PO"
                              btnHeight="3.2rem"
                              btnWidth="12rem"
                              vendorBtn={true}
                              customClass={styles.selectBidBtn}
                              onClick={() => {
                                handleClickSendPoEmail(i, row);
                              }}
                              disabled={ 
                                bulkData &&
                                checkIsBidSelected
                                  ?.map((e) => e === "selected")
                                  ?.filter((e) => e === true)?.length !== 0 || row.final_status !== "completed"
                              }
                            />
                          )}
                        </td>
                      </>
                    )}
                    {(file && org) ||
                    buyer ||
                    (ownBid && ownBid[0]?.vendor === row?.vendor) ? (
                      row.document !== "None" &&
                      row.document !== null &&
                      row.document !== "" ? (
                        <td>
                          <span>
                            <a
                              onClick={() => {
                                let fileName = row?.document?.split("/");
                                let replaceStringtoURL = row.document.replace(
                                  "https://teckost.s3.amazonaws.com/",
                                  `${cloudFrontURL}/`
                                );
                                // saveAs(`${cloudFrontURL}/${row.document}`, fileName[fileName?.length - 1])
                                saveAs(
                                  `${replaceStringtoURL}`,
                                  fileName[fileName?.length - 1]
                                );
                              }}
                            >
                              <ImgFrame
                                imgSrc={fileIcon}
                                customClass={styles.fileBtn}
                              />
                            </a>
                          </span>
                        </td>
                      ) : (
                        <td>
                          {" "}
                          <span
                            className={styles.viewDescriptionBtn}
                            onClick={() => {
                              handleOpenViewDescription(row.description);
                            }}
                          >
                            View
                          </span>
                        </td>
                      )
                    ) : (
                      ""
                    )}
                    {timeline && (
                      <td>
                        <Badge
                          color="error"
                          variant="dot"
                          invisible={invisible}
                          size="small"
                          onClick={() => {
                            viewTimeLineClick(row.token, row);
                          }}
                        >
                          <ImgFrame
                            imgSrc={timelineIcon}
                            customClass={styles.timeicon}
                          />
                        </Badge>
                      </td>
                    )}

                    {ownBid && ownBid[0]?.vendor === row?.vendor && (
                      <td className={styles.ownBid}>
                        <ImgFrame
                          imgSrc={ownBidIcon}
                          customClass={styles.ownBidImage}
                        />
                        <span
                          style={{
                            marginLeft: "1rem",
                          }}
                        >
                          Own Bid
                        </span>
                      </td>
                    )}
                     {selectBidBtn && (
                      <td style={{ width: "16rem" }}>
                        <div
                          className={`${styles.flex} ${styles.SelectBidSection}`}
                        >
                          {!isSelectedBid(i) && row.status === "selected" ? (
                            <>
                              <span className={styles.bidSelectedText}>
                                SELECTED
                              </span>
                            </>
                          ) : null}
                        </div>
                      </td>
                    )}
                  </tr>
                ))} 
            </tbody>
          )}
        </table>
      </div>

      {isLoading && (
        <span className={styles.infoMsg}>
          <CircularProgress
            thickness={6}
            variant="indeterminate"
            disableShrink
            sx={{
              animationDuration: "450ms",
              color: "#55555580",
            }}
            size={25}
          />
        </span>
      )}

      {errorMessage && (
        <span className={styles.infoMsg}>
          {errorMessage || "No data found."}
        </span>
      )}

      <Modal
        open={descriptionPopup.open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={descriptionPopup.open}>
          <div className={styles.flex}>
            <PopupFrame
              customClass={styles.VUPopup}
              inputChildren={viewDescriptionContent}
              poupHeading={viewDescriptionHeading}
              actionButtons={viewDescriptionAction}
              closePopup={handleCloseViewDescription}
            />
          </div>
        </Fade>
      </Modal>


      <Modal
        open={openvendor}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={openvendor}>
          <div
            style={{
              height: "140vh",
            }}
          >
            {/* <PopupFrame
              inputChildren={UserInputs()}
              poupHeading={UserInputsHeading}
              actionButtons={UserInputsActionBtns}
              closePopup={handleClose}
            /> */}
            <PopupFrame
              key={''}
              inputChildren={UserInputs()}
              poupHeading={UserInputsHeading}
              actionButtons={UserInputsActionBtns}
              closePopup={handleClose}
              customClass={''}
              styleCenterDiv={styles.vendorSelect}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        open={openModalsubmit}
        onClose={() => setOpenModalsubmit(false)} // Ensure you can close the modal
      >
        <Fade in={openModalsubmit}>
        <div style={{ 
              padding: '50px', 
              backgroundColor: 'white', 
              borderRadius: '10px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              width: '600px', // Set your desired width
              margin: 'auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)' // Center the modal
            }}
          >
            <p style={{ fontSize: '18px' }}>{message}</p> 
            <button onClick={() => setOpenModalsubmit(false)}>Close</button>
          </div>
        </Fade>
      </Modal>

      <Modal open={open}>
        <Fade in={open}>
          <Box>
            <SuccessPopupDashboard
              //popupImage={successPopupImg}
              popupHeroText={popupHeroText}
              popupDescription={popupMessage}
              popBtn={true}
              vendorBtn={true}
              btnText={"Okay"}
              btnClick={openRFQMainCancel}
            />
          </Box>
        </Fade>
      </Modal>


      <Modal
        open={IsPopupOpen}
        onClose={() => setIsPopupOpen(false)} // Ensure you can close the modal
      >
        <Fade in={IsPopupOpen}>
        <div
          style={{
            padding: '100px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 6px 30px rgba(0, 0, 0, 0.2)',
            width: '800px',
            height: '500px',
            overflowY: 'auto', // Makes the popup scrollable if content overflows
            margin: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          
          <HeaderTitle titleText={"Item details"} customclass={styles.popupHeading} />
          
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
              <thead>
                <tr style={{ backgroundColor: '#f0f0f0', textAlign: 'left' }}>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Item Name</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Item Code</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Description</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Quantity</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Unit Price</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>GST</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {selectedItemList.map((item, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.item_name}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.item_code}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.description || 'N/A'}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.quantity}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.item_rfqunit_prices}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}> {item.tax} </td>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.item_rfqtotal_prices}</td>

                  </tr>
                ))}
              </tbody>
            </table>
            <DButtonOutlined
            label={"Cancel"}
            onClick={() => setIsPopupOpen(false)}
            btnWidth={"17rem"}
            btnHeight={"4rem"}
          />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default memo(DataTable);

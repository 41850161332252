import { forwardRef } from "react";
import styles from "./PoPdfView.module.css";
import { format, parseISO } from "date-fns";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
const PoPdfView = forwardRef(({ pdfData }, ref) => {
  const data = useSelector((state) => state.loginData.value);
  const AdminData = useSelector((state) => state.adminData.value);
  //console.log("userData: ",data);
  const {
    issuer_details = {},
    vendor_details = {},
    billing_details = {},
    shipping_details = {},
    pricing: priceDetails = {},
    terms = {},
    item_list = {},
    company_vendor_code = "",
    delivery_date = '',
    payment_date = ''
  } = pdfData;
  function addressFormate(address) {
    if (!address || Object.keys(address).length === 0) return "-";
    return Object.values(address).filter(Boolean).join(", ");
  }
  function dateFormate(realDate) {
    if (!realDate) return "-";
    const parsedDate = parseISO(realDate);
    return format(parsedDate, "dd/MM/yyyy");
  }
  // issuer
  const {
    issuer_details_name,
    issuer_details_email,
    issuer_details_PAN,
    issuer_details_GSTIN,
    issuer_details_CIN,
    issuer_details_purchase_order_no,
    issuer_details_purchase_order_date,
    issuer_details_address,
  } = issuer_details;
  // vendor
  //const { vendor_details_name, vendor_details_address } = vendor_details;
  const { vendor_details_name, vendor_details_address,vendor_org_profile ,vendor_image_base64} = vendor_details;
  // billing
  const { billing_details_name, billing_details_address } = billing_details;
  // shipping
  const { shipping_details_name, shipping_details_address } = shipping_details;
  // pricing
  const { converted_currency: totalPrice } = priceDetails;
  // Terms & condition
  const {
    payment_terms,
    validity,
    delivery_terms,
    mode_of_transportation,
    terms_and_conditions_of_purchase = "",
  } = terms;
  let itemList = [];
  let grandTotal = 0;
  let CurrencyType = '';
  if (item_list.length > 0) {
    itemList = item_list
    .filter((item) => Number(item.quantity) > 0)
    .map((item) => {
      grandTotal = grandTotal + Number(item.item_rfqtotal_prices);
      CurrencyType = item.currency_type;
      return {
        itemName: item.item_name,
        itemCode: item.item_code,
        HSNCode: item.hsn_code,
        UNSPSCCode: item.unspsc_code,
        Unit: item.unit,
        Quantity: item.quantity,
        Tax:item.tax,
        PartNumber:item.part_number,
        Description:item.description,
        UnitPrice:item.item_rfqunit_prices,
        itemTotalPrice : item.item_rfqtotal_prices,
      };
    });
  }
  // console.log('itemList',itemList)

  const deliveryDate = delivery_date;
  const paymentDate = payment_date;
  // console.log('paymentDate', paymentDate)

  const formattedDdate = deliveryDate 
  ? new Date(deliveryDate).toLocaleDateString("en-GB") 
  : "";
  const formattedPayDate =paymentDate ? new Date(paymentDate).toLocaleDateString("en-GB") : "";
  // console.log('formattedPayDate', formattedPayDate)
  const issuerAddress = addressFormate(issuer_details_address);
  const t_c = parse(terms_and_conditions_of_purchase);
  return (
    <div className={styles.poPdfContainer} ref={ref}>
      <table
        className={`${styles.removeDefaultStyle} ${styles.borderColapse}`}
        style={{ fontSize: "10px" }}
      >
        <tbody>
          <tr>
            <td>
              <h1 className={styles.header}>Purchase Order</h1>
            </td>
          </tr>

          <tr>
            <td>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Vendor Image */}
                <div style={{ marginRight: '10px' }}>
                  {vendor_image_base64 && vendor_image_base64 !== '' ? (
                    <img
                      src={vendor_image_base64}
                      alt="Vendor Organization Profile"
                      style={{ width: '100px', height: 'auto', border: '1px solid black' }}
                    />
                  ) : null}
                </div>
                {/* Issuer Details */}
                <div style={{ flex: 1 , fontSize: '12px'}}>
                  <h3 style={{ margin: '0' }}>{issuer_details_name}</h3>
                  <p style={{ margin: '0' }}>{issuerAddress}</p>
                  <p style={{ margin: '0' }}>E-mail : {issuer_details_email}</p>
                </div>
              </div>
            </td>
          </tr>

          {/* <tr>
            <td>
              <div className={styles.issuerDetails}>
                <h3 className={styles.issuerDetailsTitle}>
                  {issuer_details_name}
                </h3>
                <p className={styles.issuerDetailsAddress}>{issuerAddress}</p>
                <p className={styles.issuerDetailsEmail}>
                  E-mail : {issuer_details_email}
                </p>
              </div>
            </td>
          </tr> */}
          <tr>
            <td>
              <ul className={styles.issuerDetailsRegisterNumber}>
                <li>
                  <h4>PAN NO :</h4>
                  <p>{data.user['pan_card']}</p>
                  
                </li>
                <li>
                  <h4>GSTIN :</h4>
                  <p>{data.user['gst_number']}</p>
                  
                </li>
                <li>
                  <h4>CIN NO :</h4>
                  <p>{data.user['company_registration_number']}</p>
                 
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.columnLayout}>
                <ul className={styles.orderNumberDate}>
                  <li>
                    <h4>Purchase Order No :</h4>
                    <p>{issuer_details_purchase_order_no}</p>
                  </li>
                  <li>
                    <h4>Purchase Order Date :</h4>
                    <p>{dateFormate(issuer_details_purchase_order_date)}</p>
                  </li>
                </ul>
                <ul className={styles.orderNumberDate}>
                  <li>
                    <h4>Delivery Date :</h4>
                    <p>{formattedDdate}</p>
                  </li>
                  <li>
                    <h4>Payment Date :</h4>
                    <p>{formattedPayDate}</p>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{ padding: 0 }}>
              <table
                className={`${styles.removeDefaultStyle} ${styles.removeBorder} ${styles.vendorDetailsTable}`}
              >
                <tbody>
                  <tr>
                    <td className={styles.cardTableHeader}>
                      <strong>Vendor Details</strong>
                    </td>
                    <td className={styles.cardTableHeader}>
                      <strong>Billing Details</strong>
                    </td>
                    <td
                      style={{ borderRight: "none" }}
                      className={styles.cardTableHeader}
                    >
                      <strong>Shipping Details</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={styles.verticalTop}
                      style={{ borderBottom: "none" }}
                    >
                      <div className={styles.detailsCard}>
                        {/* <h4>{vendor_details_name}</h4> */}
                        <h4>{company_vendor_code}</h4>
                        <p>{addressFormate(vendor_details_address)}</p>
                      </div>
                    </td>
                    <td
                      className={styles.verticalTop}
                      style={{ borderBottom: "none" }}
                    >
                      <div className={styles.detailsCard}>
                        <h4>{billing_details_name}</h4>
                        <p>{addressFormate(billing_details_address)}</p>
                      </div>
                    </td>
                    <td
                      className={styles.verticalTop}
                      style={{ borderBottom: "none", borderRight: "none" }}
                    >
                      <div className={styles.detailsCard}>
                        <h4>{shipping_details_name}</h4>
                        <p>{addressFormate(shipping_details_address)}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{ padding: 0 }}>
              <table
                className={`${styles.removeDefaultStyle} ${styles.customItemListTable}  ${styles.removeBorder}`}
              >
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Part No</th>
                    <th>Description of Goods</th>
                    <th>Item Name</th>
                    <th>Item Code</th>
                    <th>HSN Code</th>
                    <th>Quantity</th>
                    {/* <th>UNSPSC Code</th>*/}
                    {/* <th>Unit</th>  */}
                    
                    <th>UOM</th>
                    <th>Rate ({CurrencyType})</th>
                    <th>Tax (%)</th>
                    <th>Taxable Amount</th>
                    {/* <th style={{ borderRight: "none" }}>Taxable Amount</th> */}
                  </tr>
                </thead>
                <tbody>
                  {itemList?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.PartNumber || "-"}</td>
                      <td>{item.Description || "-"}</td>
                      <td>{item.itemName || "-"}</td>
                      <td>{item.itemCode || "-"}</td>
                      <td>{item.HSNCode || "-"}</td>
                      <td>{item.Quantity || "-"}</td>
                      {/* <td>{item.UNSPSCCode || "-"}</td>*/}
                      <td>{item.Unit || "-"}</td> 
                      
                      {/* <td style={{ borderRight: "none" }}>00.00</td> */}
                     
                      <td>{item.UnitPrice || "-"}</td>
                      <td>{item.Tax + "%" || "-"}</td>
                      <td>{item.itemTotalPrice || "-"}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4"></td>
                    <td
                      colSpan="3"
                      style={{
                        fontSize: "10px",
                        borderRight: "none",
                        textAlign: "right",
                        paddingRight: "10px",
                      }}
                    >
                      Grand Total : {grandTotal.toFixed(2)}  {CurrencyType}
                    </td>
                    {/* <td
                      style={{
                        textAlign: "right",
                        padding: "5px",
                        borderLeft: "1px solid #343434",
                      }}
                    ></td> */}
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <div className={styles.termsAndConditionContent}>
                <h4>Terms & Condition</h4>
                <ul className={styles.termsAndConditionList}>
                  <li>
                    <p>Payment Terms:</p>
                    <span>{payment_terms}</span>
                  </li>
                  <li>
                    <p>Validity:</p>
                    <span>{validity}</span>
                  </li>
                  <li>
                    <p>Delivery terms:</p>
                    <span>{delivery_terms}</span>
                  </li>
                  <li>
                    <p>Mode of Transportation:</p>
                    <span>{mode_of_transportation}</span>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={styles.termsContent}>{t_c}</div>
            </td>
          </tr>     
        </tbody>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <p>This is a computer-generated document. No signature is required.</p>
      </div>
      </table>
    </div>
  );
});

export default PoPdfView;

import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import IntlTelInput from "react-intl-tel-input";
import { useForm, Controller } from "react-hook-form";
import { PDFDocument } from "pdf-lib";
import {
  Box,
  CircularProgress,
  Fade,
  Modal,
  MenuItem,
  Select,
  Button,
  Backdrop,
} from "@mui/material";
import ReactQuill from "react-quill";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import moment from 'moment';

import "react-intl-tel-input/dist/main.css";
import DateBar from "../../../components/common/DateBar/DateBar";
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import NotificationBell from "../../../components/common/NotificationBell/NotificationBell";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import SearchBar from "../../../components/common/SearchBar/SearchBar";
import PaginantionComponent from "../../../components/common/Pagination/PaginationComponent";
import { nextPageClick, previouPageClick } from "../../../utils/pagination";
import DataTable from "../../../components/common/DataTable/DataTableSorting";
import useFetchData from "../../../hooks/useFetchData";
import { API_ENDPOINT } from "../../../services/api/resources";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
//ui
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import { validateSelect } from "../../../utils/validations";
//css
import "react-quill/dist/quill.snow.css";
import classes from "./PoTab.module.css";
import styles from "../../../components/Signup/SignupInputs/SInputs.module.css";
import "./quilEditor.css";
//image
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
import deletePopupImg from "../../../assets/images/png/Group 2@2x.png";
import PoPdfView from "../../../components/common/HtmlToPdf/PoPdfView";

//view
import PopupFrame from "../../../components/Popups/PopupFrame/PopupFrame";
import InfoLabel from "../../../components/common/InfoLabel/InfoLabel";
import { MoreHoriz } from "@mui/icons-material";
import DTextInput from "../../../components/Dashboard/DashboardInputs/DTextInput";
import { size } from "lodash";
import StatusMessage from "../../../components/common/StatusMessage/StatusMessage";
import DDateInput from "../../../components/Dashboard/DashboardInputs/DDateInput";
import { emptyCheck } from "../../../utils/validations";
import DropdownBtnOutlined from "../../../components/Dashboard/DropdownButtons/DropdownBtnOutlined/DropdownBtnOutlined";
import { downloadFile } from "../../../utils/downloadFile";

function PoTab() {
  const pdfRef = useRef();
  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  //Component state
  const auth_token = data?.auth_token;
  const buyer_token = data?.user?.token;

  //po status

  //api param
  let idParams = [
    {
      key: "buyer_token",
      value: buyer_token ? buyer_token : "",
    },
  ];
  const currentPage = 1;
  //const sortingColumnName = null;
  //const sortingOrder = null;

  const [sortingColumnName, setSortingColumnName] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationData, setPaginationData] = useState();

  const [poData, setPoData] = useState([]);
  const [tableProps, setTableProps] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const [openForm, setOpenForm] = useState({
    open: false,
    loading: false,
    errorMessage: "",
  });
  const [poEdit, setPoEdit] = useState(false);
  // selected PO item
  const [selectedPo, setSelectedPo] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [open, setOpen] = useState(false);
  const [successModalContent, setSuccessModalContent] = useState({
    title: "",
    description: "",
  });
  // deletion PO item
  const [deletionPo, setDeletionPo] = useState({});
  const [openDltConfirmation, setOpenDltConfirmation] = useState(false);
  const [openRstConfirmation, setOpenRstConfirmation] = useState(false);
  const [isPoDeleting, setIsPoDeleting] = useState(false);
  // view PO item
  const [viewPO, setViewPo] = useState({});
  const [pdfData, setPdfData] = useState(null);
  const [openPoPdfModal, setOpenPoPdfModal] = useState(false);
  const [openViewItemPopup, setOpenViewItemPopup] = useState(false);
  const [generateViewPdfFlag, setGenerateViewPdfFlag] = useState(false);
  // download PO item
  const [generateDownloadPdfFlag, setGenerateDownloadPdfFlag] = useState(false);
  // send PO item
  const [generateSendPdfFlag, setGenerateSendPdfFlag] = useState(false);

  //view
  const [uniqueData, setUniqueData] = useState();

  const [comments, setComments] = useState("");
  const [ddate, setDdate] = useState({
    value: "",
    isValid: true,
  });
  const [paydate, setPaydate] = useState({
    value: "",
    isValid: true,
  });
  const [duration, setDuration] = useState();

  const [primaryBtnError, setPrimaryBtnError] = useState({
    error: false,
    errorMsg: "",
  });

  const [btnError, setBtnError] = useState({
    error: false,
    errorMsg: "",
  });


  const [unitpriceval,setunitpriceval] = useState('')
  
  // shipping address state
  const [countryToken, setCountryToken] = useState(0);
  const [stateToken, setstateToken] = useState(0);
  const [city, setCity] = useState({
    value: "",
    label: "",
    cityList: [],
    isValid: true,
  });
  const [stateName, setStateName] = useState({
    value: "",
    label: "",
    stateList: [],
    isValid: true,
  });
  const [country, setCountry] = useState({
    value: "",
    label: "",
    countryList: [],
    isValid: true,
  });
  const [costcenter, setcostcenter] = useState({
    value: "",
    label: "",
    cc_codeList: [],
    isValid: true,
  });
  const [glcode, setglcode] = useState({
    value: "",
    label: "",
    gl_codeList: [],
    isValid: true,
  });
  const [projectcode, setprojectcode] = useState({
    value: "",
    label: "",
    project_codeList: [],
    isValid: true,
  });

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    reset,
    setValue,
    watch,
    control,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: selectedPo,
  });

  const loginData = useSelector((state) => state.loginData.value);
  const accessType = loginData?.access_type;

  const { errors } = formState;
  const issuerMobileNumber = watch("issuerMobileNumber");
  const vendorMobileNumber = watch("vendorMobileNumber");
  const billingMobileNumber = watch("billingMobileNumber");
  const shippingMobileNumber = watch("shippingMobileNumber");
  const shippingCountry = watch("shippingCountry");
  const shippingState = watch("shippingState");
  const shippingCity = watch("shippingCity");

  // effect hook
  useEffect(() => {
    GetAllPoData(pageNumber);
    
    // if(approveStatus == "1"){
    //   setIsUpdating(false)
    // }
  }, [pageNumber, searchQuery, open, openDltConfirmation, sortingColumnName,
        sortingOrder]);
  //Item list default value assigning
  const [GrandTotal,setGrandTotal] = useState(0);
  let grandTotal = 0;
  useEffect(() => {
    getCostcenterList();
    getglList();
    getProjectcodeList();

    if (itemList.length > 0) {
      
      itemList.map((item, index) => {
       
        grandTotal = grandTotal + Number(item.itemTotalPrice);
        setGrandTotal(grandTotal);
        setValue(
          `item_list[${index}].itemMappedToken`,
          item.itemMappedToken || ""
        );
        setValue(`item_list[${index}].itemCode`, item.itemCode || "");
        setValue(`item_list[${index}].itemName`, item.itemName || "");
        setValue(`item_list[${index}].itemQuantity`, item.itemQuantity || "");
        setValue(`item_list[${index}].itemPrice`, item.itemPrice || "");
        setValue(
          `item_list[${index}].itemDescription`,
          item.itemDescription || ""
        );
        setValue(
          `item_list[${index}].itemUnitPrice`,
          item.itemUnitPrice || ""
        );
        setValue(
          `item_list[${index}].itemTotalPrice`,
          item.itemTotalPrice || ""
        );
        setValue(
          `item_list[${index}].itemCostCenterCode`,
          item.itemCostCenterCode || ""
        );
        setValue(
          `item_list[${index}].itemGl_code`,
          item.itemGl_code || ""
        );
        setValue(
          `item_list[${index}].itemProject_code`,
          item.itemProject_code || ""
        );
        setValue(
          `item_list[${index}].itemCurrencyType`,
          item.itemCurrencyType || ""
        )
        setValue(
          `item_list[${index}].itemTax`,
          item.itemTax || ""
        )
        setValue(`item_list[${index}].itemPartNumber`, item.itemPartNumber || "");
      });
    }
  }, [itemList, setValue]);

  //country select side effect

  const [tax,setTax] = useState([]);
  useEffect(() => {
    const selectedCountry = shippingCountry;
    if (!selectedCountry) return;
    setCountry((prev) => ({
      ...prev,
      value: selectedCountry,
      isValid: validateSelect("Select", selectedCountry),
    }));
    setStateName({
      value: "",
      label: "",
      stateList: [],
      isValid: true,
    });
    setCity({
      value: "",
      label: "",
      cityList: [],
      isValid: true,
    });
    setstateToken();

    let filterObj =
      selectedCountry &&
      country.countryList?.filter((el) => el.name === selectedCountry);
    if (filterObj.length === 0) return;
    setCountryToken(filterObj[0]?.token);
    selectedCountry && getStatesList(filterObj[0]?.token);
  }, [shippingCountry, country.countryList]);
  //state select side effect
  useEffect(() => {
    const selectState = shippingState;
    if (!selectState) return;
    setStateName((prev) => ({
      ...prev,
      value: selectState,
      isValid: validateSelect("Select", selectState),
    }));
    setCity({
      value: "",
      label: "",
      cityList: [],
      isValid: true,
    });
    let filterObj =
      selectState &&
      stateName.stateList?.filter((el) => el.name === selectState);
    setstateToken(filterObj[0]?.token);
    selectState && getCityList(filterObj[0]?.token);
  }, [shippingState, stateName.stateList]);
  //city select side effect
  useEffect(() => {
    let selectedCity = shippingCity;
    setCity((prev) => ({
      ...prev,
      value: selectedCity,
      isValid: validateSelect("Select", selectedCity),
    }));
  }, [shippingCity]);
  //generate PDF
  useEffect(() => {
    if (generateViewPdfFlag) {
      generateViewPdf();
    }
  }, [generateViewPdfFlag, viewPO]);
  useEffect(() => {
    if (generateDownloadPdfFlag) {
      generateDownloadPOPdf();
    }
  }, [generateDownloadPdfFlag]);
  // send PO with PDF
  useEffect(() => {
    if (generateSendPdfFlag) {
      SendPO();
    }
  }, [generateSendPdfFlag]);
  useEffect(
    (_) => {
      if (poData && poData.length === 0) {
        setTableProps((pre) => ({
          ...pre,
          errorMessage: "No data found.",
        }));
      }
    },
    [poData]
  );

  const onLocaleStoreg = (data) => {
    localStorage.setItem(
      "po_page_number",
      `${data === "prev" ? parseInt(pageNumber) - 1 : parseInt(pageNumber) + 1}`
    );
  };
  useEffect(() => {
    // Clear the local storage value when the page is reloaded
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("po_page_number", 1);
    });
  }, []);

  //Global variables
  //table header
  // currency_type
  const headings = [
    { label: "RFQ Code", field: "rfq_code" },
    { label: "PO Number", field: "PO_number" },
    { label: "PO Date", field: "purchase_order_date" },
    { label: "Item Name", field: "name_list" },
    { label: "Grand Total", field: "item_price" },
    { label: "Vendor Name", field: "vendor_name" },
    // { label: "Source", field: "source" },
  ];
  if (loginData.access_type === "User") {
    headings.push({ label: "Approval Status", field: "app_status" });
  }
  if (loginData.access_type === "Buyer") {
    headings.push({ label: "Approval Status", field: "final_status" });
  }
  if (loginData.access_type === "Buyer") {
    headings.push({ label: "Comments", field: "comments" });
  }
  if (loginData.access_type === "User") {
    headings.push({ label: "Comments", field: "comments" });
  }
  // helper variables
  const itemsPerPage = paginationData?.current_page_count;
  const totalItems = paginationData?.page_count;
  const { PO_number, PO_token } = viewPO;
  // functions
  function openPOCancel() {
    reset();
    setPoEdit(false);
    setOpen(false);
  }
  async function openViewPo(token, row) {
    console.log("openViewPo");
    if (!row) return;
    setViewPo(row);
    if(data.access_type == "User"){
      setGenerateViewPdfFlag(false)
      setOpenViewItemPopup(true);
    }else{
      setGenerateViewPdfFlag(true);
      setOpenPoPdfModal(true);
    }

    setUniqueData(row.comp_approve_status)
  }

  const viewUserChildren = [
    uniqueData?.length > 0 && uniqueData.map((data, index) => (
    <div key={index}>
    <InfoLabel
    size={"44%"}
    label={"email"}
    value={data?.account_id__email}
    />,
    <InfoLabel
      size={"44%"}
      label={"app_status"}
      value={data?.app_status}
    />,
    <InfoLabel
      size={"44%"}
      label={"sequence"}
      value={data?.sequence}
    />,
    </div>
  ))
    // <InfoLabel
    //     size={"44%"}
    //     label={"Users"}
        // value={
        //   uniqueData?.users ? (
        //     <ul>
        //       {uniqueData.users.map((user, index) => (
        //         <li key={index}>{user.email}</li>
        //       ))}
        //     </ul>
        //   ) : (
        //     "-"
        //   )
        // }
      // />,
        // <InfoLabel
        //   size={"44%"}
        //   label={"UNSPSC Code"}
        //   value={uniqueData?.unspsc_code}
        // />,
        // <InfoLabel size={"100%"} label={"HSN Code"} value={uniqueData?.hsn_code} />,
  ];

  const viewUserHeading = (
    <HeaderTitle
      titleText={
        "heirachy" || <MoreHoriz sx={{ fontSize: "30px" }} />
      }
      customclass={classes.VUpopupHeading}
    />
  );


  const handleCloseViewItemPopup = () => {
    setOpenViewItemPopup(false);
  };
  const viewItemsActionButtons = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseViewItemPopup}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label={"OK"}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      onClick={handleCloseViewItemPopup}
    />,
  ];


  const generateViewPdf = async () => {
    const input = pdfRef.current;

    // A4 size dimensions in points (assuming 96 DPI)
    const a4Width = 595.28;
    const a4Height = 841.89;
    const margin = 10;

    // First render the full content to a single canvas
    const fullCanvas = await html2canvas(input, {
      scale: 1,
    });

    const contentHeight = fullCanvas.height;
    const pageHeight = a4Height - margin * 2;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    let remainingHeight = contentHeight;
    let position = 0;

    while (remainingHeight > 0) {
      const canvasHeight = Math.min(remainingHeight, pageHeight);
      const canvas = await html2canvas(input, {
        scale: 2,
        y: position,
        height: canvasHeight,
      });

      const pageImgData = canvas.toDataURL("image/JPEG");
      pdf.addImage(
        pageImgData,
        "JPEG",
        margin,
        margin,
        a4Width - margin * 2,
        canvasHeight
      );

      remainingHeight -= canvasHeight;
      position += canvasHeight;

      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    // Output the PDF as a blob
    const pdfBlob = pdf.output("blob");

    // Set PDF data for preview
    setPdfData(URL.createObjectURL(pdfBlob));
    setGenerateViewPdfFlag(false); // Reset the flag
  };
  const downloadPOPdf = async (token, row) => {
    setViewPo(row);
    setGenerateDownloadPdfFlag(true);
  };
  const generateDownloadPOPdf = async () => {
    const input = pdfRef.current;

    // A4 size dimensions in points (assuming 96 DPI)
    const a4Width = 595.28;
    const a4Height = 841.89;
    const margin = 10;

    // First render the full content to a single canvas
    const fullCanvas = await html2canvas(input, { scale: 1 });

    const contentHeight = fullCanvas.height;
    const pageHeight = a4Height - margin * 2;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    let remainingHeight = contentHeight;
    let position = 0;

    while (remainingHeight > 0) {
      const canvasHeight = Math.min(remainingHeight, pageHeight);
      const canvas = await html2canvas(input, {
        scale: 2,
        y: position,
        height: canvasHeight,
      });

      const pageImgData = canvas.toDataURL("image/JPEG"); // Use JPEG format with 0.5 compression factor
      pdf.addImage(
        pageImgData,
        "JPEG",
        margin,
        margin,
        a4Width - margin * 2,
        canvasHeight
      );

      remainingHeight -= canvasHeight;
      position += canvasHeight;

      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    // Convert to Blob
    const pdfBlob = pdf.output("blob");

    // Load the existing PDF into pdf-lib
    const existingPdfBytes = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Optionally, you can remove metadata, compress images, etc.
    pdfDoc.setTitle("Compressed PDF");
    pdfDoc.setAuthor("Your Name");

    // Save the compressed PDF
    const compressedPdfBytes = await pdfDoc.save({
      useObjectStreams: false, // Disable object streams for better compression
      updateFieldAppearances: false, // Reduce the file size by not updating appearances
    });

    // Convert the compressed PDF bytes to a Blob
    const compressedPdfBlob = new Blob([compressedPdfBytes], {
      type: "application/pdf",
    });

    // Trigger the download
    saveAs(compressedPdfBlob, `PO_#${PO_number}.pdf`);
    setGenerateDownloadPdfFlag(false);
  };


  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    setComments(value); 
    setValue("comments", value); 
  };
  const [u_price,setu_price] = useState([]);
  const [item_quantity,setitem_quantity] = useState([]);
  const [testt,settestt] = useState([]);
  const [quant,setquant] = useState([]);
  const [itemunit_price,setitemunit_price] = useState([]);
  const [loadingStateDwnload, setLoadingStateDwnload] = useState(false);
  
  useEffect(() => {
    const quantities = itemList.map(item => ({quantity:item.itemQuantity, token: item.itemMappedToken}));
    setitem_quantity(quantities);
  }, [itemList]);
  useEffect(() => {
    const Unitt = itemList.map(item => ({itemUnitPrice:item.itemUnitPrice, token: item.itemMappedToken}));
    setitemunit_price(Unitt);
  }, [itemList]);

  function openUpdatePo(token, row) {
    let {
      issuer_details,
      vendor_details,
      billing_details,
      shipping_details,
      terms,
      item_list,
      approve_info,
      next_app,
      pre_app,
      pricing,
      unit_price,
      final_status,
      approval_count,
      current_status
    } = row;
    const approveData ={
      appStatus : approve_info?.app_status,
      organization_id: approve_info?.organization_id,
      purchase_order_id:approve_info?.purchase_order_id
    };
    const unitprice ={
      unitPrice : unit_price===null ? 0 : unit_price 
    }
    const pricingData ={
      pricingId : pricing?.vb_id
    }
    const currentStatus ={
      currentAppstatus : current_status?.app_status,
      currentAppemail : current_status?.account_id__email,
      currentAppName : current_status?.account_id__org_id__first_name,
      currentAppComment : current_status?.comment,
      re_generate : current_status?.re_generate
    }

    const nextApp ={
      nextappEmail: next_app?.account_id__email,
      nextappStatus: next_app?.app_status,
      nextappName: next_app?.account_id__org__first_name
    }
    const preApp ={
      preappEmail: pre_app?.account_id__email,
      preappName: pre_app?.account_id__org__first_name,
      preappStatus: pre_app?.app_status
    }
    const issuer = {
      issuerName: issuer_details.issuer_details_name,
      issuerMobileNumber: issuer_details.issuer_details_phone_number,
      issuerAddressLine1:
        issuer_details.issuer_details_address.buyer_address_line,
      // issuerAddressLine2: "",
      issuerCountry: issuer_details.issuer_details_address.buyer_country,
      issuerState: issuer_details.issuer_details_address.buyer_state,
      issuerCity: issuer_details.issuer_details_address.buyer_city,
      issuerPostalCode: issuer_details.issuer_details_address.buyer_pincode,
      issuerEmail: issuer_details.issuer_details_email,
      issuerPan: issuer_details.issuer_details_PAN,
      issuerGSTIn: issuer_details.issuer_details_GSTIN,
      issuerCINNo: issuer_details.issuer_details_CIN,
      issuerPurchaseOrderNumber:
        issuer_details.issuer_details_purchase_order_no,
      // issuerPurchaseOrderDate:
      //   issuer_details.issuer_details_purchase_order_date,

      issuerPurchaseOrderDate: moment(issuer_details.issuer_details_purchase_order_date).format('DD/MM/YYYY'), // Format as dd/mm/yyyy

    };
    const vendor = {
      vendorName: vendor_details.vendor_details_name,
      vendorMobileNumber: vendor_details.vendor_details_phone_number,
      vendorAddressLine1:
        vendor_details.vendor_details_address.vendor_company_address,
      vendorAddressLine2: "",
      vendorCountry: vendor_details.vendor_details_address.vendor_country,
      vendorState: vendor_details.vendor_details_address.vendor_state,
      vendorCity: vendor_details.vendor_details_address.vendor_city,
      vendorPostalCode:
        vendor_details.vendor_details_address.vendor_postal_code,
    }
    const billing = {
      billingName: billing_details.billing_details_name,
      billingMobileNumber: billing_details.billing_details_phone_number,
      billingAddressLine1:
        billing_details.billing_details_address.buyer_address_line,
      // billingAddressLine2: "",
      billingCountry: billing_details.billing_details_address.buyer_country,
      billingState: billing_details.billing_details_address.buyer_state,
      billingCity: billing_details.billing_details_address.buyer_city,
      billingPostalCode: billing_details.billing_details_address.buyer_pincode,
    };
    const shipping = {
      shippingName: shipping_details.shipping_details_name,
      shippingMobileNumber: shipping_details.shipping_details_phone_number,
      shippingAddressLine1:
        shipping_details.shipping_details_address.shipping_address1,
      shippingAddressLine2:
        shipping_details.shipping_details_address.shipping_address2,
      shippingCountry:
        shipping_details.shipping_details_address.shipping_country,
      shippingState: shipping_details.shipping_details_address.shipping_state,
      shippingCity: shipping_details.shipping_details_address.shipping_city,
      shippingPostalCode:
        shipping_details.shipping_details_address.shipping_pincode,
    };
    const items = item_list?.map((item) => {
      return {
        itemMappedToken: item.item_mapped_token,
        itemCode: item.item_code,
        itemName: item.item_name,
        itemQuantity: item.quantity,
        itemPrice: row.item_price,
        itemDescription: item.description,
        itemUnitPrice: item.item_rfqunit_prices,
        itemTotalPrice :item.item_rfqtotal_prices,
        itemCostCenterCode :item.costcenter_code,
        itemGl_code :item.gl_code,
        itemProject_code:item.project_code,
        itemCurrencyType :item.currency_type,
        itemPartNumber :item.part_number,
        itemTax :item.tax
        
      };
    });
    items.length > 0 && setItemList(items);
    const others = {
      paymentTerms: terms.payment_terms,
      validity: terms.validity,
      deliveryTerms: terms.delivery_terms,
      modeOfTransportation: terms.mode_of_transportation,
      terms_and_condition: terms.terms_and_conditions_of_purchase,
    };

    const finalFormData = {
      ...issuer,
      ...vendor,
      ...billing,
      ...shipping,
      ...others,
      ...approveData,
      ...nextApp,
      ...preApp,
      ...pricingData,
      ...currentStatus,
      ...unitprice,
      poToken: row.PO_token,
      approveCount:row.approval_count,
      finalStatus:row.final_status,
      rfqToken: row.rfq_token,
      comments: comments,
      deliveryDate:row.delivery_date,
      paymentDate:row.payment_date
    };

    Object.keys(finalFormData).forEach((key) => {
      setValue(key, finalFormData[key]);
    });
    setSelectedPo({ ...finalFormData });
    setPoEdit(true);
    getCountriesList();
    getCostcenterList();
    if(loginData.access_type!=="Buyer" && finalFormData.preappName!=undefined && finalFormData.preappStatus==1){
      setPrimaryBtnError({
        error: true,
        // errorMsg: (<>Rejected by <strong>{finalFormData.preappName}</strong>.</>),
        errorMsg: (<div style={{ width: 'max-content'}}>
          <StatusMessage preAppStatus={finalFormData.preappStatus} preAppName={finalFormData.preappName}/></div>),
      });
    }else
    if(loginData.access_type!=="Buyer" && finalFormData.nextappEmail!=undefined && finalFormData.nextappStatus==1){
      setPrimaryBtnError({
        error: true,
        // errorMsg: (<>Rejected by <strong>{finalFormData.nextappName}</strong>.</>),
        errorMsg: (<div style={{width: 'max-content' }}>
          <StatusMessage nextAppStatus={finalFormData.nextappStatus} nextAppName={finalFormData.nextappName}/></div>),
      });
    }else
    if(loginData.access_type!=="Buyer" && finalFormData.preappName!=undefined && finalFormData.preappStatus==null){
      setPrimaryBtnError({
        error: true,
        // errorMsg:(<> Waiting for the previous level user <strong style={{ fontSize: '18px' }}>{finalFormData.preappName}</strong> to give their comments</>),
        errorMsg: (<div style={{width: 'max-content' }}>
          <StatusMessage preAppStatus={finalFormData.preappStatus} preAppName={finalFormData.preappName}/></div>),
      });
    } 
    if(loginData.access_type!=="Buyer" && finalFormData.preappName!=undefined && finalFormData.preappStatus==null /* || finalFormData.preappStatus==undefined) */){
      setPrimaryBtnError({
        error: true,
        // errorMsg:(<> Waiting for the previous level user <strong style={{ fontSize: '18px' }}>{finalFormData.preappName}</strong> to give their comments</>),
        errorMsg: (<div style={{width: 'max-content' }}><StatusMessage preAppStatus={finalFormData.preappStatus} preAppName={finalFormData.preappName} currentStatus={finalFormData.currentAppstatus} currentName={finalFormData.currentAppName} reGenerate={finalFormData.re_generate}/></div>),
      });
    }
    if(loginData.access_type!=="Buyer" && finalFormData.preappName==undefined && finalFormData.preappStatus==undefined ){
      setPrimaryBtnError({
        error: true,
        // errorMsg:(<> Waiting for the previous level user <strong style={{ fontSize: '18px' }}>{finalFormData.preappName}</strong> to give their comments</>),
        errorMsg: (<div style={{width: 'max-content' }}><StatusMessage preAppStatus={finalFormData.preappStatus} preAppName={finalFormData.preappName} currentStatus={finalFormData.currentAppstatus} currentName={finalFormData.currentAppName} reGenerate={finalFormData.re_generate} appStatus={finalFormData.appStatus}/></div>),
      });
    }
    if(loginData.access_type!=="User" && finalFormData.approveCount!==0){
      setPrimaryBtnError({
        error: true,
        errorMsg:(<><p style={{ color: '#ffc107', width:'max-content'}}><strong>Waiting for the approval</strong></p></>),
      });
    }
     if(loginData.access_type!=="User" && finalFormData.finalStatus==="completed"){
      setPrimaryBtnError({
        error: true,
        errorMsg:(<><p style={{ color: 'green'}}><strong>Approved</strong></p></>),
      });
    }
     if(loginData.access_type!=="User" && finalFormData.finalStatus==="in-progress" && finalFormData.currentAppemail!=undefined && finalFormData.currentAppstatus == 2  ){
      setPrimaryBtnError({
        error: true,
        errorMsg:(<><p style={{color: 'green', width:'max-content'}}><strong>Approved by {finalFormData.currentAppName}</strong> </p></>),
      });
    }
     if(loginData.access_type!=="User" && finalFormData.finalStatus==="in-progress" && finalFormData.currentAppemail!=undefined && finalFormData.currentAppstatus == 1 && finalFormData.re_generate!== 1  ){
      setPrimaryBtnError({
        error: true,
        errorMsg:(<><p style={{  marginTop: '3px', marginRight: '25px', color: 'red',width:'max-content'}}><strong>Rejected by {finalFormData.currentAppName}</strong> </p></>),
      });
    }
  }

  useEffect(() => {
    if (selectedPo.deliveryDate) {
      setDdate({
        value: selectedPo.deliveryDate,
        isValid: true,
      });
    }
    if (selectedPo.paymentDate) {
      setPaydate({
        value: selectedPo.paymentDate,
        isValid: true,
      });
    }
  }, [selectedPo.deliveryDate, selectedPo.paymentDate]);

  const approveStatus = selectedPo.appStatus
  const orgId = selectedPo.organization_id
  const purchaseId = selectedPo.purchase_order_id
  // const approveStatus = '2'
  function handleOpenDltConfirmation(deletionId, row) {
    setOpenDltConfirmation(true);
    setDeletionPo(row);
  }
  function handleCloseDltConfirmation() {
    setOpenDltConfirmation(false);
  }
  function handleDeletePO() {
    let poToken = deletionPo.PO_token;
    setIsPoDeleting(true);
    axios({
      method: "POST",
      url: API_ENDPOINT.detele_purchase_order,
      data: { PO_token: poToken },
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.response === "Purchase_order is deleted successfully") {
          setOpenDltConfirmation(false);
        }
        setIsPoDeleting(false);
      })
      .catch((error) => {
        setIsPoDeleting(false);
      });
  }
  function sendPoEmail(token, row) {
    if (!row) return;
    setViewPo(row);
    setGenerateSendPdfFlag(true);
  }
  const SendPO = async () => {
    const input = pdfRef.current;

    // A4 size dimensions in points (assuming 96 DPI)
    const a4Width = 595.28;
    const a4Height = 841.89;
    const margin = 10;

    // First render the full content to a single canvas
    const fullCanvas = await html2canvas(input, {
      scale: 1,
    });

    const contentHeight = fullCanvas.height;
    const pageHeight = a4Height - margin * 2;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    let remainingHeight = contentHeight;
    let position = 0;

    while (remainingHeight > 0) {
      const canvasHeight = Math.min(remainingHeight, pageHeight);
      const canvas = await html2canvas(input, {
        scale: 2,
        y: position,
        height: canvasHeight,
      });

      const pageImgData = canvas.toDataURL("image/JPEG");
      pdf.addImage(
        pageImgData,
        "JPEG",
        margin,
        margin,
        a4Width - margin * 2,
        canvasHeight
      );

      remainingHeight -= canvasHeight;
      position += canvasHeight;

      if (remainingHeight > 0) {
        pdf.addPage();
      }
    }

    // Convert to Blob
    const pdfBlob = pdf.output("blob");
    const file = new File([pdfBlob], "purchase_order.pdf", {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    });
    const formData = new FormData();
    formData.append("PO_token", PO_token);
    formData.append("PDF_file", file);

    axios({
      method: "POST",
      url: API_ENDPOINT.send_email_purchase_order,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.response === "mail send successfully") {
          setOpen(true);
          setSuccessModalContent({
            title: "Purchase Order Mailed",
            description:
              "The Purchase Order has been successfully sent to the vendor.",
          });
        }
      })
      .catch((error) => {
        setGenerateSendPdfFlag(false);
      });
    setGenerateSendPdfFlag(false);
  };
  const GetAllPoData = (pageNumber, rfq_code) => {
    useFetchData({
      pageNumber: pageNumber,
      endpoint: `${API_ENDPOINT.get_purchase_order_list}`,
      idParams: idParams,
      searchQuery: searchQuery,
      auth_token: auth_token,
      ...(sortingColumnName && { sortingColumnName: sortingColumnName }),
      ...(sortingOrder && { sortingOrder: sortingOrder }),
      setData: setPoData,
      setPaginationData: setPaginationData,
      tableProps,
      setTableProps,
    });
  };

  // const validateFilters = () => {
  //   const isDate = emptyCheck(ddate?.value);

  //   setDdate((prev) => ({ ...prev, isValid: isDate }));
  //   return isfromDate;
  // };

  const onSubmit = async (data, status) => {
    if (!ddate?.value || !emptyCheck(ddate.value)) {
      setDdate({
        ...ddate,
        isValid: false, 
      });
      return; 
    }
    if (!paydate?.value || !emptyCheck(paydate.value)) {
      setPaydate({
        ...paydate,
        isValid: false, 
      });
      return; 
    }
    setIsUpdating(true);
    let itemList = [];
    if (data.item_list.length > 0) {
      if (data.item_list.length == 1 ){
        const itemQty = Number(data.item_list[0].itemTotalPrice)
        console.log('itemQty', itemQty)
        if (itemQty === 0){
          setOpenRstConfirmation(true)
          setIsUpdating(false)
          return;
        }
        
      }
    
      itemList = data.item_list?.map((item,index) => {
        return {
          itemMappedToken: item.itemMappedToken,
          itemQuantity: item.itemQuantity,
          itemCode: item.itemCode,
          itemName: item.itemName,
          // unitPrice: data.unitPrice,
          itemUnitPrice:item.itemUnitPrice,
          itemDescription: item.itemDescription,
          itemPrice: item.itemPrice,
          itemrfqtotal_prices:u_price[index],
          itemCostCenterCode:item.itemCostCenterCode,
          itemGl_code:item.itemGl_code,
          itemProject_code:item.itemProject_code,
          itemTax :item.itemTax

        };
      });
    }
    let formData = {
      PO_token: data.poToken,
      payment_terms: data.paymentTerms,
      validity: data.validity,
      mode_of_transportation: data.modeOfTransportation,
      delivery_terms: data.deliveryTerms,
      terms_and_conditions_of_purchase: data.terms_and_condition,
      shipping_address1: data.shippingAddressLine1,
      shipping_address2: data.shippingAddressLine2,
      shipping_country: data.shippingCountry,
      shipping_state: data.shippingState,
      shipping_city: data.shippingCity,
      shipping_pincode: data.shippingPostalCode,
      shipping_mobile: data.shippingMobileNumber,
      item_List: itemList,
      vb_id:data.pricingId,
      approve_mode:status,
      delivery_date: ddate.value,
      payment_date: paydate.value
      // approve_status: '2'
      // item_token_list: itemList,
    };

    axios({
      method: "POST",
      url: API_ENDPOINT.update_purchase_order,
      data: formData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.response === "purchase_order updated") {
          setOpen(true);
          setSuccessModalContent({
            title: "Purchase Order Saved",
            description: "Purchase order updated successfully.",
          });
        }
        if (res.data.response === "save_sent_for_approval") {
          setOpen(true);
          setSuccessModalContent({
            title: "Purchase Order Updated",
            description: "Purchase order updated successfully, and sent for approval.",
          });
        }
        setBtnError({
          error: false,
          errorMsg: ""
        })
        setIsUpdating(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsUpdating(false);
      });
    setIsUpdating(true);

  };
  function handleCloseRstConfirmation() {
    setOpenRstConfirmation(false);
  }

  // const pdfUrlWithZoom = `${pdfData}#zoom=43`;

  const [Totalprice_list,setTotalprice_list] = useState(false);
  const onUserSubmit = async (data, status) => {
    setIsUpdating(true);
    let itemList = [];
    if (data?.item_list?.length > 0) {
      itemList = data.item_list?.map((item) => {
        return {
          itemMappedToken: item.itemMappedToken,
          itemQuantity: item.itemQuantity,
        };
      });
    }

    let UserformData = {
      user_token: buyer_token,
      organization_id: orgId,
      purchase_order_id: purchaseId,
      status: status,
      comment:data.comments,
      rfq_token:data.rfqToken,
    };

    axios({
      method: "POST",
      url: API_ENDPOINT.hierarchy_approval,
      data: UserformData,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((res) => {
        if (res.data.message === "Approval already completed.") {
          setOpen(true);
          setSuccessModalContent({
            title: "Approval Status",
            description: "Approval already completed.",
          });
        }else if(res.data.message === "Approval accepted."){
          setOpen(true);
          setSuccessModalContent({
            title: "Approval Status",
            description: "Approval accepted successfully.",
          });
        }else if(res.data.message === "Approval rejected."){
          setOpen(true);
          setSuccessModalContent({
            title: "Approval Status",
            description: "Approval rejected.",
          });
        }else if(res.data.status === "prev_wait"){
          setOpen(true);
          setSuccessModalContent({
            title: "Approval Status",
            description: res.data.message,
          });
        }
        setIsUpdating(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsUpdating(false);
      });
      setIsUpdating(true)
  };


  const onError = (error) => {
    console.log(error);
  };
  // get costcentercode
  const getCostcenterList = async () => {
    axios({
      method: "POST",
      url: API_ENDPOINT.cost_center_list,
      data: {
        buyer_token, 
      },     })
      .then((res) => {
        // console.log("testtttt",res?.data?.data)
        setcostcenter((prev) => ({
          ...prev,
          cc_codeList: res?.data?.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log("costcentercostcenter",costcenter)

  const getglList = async () => {
    axios({
      method: "POST",
      url: API_ENDPOINT.gl_code_list,
      data:{
        buyer_token
      }
    })
      .then((res) => {
        // console.log("testtttt",res?.data?.data)
        setglcode((prev) => ({
          ...prev,
          gl_codeList: res?.data?.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProjectcodeList = async () => {
    axios({
      method: "POST",
      url: API_ENDPOINT.projects_list,
      data:{
        buyer_token
      }
    })
      .then((res) => {
        // console.log("projectcode",res?.data?.data)
        setprojectcode((prev) => ({
          ...prev,
          project_codeList: res?.data?.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // coutry || state || city API call
  const getCountriesList = async () => {
    axios({
      method: "POST",
      url: API_ENDPOINT.view_countries,
    })
      .then((res) => {
        setCountry((prev) => ({
          ...prev,
          countryList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStatesList = async (country_token) => {
    if (!country_token) return;
    axios({
      method: "POST",
      url: API_ENDPOINT.view_state,
      data: {
        country_token: country_token,
      },
    })
      .then((res) => {
        setStateName((prev) => ({
          ...prev,
          stateList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCityList = async (state_token) => {
    if (!state_token) return;
    axios({
      method: "POST",
      url: API_ENDPOINT.view_cities,
      data: {
        state_token: state_token,
      },
    })
      .then((res) => {
        setCity((prev) => ({
          ...prev,
          cityList: res?.data?.response,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={classes.users_tab_wrapper}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={generateDownloadPdfFlag || generateSendPdfFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.users_topHeader}>
        <div className={`${classes.lhs} flex`}>
          <HeaderTitle titleText= {data.access_type == 'User' ? "Purchase Order": "Purchase Order"} customclass={classes.dashHead} />
        </div>

        <div className={`${classes.rhs} flex`}>
          <DateBar />
          <NotificationBell />
          <ProfileCard
            buyerBtn={true}
            buyerBtnOutline={true}
            profilePath={"/Buyer_dashboard/My_profile"}
          />
        </div>
      </div>
      <div className={classes.poSection}>
        {!poEdit ? (
            <div className={classes.poListRow}>
              
            <div className={classes.search_add_wrapper}>
              <div
                className={`${classes.flex} ${classes.flex_align_end}  ${classes.search_add_wrapper_content_left}`}
              >
                <DropdownBtnOutlined
                label="DOWNLOAD AS"
                btnHeight="4rem"
                btnWidth="18rem"
                loadingState={loadingStateDwnload}
                //disabled={RFQData?.length === 0}
                onClick={(fileFormat) => {
                  downloadFile({
                    key: "buyer_token",
                    value: buyer_token,
                    fileNameProp: "Purchase Order",
                    outputFormat: fileFormat,
                    API_ENDPOINT: API_ENDPOINT.purchase_order_csv,
                    authToken: auth_token,
                    setLoadingState: setLoadingStateDwnload,
                    rfqData: true
                  });
                }}
                options={["CSV", "PDF"]}
              />
                <SearchBar
                  value={searchQuery}
                  onChange={(query) => {
                    setSearchQuery(query);
                    setPageNumber(1);
                  }}
                />
              </div>
            </div>
            <div className={`${classes.d_dataTable} `}>
              <DataTable
                headings={headings}
                tableData={poData}
                tableSize={poData?.length === 0 ? "100%" : "100vw"}
                actions={true}
                errorMessage={tableProps.errorMessage}
                isLoading={tableProps.isLoading}
                viewBtn={true}
                editBtn={true}
                deleteBtn={true}
                sorting={true}
                downloadSendmailForPo={true}
                sendMail={true}
                Download={true}
                viewClick={openViewPo}
                editClick={openUpdatePo}
                deleteClick={handleOpenDltConfirmation}
                downloadClick={downloadPOPdf}
                handleClickSendPoEmail={sendPoEmail}
                // setSortingColumnName={() => {
                //   return null;
                // }}
                // sortingColumnName={null}
                // setSortingOrder={() => {
                //   return null;
                // }}
                // sortingOrder={null}
                setSortingColumnName={setSortingColumnName}
                sortingColumnName={sortingColumnName}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />

              <PaginantionComponent
                pageNumber={pageNumber}
                currentPage={currentPage || 0}
                itemsPerPage={itemsPerPage || 0}
                totalItems={totalItems || 0}
                previouPageClick={() => {
                  previouPageClick(pageNumber, setPageNumber);
                  onLocaleStoreg("prev");
                }}
                localStorageKey={"po_page_number"}
                nextPageClick={() => {
                  nextPageClick(pageNumber, setPageNumber, paginationData);
                  onLocaleStoreg("next");
                }}
                setPageNumber={setPageNumber}
                disablePrevious={
                  parseInt(pageNumber) === 1 || pageNumber === ""
                }
                disableNext={
                  parseInt(pageNumber) === paginationData?.page_count ||
                  !paginationData ||
                  pageNumber === ""
                }
              />
            </div>
            {openForm.errorMessage ? (
              <div className={` ${classes.formErrorWrap} ${classes.flex}`}>
                <p className={classes.infoText}> {openForm.errorMessage} </p>
              </div>
            ) : (
              !openForm.open &&
              openForm.loading && (
                <div className={classes.tabsScreens}>
                  <span className={classes.loader}>
                    <CircularProgress
                      thickness={6}
                      variant="indeterminate"
                      disableShrink
                      sx={{
                        animationDuration: "350ms",
                        color: "#55555580",
                      }}
                      size={25}
                    />
                  </span>
                </div>
              )
            )}
          </div>
        ) : (
          <div className={classes.poEditRow}>
            <DButton
              label={"BACK"}
              btnHeight="4rem"
              btnWidth={"12rem"}
              buyerBtn={true}
              onClick={() => {
                reset();
                setTotalprice_list(false);
                // setu_price([]);
                settestt([]);
                setquant([]);
                setPoEdit(false);
                setPrimaryBtnError({
                  error: false,
                  errorMsg:'',
                });
                setBtnError({
                  error: false,
                  errorMsg: ""
                });
                // setDdate({
                //   value:"",
                //   isValid:true
                // })
                // setPaydate({
                //   value:"",
                //   isValid:true
                // })
              }}
            />
            <div className={classes.tabsScreens}>
              <div className={classes.infoSection}>
                <HeaderTitle
                  customclass={`${classes.tabHeading}`}
                  titleText={data.access_type == 'User' ? "Draft Purchase Order" : selectedPo.finalStatus ==="completed" ?  "PO" : "Draft PO"}
                />
                <div className={classes.poFormWrapper}>
                  <form onSubmit={handleSubmit(onSubmit,onUserSubmit, onError)}>
                    {/*Issuer details*/}
                    <h3 className={classes.subTitle}>Issuer details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <input
                        type="hidden"
                        name="poToken"
                        {...register("poToken")}
                      />
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="issuerName"
                        register={register}
                        {...register("issuerName", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="issuerMobileNumber"
                        {...register("issuerMobileNumber", {
                          // required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("issuerMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.issuerMobileNumber?.message}
                        value={issuerMobileNumber || ""}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="issuerAddressLine1"
                        register={register}
                        {...register("issuerAddressLine1", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerAddressLine1?.message}
                        disabled={true}
                      />
                      {/* <TextInput
                        type="text"
                        label="Address Line2"
                        placeholder="Enter address line2"
                        name="issuerAddressLine2"
                        register={register}
                        {...register("issuerAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerAddressLine2?.message}
                        disabled={true}
                      /> */}
                      <TextInput
                        type="text"
                        label="Country"
                        placeholder="Enter country"
                        name="issuerCountry"
                        register={register}
                        {...register("issuerCountry", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerCountry?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="State/Provine/Region"
                        placeholder="Enter country"
                        name="issuerState"
                        register={register}
                        {...register("issuerState", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerState?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="issuerCity"
                        register={register}
                        {...register("issuerCity", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerCity?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="issuerPostalCode"
                        register={register}
                        {...register("issuerPostalCode", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerPostalCode?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Email"
                        placeholder="Enter Email"
                        name="issuerEmail"
                        register={register}
                        {...register("issuerEmail", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerEmail?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Pan number"
                        placeholder="Enter pan number"
                        name="issuerPan"
                        register={register}
                        {...register("issuerPan", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerPan?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="GSTIN"
                        placeholder="Enter GSTIN"
                        name="issuerGSTIn"
                        register={register}
                        {...register("issuerGSTIn", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerGSTIn?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="CIN NO"
                        placeholder="Enter CIN NO"
                        name="issuerCINNo"
                        register={register}
                        {...register("issuerCINNo", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerCINNo?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Purchase order number"
                        placeholder="Enter purchase order number"
                        name="issuerPurchaseOrderNumber"
                        register={register}
                        {...register("issuerPurchaseOrderNumber", {
                          // required: "This field is required",
                        })}
                        errorMessage={
                          errors?.issuerPurchaseOrderNumber?.message
                        }
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Purchase order Date"
                        placeholder="Enter purchase order Date"
                        name="issuerPurchaseOrderDate"
                        register={register}
                        {...register("issuerPurchaseOrderDate", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.issuerPurchaseOrderDate?.message}
                        disabled={true}
                      />
                    </div>
                    {/*Issuer details*/}

                    {/*Vendor details*/}
                    <h3 className={classes.subTitle}>Vendor details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="vendorName"
                        register={register}
                        {...register("vendorName", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="vendorMobileNumber"
                        {...register("vendorMobileNumber", {
                          // required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("vendorMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.vendorMobileNumber?.message}
                        value={vendorMobileNumber || ""}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="vendorAddressLine1"
                        register={register}
                        {...register("vendorAddressLine1", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorAddressLine1?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line2"
                        placeholder="Enter address line2"
                        name="vendorAddressLine2"
                        register={register}
                        {...register("vendorAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorAddressLine2?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Country"
                        placeholder="Enter country"
                        name="vendorCountry"
                        register={register}
                        {...register("vendorCountry", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorCountry?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="State/Provine/Region"
                        placeholder="Enter country"
                        name="vendorState"
                        register={register}
                        {...register("vendorState", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorState?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="vendorCity"
                        register={register}
                        {...register("vendorCity", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorCity?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="vendorPostalCode"
                        register={register}
                        {...register("vendorPostalCode", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.vendorPostalCode?.message}
                        disabled={true}
                      />
                    </div>
                    {/*vendor details*/}

                    {/*Billing details*/}
                    <h3 className={classes.subTitle}>Billing details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="billingName"
                        register={register}
                        {...register("billingName", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="billingMobileNumber"
                        {...register("billingMobileNumber", {
                          // required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("billingMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.billingMobileNumber?.message}
                        value={billingMobileNumber || ""}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="billingAddressLine1"
                        register={register}
                        {...register("billingAddressLine1", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingAddressLine1?.message}
                        disabled={true}
                      />
                      {/* <TextInput
                        type="text"
                        label="Address Line2"
                        placeholder="Enter address line2"
                        name="billingAddressLine2"
                        register={register}
                        {...register("billingAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingAddressLine2?.message}
                        disabled={true}
                      /> */}
                      <TextInput
                        type="text"
                        label="Country"
                        placeholder="Enter country"
                        name="billingCountry"
                        register={register}
                        {...register("billingCountry", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingCountry?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="State/Provine/Region"
                        placeholder="Enter country"
                        name="billingState"
                        register={register}
                        {...register("billingState", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingState?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="City"
                        placeholder="Enter city"
                        name="billingCity"
                        register={register}
                        {...register("billingCity", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingCity?.message}
                        disabled={true}
                      />
                      <TextInput
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="billingPostalCode"
                        register={register}
                        {...register("billingPostalCode", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.billingPostalCode?.message}
                        disabled={true}
                      />
                    </div>
                    {/*Billing details*/}

                    {/*Shipping details*/}
                    <h3 className={classes.subTitle}>Shipping details</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        name="shippingName"
                        register={register}
                        {...register("shippingName", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.shippingName?.message}
                        disabled={true}
                      />
                      <PhoneNumberInput
                        label="Mobile Number"
                        name="shippingMobileNumber"
                        {...register("shippingMobileNumber", {
                          required: "This field is required",
                        })}
                        onChange={(
                          value,
                          data,
                          isValidMobileNumber,
                          formattedValue
                        ) => {
                          if (!isValidMobileNumber) {
                            setError("shippingMobileNumber", {
                              type: "custom",
                              message: "Invalid phone number",
                            });
                          } else {
                            clearErrors(["shippingMobileNumber"]);
                          }
                          // checking the value only for numbers
                          const numberOnlyRegex = /^[0-9]*$/;
                          if (value === "" || numberOnlyRegex.test(value)) {
                            setValue("shippingMobileNumber", formattedValue);
                          }
                        }}
                        errorMessage={errors.shippingMobileNumber?.message}
                        value={shippingMobileNumber || ""}
                        disabled={isUpdating || accessType=="User"}
                      />
                      <TextInput
                        type="text"
                        label="Address Line1"
                        placeholder="Enter address line1"
                        name="shippingAddressLine1"
                        register={register}
                        {...register("shippingAddressLine1", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.shippingAddressLine1?.message}
                        disabled={isUpdating || accessType=="User"}
                      />
                      <TextInput
                        type="text"
                        label="Address Line2 (Optional)"
                        placeholder="Enter address line2"
                        name="shippingAddressLine2"
                        register={register}
                        {...register("shippingAddressLine2", {
                          // required: "This field is required",
                        })}
                        errorMessage={errors?.shippingAddressLine2?.message}
                        disabled={isUpdating || accessType=="User"}
                      />

                      <LocationSelectDropDown
                        label="Country"
                        defaultDropDown={true}
                        value={shippingCountry}
                        noInit={true}
                        name="shippingCountry"
                        register={register}
                        {...register("shippingCountry", {
                          required: "This field is required",
                        })}
                        options={
                          country.countryList?.map((el) => el.name) || [
                            "Select",
                          ]
                        }
                        error={errors?.shippingCountry?.message}
                        errorMessage={errors?.shippingCountry?.message}
                        customClassName={classes.field}
                        disabled={isUpdating || accessType=="User"} // Added
                      />
                      <LocationSelectDropDown
                        label="State/Province/Region"
                        defaultDropDown={true}
                        value={shippingState}
                        disabled={!countryToken || isUpdating || accessType=="User"}
                        noInit={true}
                        name="shippingState"
                        register={register}
                        {...register("shippingState", {
                          required: "This field is required",
                        })}
                        options={
                          stateName.stateList.length >= 1
                            ? stateName.stateList?.map((el) => el.name)
                            : ["No states available"]
                        }
                        error={errors?.shippingState?.message}
                        errorMessage={errors?.shippingState?.message}
                        customClassName={classes.field}
                      />

                      <LocationSelectDropDown
                        label="City"
                        defaultDropDown={true}
                        value={shippingCity}
                        disabled={!stateToken || isUpdating || accessType=="User"}
                        noInit={true}
                        name="shippingCity"
                        register={register}
                        {...register("shippingCity", {
                          required: "This field is required",
                        })}
                        options={
                          city.cityList.length >= 1
                            ? city.cityList?.map((el) => el.name)
                            : ["No cities available"]
                        }
                        error={errors?.shippingCity?.message}
                        errorMessage={errors?.shippingCity?.message}
                        customClassName={classes.field}
                      />
                      <TextInput
                        maxLength={50}
                        type="text"
                        label="Postal/ZipCode"
                        placeholder="Enter postal/zipCode"
                        name="shippingPostalCode"
                        register={register}
                        {...register("shippingPostalCode", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Postal/ZipCode must be numeric",
                          },
                        })}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            charCode > 31 &&
                            (charCode < 48 || charCode > 57)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        errorMessage={errors?.shippingPostalCode?.message}
                        disabled={isUpdating || accessType=="User"}
                      />
                    </div>
                    {/*Shipping details*/}

                    {/*Item details*/}
                    <h3 className={classes.subTitle}>Item details</h3>

                    <div
                      className={`${classes.poForm} ${classes.borderBottom} ${classes.itemParent}`}
                    >
                      <div className={classes.tableScroll}>
                        <div className={classes.tableScroll2}> 
                      <table className={classes.itemTable}>
                        <thead>
                          <tr>
                            <th>Item Code</th>
                            <th>Item Name</th>
                            <th>Part Number</th>
                            <th>Cost Center Code</th>
                            <th>GL Code</th>
                            <th>Project Code</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Tax (%)</th>
                            <th>Description</th>
                            <th>Currency</th>
                            {itemList.length > 0 && <th>Total Price</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {itemList.map((item, index) => (
                            <tr key={index}>
                              <input
                                type="hidden"
                                name={`item_list[${index}].itemMappedToken`}
                                {...register(`item_list[${index}].itemMappedToken`)}
                              />
                              <td>
                                <TextInput
                                  type="text"
                                  label=""
                                  placeholder="Enter item code"
                                  name={`item_list[${index}].itemCode`}
                                  register={register}
                                  {...register(`item_list[${index}].itemCode`, {
                                    required: "This field is required",
                                  })}
                                  errorMessage={errors?.item_list?.[index]?.itemCode?.message}
                                  // disabled={isUpdating || accessType === "User"}
                                  disabled={true}
                                />
                              </td>
                              <td>
                                <TextInput
                                  type="text"
                                  label=""
                                  placeholder="Enter item name"
                                  name={`item_list[${index}].itemName`}
                                  register={register}
                                  {...register(`item_list[${index}].itemName`, {
                                    required: "This field is required",
                                  })}
                                  errorMessage={errors?.item_list?.[index]?.itemName?.message}
                                  // disabled={isUpdating || accessType === "User"}
                                  disabled={true}
                                />
                              </td>
                              <td>
                                <TextInput
                                  type="text"
                                  label=""
                                  placeholder="Enter Part Number"
                                  name={`item_list[${index}].itemPartNumber`}
                                  register={register}
                                  // {...register(`item_list[${index}].itemPartNumber`, {
                                  //   required: "This field is required",
                                  // })}
                                  // errorMessage={errors?.item_list?.[index]?.itemPartNumber?.message}
                                  // disabled={isUpdating || accessType === "User"}
                                  disabled={true}
                                />
                              </td>
                              <td>
                             

                               <LocationSelectDropDown
                                defaultDropDown={true}
                                // noInit={true}
                                name={`item_list[${index}].itemCostCenterCode`}
                                register={register}
                                  {...register(`item_list[${index}].itemCostCenterCode`, {
                                    required: "This field is required",
                                    
                                  })}
                                // options={
                                //   costcenter.cc_codeList?.map((el) => el.costcenter_code) || [
                                //     "Select",
                                //   ]
                                // }
                                options={
                                  [
                                    "Select",
                                    ...(costcenter.cc_codeList?.map((el) => el.costcenter_code) || [])
                                  ]}
                                error={errors?.item_list?.[index]?.itemCostCenterCode?.message}
                                errorMessage={errors?.item_list?.[index]?.itemCostCenterCode?.message}
                                // error={errors?.shippingCity?.message}
                                customClassName={classes.field}
                                // defaultValue={item.itemCostCenterCode || "Select"}
                                defaultValue={item.itemCostCenterCode === 0 ? "" : item.itemCostCenterCode }
                              />
                              </td>
                              <td>
                              <LocationSelectDropDown
                                defaultDropDown={true}
                                // noInit={true}
                                name={`item_list[${index}].itemGl_code`}
                                register={register}
                                  {...register(`item_list[${index}].itemGl_code`, {
                                    required: "This field is required",
                                  })}
                                // options={
                                //   glcode.gl_codeList?.map((el) => el.gl_code) || [
                                //     "Select",
                                //   ]
                                // }
                                options={
                                  [
                                    "Select",
                                    ...(glcode.gl_codeList?.map((el) => el.gl_code) || [])
                                  ]}
                                errorMessage={errors?.item_list?.[index]?.itemGl_code?.message}
                                error={errors?.item_list?.[index]?.itemGl_code}
                                customClassName={classes.field}
                                defaultValue={item.itemGl_code === 0 ? "" : item.itemGl_code }
                              />

                              </td>
                               <td>
                              <LocationSelectDropDown
                                defaultDropDown={true}
                                // noInit={true}
                                name={`item_list[${index}].itemProject_code`}
                                register={register}
                                  {...register(`item_list[${index}].itemProject_code`, {
                                    required: "This field is required",
                                  })}
                                // options={
                                //   projectcode.project_codeList?.map((el) => el.project_code) || [
                                //     "Select",
                                //   ]
                                // }
                                options={
                                  [
                                    "Select",
                                    ...(projectcode.project_codeList?.map((el) => el.project_code) || [])
                                  ]}
                                errorMessage={errors?.item_list?.[index]?.itemProject_code?.message}
                                error={errors?.item_list?.[index]?.itemProject_code}
                                customClassName={classes.field}
                                // defaultValue={item.itemProject_code || "Select"}
                                defaultValue={item.itemProject_code === 0 ? "" : item.itemProject_code}
                              />

                              </td>
                              <td>
                              <TextInput
                              type="number"
                              label=""
                              placeholder="Enter quantity"
                              name={`item_list[${index}].itemQuantity`}
                              register={register}
                              {...register(`item_list[${index}].itemQuantity`,{
                                required: "This field is required",
                              })}  
                              onChange={(e) =>{
                                const currentTax = parseFloat(getValues(`item_list[${index}].itemTax`)) || 0; 
                                console.log("itemunit_price",itemunit_price)
                                const newQuantity = parseInt(e.target.value, 10) || 0;
                                const updatedquatt = [...quant]; 
                                updatedquatt[index] = newQuantity;
                                setquant(updatedquatt); 
                                if (testt.length > 0){
                                  const ts = testt.map((t,index22) =>{
                                    console.log("wwwwwwww11111",t,newQuantity)
                                    if (index22 === index) {
                                      setTotalprice_list(true);
                                      setValue(`item_list[${index}].itemTotalPrice`, (t * newQuantity * currentTax/100) +(t * newQuantity ));
                                      // setValue(`item_list[${index}].itemTotalPrice`, t * newQuantity);
                                      clearErrors([`item_list[${index}].itemTotalPrice`]);
                                      // clearErrors([`item_list[${index}].itemUnitPrice`]);
                                      clearErrors([`item_list[${index}].itemQuantity`]);
                                      return (t * newQuantity * currentTax/100) + (t * newQuantity);
                                  }
                                  return u_price[index22];
                                  })
                                  setu_price(ts);
                                }

                                else if(itemunit_price.length > 0 && testt.length == 0 ){
                                  const ts = itemunit_price.map((t,index22) =>{
                                    if (index22 === index) {
                                      setTotalprice_list(true);
                                      console.log("wwwwwwww22222",t,newQuantity)

                                      const newunit_price  = t.itemUnitPrice * newQuantity
                                      setValue(`item_list[${index}].itemTotalPrice`, (newunit_price * currentTax/100) +(newunit_price));
                                      // setValue(`item_list[${index}].itemTotalPrice`, newunit_price);
                                      clearErrors([`item_list[${index}].itemTotalPrice`]);
                                      // clearErrors([`item_list[${index}].itemUnitPrice`]);
                                      clearErrors([`item_list[${index}].itemQuantity`]);
                                      // return newunit_price; 
                                      return (newunit_price * currentTax/100)+(newunit_price); 

                                  }
                                  return u_price[index22];
                                  })
                                  setu_price(ts);


                                }
                                const updatedQuantities = item_quantity.map((quantity, index1) => {
                                  const c_token = getValues(`item_list[${index}].itemMappedToken`)
                                    if (index1 === index && c_token === quantity.token) {
                                        return newQuantity;
                                    }
                                    return quantity; 
                                });

                                setitem_quantity(updatedQuantities);

                              }}
                              errorMessage={errors?.item_list?.[index]?.itemQuantity?.message}
                              disabled={isUpdating || accessType === "User"}
                            />
                              </td>
                            <td>
                              <TextInput
                              type="number"
                              label=""
                              placeholder="Enter unit price"
                              name={`item_list[${index}].itemUnitPrice`}
                              // name={`item_list[${index}].unitPrice`}
                              register={register}
                              {...register(`item_list[${index}].itemUnitPrice`,{
                                required: "This field is required",
                              })} // Ensure this matches the input's name
                              // unit={handleUnitprice(index)}
                              onChange = {(e)=>{
                                const currentTax = parseFloat(getValues(`item_list[${index}].itemTax`)) || 0;
                                const newValue = e.target.value;
                                // console.log("Index:", index); 
                                // console.log("Input changed:", newValue); 
                                const updatedTestt = [...testt]; 
                                updatedTestt[index] = newValue;
                                settestt(updatedTestt); 
                                if (quant.length > 0){
                                  const ts = quant.map((q,index22) =>{
                                    console.log("wwwddddd",q,newValue)
                                    if (index22 === index) {
                                      setTotalprice_list(true);
                                      setValue(`item_list[${index}].itemTotalPrice`, (q * newValue *currentTax/100)+ (q * newValue));
                                      // setValue(`item_list[${index}].itemTotalPrice`, q * newValue);
                                      clearErrors([`item_list[${index}].itemTotalPrice`]);
                                      clearErrors([`item_list[${index}].itemUnitPrice`]);
                                      // return q * newValue; 
                                      return (q * newValue * currentTax/100)+(q * newValue);
                                  }
                                  return u_price[index22];
                                  })
                                  setu_price(ts);
                                }
                                else if(item_quantity.length > 0) {
                                  const updatedUPrice = item_quantity.map((quantity, index1) => {
                                    // && quantity.tokken
                                    const current_token = getValues(`item_list[${index}].itemMappedToken`)
                                      if (index1 === index && current_token === quantity.token) {
                                        setTotalprice_list(true);
                                        console.log("quantityaa",quantity,newValue)
                                        const newTotalPrice = quantity.quantity * newValue;
                                        // setValue(`item_list[${index}].itemTotalPrice`, newTotalPrice);
                                        setValue(`item_list[${index}].itemTotalPrice`, (newTotalPrice *currentTax/100)+(newTotalPrice) );
                                        clearErrors([`item_list[${index}].itemTotalPrice`]);
                                        clearErrors([`item_list[${index}].itemUnitPrice`]);
                                        // return quantity.quantity * newValue; 
                                        return (quantity.quantity * newValue *currentTax/100) +(quantity.quantity * newValue); 

                                          
                                          // Calculate new price
                                      }
                                      return u_price[index1]; //
                                    });
                                    setu_price(updatedUPrice);
                                  }
                                  
                              }}
                              errorMessage={errors?.item_list?.[index]?.itemUnitPrice?.message}
                              disabled={isUpdating || accessType === "User"}
                            />
                            </td>
                            <td >
                            <TextInput
                                  type="text"
                                  label=""
                                  placeholder="Tax"
                                  name={`item_list[${index}].itemTax`}
                                  register={register}
                                  disabled={true}
                                />
                              </td>
                              <td width="20%">
                                <TextArea
                                  placeholder="Enter description"
                                  label=""
                                  name={`item_list[${index}].itemDescription`}
                                  register={register}
                                  {...register(`item_list[${index}].itemDescription`, {
                                    required: "This field is required",
                                  })}
                                  errorMessage={
                                    errors?.item_list?.[index]?.itemDescription?.message
                                  }
                                  // disabled={isUpdating || accessType === "User"}
                                  // disabled={true}
                                />
                              </td>
                              <td>
                              <TextInput
                                  type="text"
                                  label=""
                                  placeholder="Enter Currency"
                                  name={`item_list[${index}].itemCurrencyType`}
                                  register={register}
                                  {...register(`item_list[${index}].itemCurrencyType`, {
                                    required: "This field is required",
                                  })}
                                  errorMessage={errors?.item_list?.[index]?.itemCurrencyType?.message}
                                  // value={item.poNumber || ""}  // Assuming you have the PO number here
                                  disabled={true}
                                />
                              </td>
                              
                              <td>
                                  <TextInput
                                    type="number"
                                    label=""
                                    placeholder="Enter total price"
                                    // value={u_price[index] || ''}
                                    name={`item_list[${index}].itemTotalPrice`}
                                    register={register}
                                    {...register(`item_list[${index}].itemTotalPrice`, {
                                      required: "This field is required",
                                    })}
                                    errorMessage={errors?.item_list?.[index]?.itemTotalPrice?.message}
                                    // disabled={isUpdating || accessType === "User"}
                                    disabled = {true}
                                    customClass={classes.totalPriceInput}
                                  />
                              </td> 
                              
                              {/* {Totalprice_list === false &&
                              <td>
                                  <TextInput
                                    type="number"
                                    label=""
                                    placeholder="Enter total price"
                                    // value={u_price[index] || ''}
                                    name={`item_list[${index}].itemTotalPrice`}
                                    register={register}
                                    {...register(`item_list[${index}].itemTotalPrice`, {
                                      required: "This field is required",
                                    })}
                                    errorMessage={errors?.item_list?.[index]?.itemTotalPrice?.message}
                                    disabled={isUpdating || accessType === "User"}
                                    customClass={classes.totalPriceInput}
                                  />
                              </td>
                              }
                             {Totalprice_list && <td>
                                  <TextInput
                                    type="number"
                                    label=""
                                    placeholder="Enter total price"
                                    value={u_price[index] || ''}
                                    // name={`item_list[${index}].itemTotalPrice`}
                                    register={register}
                                    {...register(`item_list[${index}].itemTotalPrice`, {
                                      required: "This field is required",
                                    })}
                                    errorMessage={errors?.item_list?.[index]?.itemTotalPrice?.message}
                                    disabled={isUpdating || accessType === "User"}
                                    customClass={classes.totalPriceInput}
                                  />
                              </td>} */}
                              {/* {index === itemList?.length - 1 && (
                                <td>
                                  <TextInput
                                    type="number"
                                    label=""
                                    placeholder="Enter total price"
                                    name={`item_list[${index}].itemPrice`}
                                    register={register}
                                    {...register(`item_list[${index}].itemPrice`, {
                                      required: "This field is required",
                                    })}
                                    errorMessage={errors?.item_list?.[index]?.itemPrice?.message}
                                    disabled={isUpdating || accessType === "User"}
                                    customClass={classes.totalPriceInput}
                                  />
                                </td>
                              )} */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </div>
                      </div>
                    </div>
                    {/*Item details*/}
                    <h3 className={classes.subTitle}>Grand Total</h3>
                  <div
                  className={`${classes.poForm} ${classes.borderBottom}`}>
                  <TextInput
                  type="text"
                  label="Grand Total"
                  placeholder="Enter Grand Total"
                  name="GrandTotal"
                  register={register}
                  {...register("GrandTotal")}
                  value={GrandTotal.toFixed(2)} // Bind the state here
                  // errorMessage={errors?.GrandTotal?.message}
                  disabled={true}
                  />
                  </div>
                    {/*Item payment*/}
                    <h3 className={classes.subTitle}>Payment Terms</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextArea
                        type="text"
                        label="Description"
                        placeholder="Enter Description"
                        name="paymentTerms"
                        register={register}
                        {...register("paymentTerms", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.paymentTerms?.message}
                        disabled={isUpdating || accessType=="User"}
                      />
                    </div>
                    {/*Item payment*/}
                    {/*Item validity*/}
                    <h3 className={classes.subTitle}>Validity</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Validity"
                        placeholder="Enter validity"
                        name="validity"
                        register={register}
                        {...register("validity", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.validity?.message}
                        disabled={isUpdating || accessType=="User"}
                      />
                    </div>
                    {/*Item validity*/}

                    {/*Delivery Terms*/}
                    <h3 className={classes.subTitle}>Delivery Terms</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextArea
                        type="text"
                        label="Delivery Terms"
                        placeholder="Enter delivery terms"
                        name="deliveryTerms"
                        register={register}
                        {...register("deliveryTerms", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.deliveryTerms?.message}
                        disabled={isUpdating || accessType=="User"}
                      />
                    </div>
                    {/*Delivery Terms*/}

                    {/*Mode of transportation*/}
                    <h3 className={classes.subTitle}>Mode of transportation</h3>
                    <div
                      className={`${classes.poForm} ${classes.borderBottom}`}
                    >
                      <TextInput
                        type="text"
                        label="Mode of Transportation"
                        placeholder="Enter Mode of Transportation"
                        name="modeOfTransportation"
                        register={register}
                        {...register("modeOfTransportation", {
                          required: "This field is required",
                        })}
                        errorMessage={errors?.modeOfTransportation?.message}
                        disabled={isUpdating || accessType=="User"}
                      />
                    </div>
                    {/*Mode of transportation*/}

                    {/*Terms*/}
                    {data.access_type!= "User" && (
                      <>
                        <h3 className={classes.subTitle}>Terms and Condition</h3>
                        <div
                          className={`${classes.poForm} ${classes.borderBottom}`}
                        >
                          <div
                            className={`${styles.inputContainer} ${classes.customTextareaSpan}`}
                          >
                            <label className={styles.label}>Description</label>
                            <Controller
                              name="terms_and_condition"
                              control={control}
                              defaultValue=""
                              rules={{ required: "This field is required" }}
                              render={({ field }) => (
                                <ReactQuill
                                  theme="snow"
                                  value={field.value || ""}
                                  onChange={field.onChange}
                                />
                              )}
                            />
                            {errors.terms_and_condition && (
                              <p className={styles.error}>
                                {errors.terms_and_condition.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {/*Terms*/}

                    {/*UserTerms*/}
                    {data.access_type== "User" && (
                      <>
                        <h3 className={classes.subTitle}>Terms and Condition</h3>
                        <div
                          className={`${classes.poForm} ${classes.borderBottom}`}
                        >
                          <div
                            className={`${styles.inputContainer} ${classes.customTextareaSpan}`}
                          >
                            {/* <label className={styles.label}>Description</label> */}
                            <Controller
                              name="terms_and_condition"
                              control={control}
                              defaultValue=""
                              rules={{ required: "This field is required" }}
                              render={({ field }) => (
                                <TextArea
                                  // theme="snow"
                                  // value={field.value || ""}
                                  // onChange={field.onChange}
                                  label="Description"
                                  data="terms"
                                  name={field.name}
                                  placeholder="Enter terms and conditions"
                                  value={field.value}
                                  onChange={field.onChange}
                                  errorMessage={errors?.terms_and_condition?.message}
                                  readOnly={true}
                                  register={register}
                                  {...register("terms_and_condition", {
                                    required: "This field is required",
                                  })}
                                />
                              )}
                            />
                            {errors.terms_and_condition && (
                              <p className={styles.error}>
                                {errors.terms_and_condition.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {/*User Terms*/}
                    {/* DeliveryDate */}
                        <h3 className={classes.subTitle}>Dates for Payment and Delivery</h3>
                          <div
                            className={`${classes.poForm} ${classes.borderBottom}`}
                          >
                            <DDateInput
                              customClass={classes.filterFields}
                              type={"date"}
                              label={"Delivery Date"}
                              name={"ddate"}
                              // disableFuture={true}
                              disablePast={true}
                              onChange={(e) => {
                                setDdate({
                                  isValid: emptyCheck(e),
                                  value: e,
                                });
                              }}
                              disabledInput={accessType=="User"}
                              value={ddate}
                              error={!ddate?.isValid}
                              errorMsg={"Delivery date is required"}
                            />

                            <DDateInput
                              customClass={classes.filterFields}
                              type={"date"}
                              label={"Payment Date"}
                              name={"paydate"}
                              disablePast={true}
                              // disableFuture={true}
                              onChange={(e) => {
                                setPaydate({
                                  isValid: emptyCheck(e),
                                  value: e,
                                });
                              }}
                              disabledInput={accessType=="User"}
                              value={paydate}
                              error={!paydate?.isValid}
                              errorMsg={"Payment date is required"}
                            />
                          </div>
                    {/* Delivery Date */}

                    {/*User Comments*/}
                    {data.access_type== "User" && approveStatus != '2' &&(
                      <>
                        <h3 className={classes.subTitle}>Comments</h3>
                        <div
                          className={`${classes.poForm} ${classes.borderBottom}`}
                        >
                          <TextArea
                            type="text"
                            label="Comments"
                            placeholder="Enter Comments"
                            name="comments"
                            onChange={handleTextAreaChange}
                            register={register}
                            {...register("comments", {
                              required: "This field is required",
                            })}
                            errorMessage={errors?.comments?.message}
                            // disabled={isUpdating || accessType=="User"}
                          />
                        </div>
                      </>
                    )}
                    {/*User Comments*/}

                    <div className={classes.poSubmitBtnGroup}>
                      
                      {/*<DButton
                        iconWidth="1rem"
                        iconHeight="1rem"
                        label={"Save"}
                        disabled={isUpdating}
                        btnHeight="4.5rem"
                        btnWidth="12rem"
                        loadingState={false}
                        buyerBtn={true}
                      />*/}
                      {accessType=="User" &&
                        <p className={classes.error}>
                          {primaryBtnError.errorMsg}
                        </p>
                      }
                      {accessType=="User" && approveStatus == "2" ? (<>
                        <DButton
                          label={"Back"}
                          btnHeight="4rem"
                          btnWidth={"12rem"}
                          buyerBtn={true}
                          onClick={() => {
                            reset();
                            setPoEdit(false);
                            setPrimaryBtnError({
                              error: false,
                              errorMsg:'',
                            });
                          }}
                        />
                        {/* {!primaryBtnError.errorMsg && */}
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          label={"Approved"}
                          disabled={isUpdating}
                          btnHeight="4.5rem"
                          btnWidth="12rem"
                          loadingState={false}
                          buyerBtn={true}
                          onClick={(e) => {
                            e.preventDefault(); 
                          }}
                        />    
                          {/* }  */}
                        </>
                      ):('')}
                      {accessType=="User" && selectedPo.preappStatus!==null && selectedPo.appStatus ===1 ?  (<>
                        <DButton
                          label={"Back"}
                          btnHeight="4rem"
                          btnWidth={"12rem"}
                          buyerBtn={true}
                          onClick={() => {
                            reset();
                            setPoEdit(false);
                            setPrimaryBtnError({
                              error: false,
                              errorMsg:'',
                            });
                          }}
                        />
                        </>
                      ):('')}
                      {accessType=="User" && approveStatus == "1" ? (<>
                        {/* {!primaryBtnError.errorMsg && */}
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          label={"Rejected"}
                          disabled={isUpdating}
                          btnHeight="4.5rem"
                          btnWidth="12rem"
                          loadingState={false}
                          buyerBtn={true}
                          onClick={(e) => {
                            e.preventDefault(); 
                          }}
                        />    
                          {/* }  */}
                        </>
                      ):('')}
                       {accessType=="User" && selectedPo.preappStatus===null || selectedPo.preappStatus ===1  ?  (<>
                        <DButton
                          label={"Back"}
                          btnHeight="4rem"
                          btnWidth={"12rem"}
                          buyerBtn={true}
                          onClick={() => {
                            reset();
                            setPoEdit(false);
                            setPrimaryBtnError({
                              error: false,
                              errorMsg:'',
                            });
                          }}
                        />
                        </>
                      ):('')}
                        {accessType=="User" && selectedPo.preappStatus===undefined  && selectedPo.appStatus !==2 &&selectedPo.appStatus!=1 ? (
                        <>
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          label={"Approve"}
                          disabled={isUpdating}
                          btnHeight="4.5rem"
                          btnWidth="12rem"
                          loadingState={false}
                          buyerBtn={true}
                          onClick={() => handleSubmit((data) => onUserSubmit(data, 2), onError)()}
                        />
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          label={"Reject"}
                          disabled={isUpdating}
                          btnHeight="4.5rem"
                          btnWidth="12rem"
                          loadingState={false}
                          buyerBtn={true}
                          onClick={() => handleSubmit((data) => onUserSubmit(data, 1), onError)()}
                        />
                      </>
                      ) :('')}
                      {accessType=="User" && selectedPo.preappStatus=== undefined && selectedPo.appStatus !==2 && selectedPo.appStatus !==1 &&
                        <DButtonOutlined
                          label={"Cancel"}
                          onClick={() => {
                            setTotalprice_list(false);
                            // setu_price([]);
                            settestt([]);
                            setquant([]);
                            reset();
                            setPoEdit(false);
                          }}
                          btnWidth={"14rem"}
                          btnHeight={"4.5rem"}
                          disabled={isUpdating}
                        />
                      }

                      {accessType=="User" && selectedPo.preappStatus===2 && selectedPo.appStatus !==2  && selectedPo.appStatus !==1 ? (
                        <>
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          label={"Approve"}
                          disabled={isUpdating}
                          btnHeight="4.5rem"
                          btnWidth="12rem"
                          loadingState={false}
                          buyerBtn={true}
                          onClick={() => handleSubmit((data) => onUserSubmit(data, 2), onError)()}
                        />
                        <DButton
                          iconWidth="1rem"
                          iconHeight="1rem"
                          label={"Reject"}
                          disabled={isUpdating}
                          btnHeight="4.5rem"
                          btnWidth="12rem"
                          loadingState={false}
                          buyerBtn={true}
                          onClick={() => handleSubmit((data) => onUserSubmit(data, 1), onError)()}
                        />
                         <DButtonOutlined
                          label={"Cancel"}
                          onClick={() => {
                            setTotalprice_list(false); 
                            // setu_price([]);
                            settestt([]);
                            setquant([]);
                            reset();
                            setPoEdit(false);
                          }}
                          btnWidth={"14rem"}
                          btnHeight={"4.5rem"}
                          disabled={isUpdating}
                        />
                      </>
                      ) :('')}
                      {accessType!="User" &&(
                        <>
                          <p className={classes.error}>
                            {primaryBtnError.errorMsg}
                          </p>
                          {selectedPo.approveCount===0 &&
                            <>
                              <DButton
                                iconWidth="1rem"
                                iconHeight="1rem"
                                label={"Save"}
                                disabled={isUpdating}
                                btnHeight="4.5rem"
                                btnWidth="12rem"
                                loadingState={false}
                                buyerBtn={true}
                                onClick={() => {setTotalprice_list(false);
                                  // setu_price([]);
                                  settestt([]);
                                  setquant([]);
                                  handleSubmit((data) => onSubmit(data, 0), onError)()}}
                              />

                              <DButton
                                iconWidth="1rem"
                                iconHeight="1rem"
                                label={"Save & Send For Approval"}
                                disabled={isUpdating}
                                btnHeight="4.5rem"
                                btnWidth="25rem"
                                loadingState={false}
                                buyerBtn={true}
                                onClick={() => {setTotalprice_list(false);
                                  //  setu_price([]); 
                                  settestt([]);
                                  setquant([]);
                                  handleSubmit((data) => onSubmit(data, 1), onError)()}}
                              />
                            </>
                          }
                          {selectedPo.currentAppstatus === 1 && selectedPo.re_generate !==1  ?(
                            <>
                              <DButton
                                iconWidth="1rem"
                                iconHeight="1rem"
                                label={"Save"}
                                disabled={isUpdating}
                                btnHeight="4.5rem"
                                btnWidth="12rem"
                                loadingState={false}
                                buyerBtn={true}
                                onClick={() =>{setTotalprice_list(false);
                                  //  setu_price([]);
                                  settestt([]);
                                  setquant([]); 
                                  handleSubmit((data) => onSubmit(data, 0), onError)()}}
                              />

                              <DButton
                                iconWidth="1rem"
                                iconHeight="1rem"
                                label={"Save & Send For Approval"}
                                disabled={isUpdating}
                                btnHeight="4.5rem"
                                btnWidth="25rem"
                                loadingState={false}
                                buyerBtn={true}
                                onClick={() => {setTotalprice_list(false);
                                  //  setu_price([]); 
                                  settestt([]);
                                  setquant([]);
                                  handleSubmit((data) => onSubmit(data, 1), onError)()}}
                              />
                            </>
                          ):('')}
                          <DButtonOutlined
                            label={"Cancel"}
                            onClick={() => {
                              setTotalprice_list(false);
                              // setu_price([]);
                              settestt([]);
                              setquant([]);
                              reset();
                              setPoEdit(false);
                              setPrimaryBtnError({
                                  error: false,
                                  errorMsg:'',
                                });
                              setBtnError({
                                error: false,
                                errorMsg: ""
                              });
                              setDdate({
                                value:'',
                                isValid:true
                              })
                              setPaydate({
                                value:'',
                                isValid:true
                              })
                            }}
                            btnWidth={"14rem"}
                            btnHeight={"4.5rem"}
                            disabled={isUpdating}
                          />
                        </>
                      )}
                      
                      {/*<div>{loginData?.access_type}</div>*/}
                    </div>

                    {/* <p className={classes.editError}>
                      {btnError.errorMsg}
                    </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* success modal popup */}
      <Modal open={open}>
        <Fade in={open}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={successModalContent.title}
              popupDescription={successModalContent.description}
              popBtn={true}
              vendorBtn={true}
              btnText={"Okay"}
              btnClick={openPOCancel}
            />
          </Box>
        </Fade>
      </Modal>
      {/* delete error modal two states - confirmation and deletion */}
      {/* confirmation */}
      <Modal open={openDltConfirmation}>
        <Fade in={openDltConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText="Are you sure want to cancel this PO?"
              popupDescription={""}
              popBtn={true}
              btnText={"Yes"}
              loadingPrimaryBtn={isPoDeleting}
              secondaryBtn={true}
              vendorBtn={true}
              vendorBtnOutlined={true}
              // btnTextSecondary={"Cancel"}
              btnTextPo={"No"}
              btnClick={handleDeletePO}
              btnClickSecondary={handleCloseDltConfirmation}
              poContent={true}
            />
          </Box>
        </Fade>
      </Modal>
      {/* restrict 0 grandTotal */}
      <Modal open={openRstConfirmation}>
        <Fade in={openRstConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={deletePopupImg}
              popupHeroText="GrandTotal should be greater than 0"
              popupDescription={""}
              popBtn={true}
              btnText={"Okay"}
              // loadingPrimaryBtn={isPoDeleting}
              vendorBtn={true}
              // btnTextSecondary={"Cancel"}
              btnClick={handleCloseRstConfirmation}
            />
          </Box>
        </Fade>
      </Modal>
      {/* pdf preview */}
      <Modal
        open={openPoPdfModal}
        onClose={() => {
          setOpenPoPdfModal(false);
        }}
        aria-labelledby="pdf-preview-title"
        aria-describedby="pdf-preview-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              // padding: "20px",
              padding: "2px",
              justifyContent: "center",
              boxShadow: 24,
              width: "100%",
              maxHeight: "90%",
              height: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h4
              style={{
                fontSize: "28px",
                textAlign: "center",
                marginBottom: "10px",
                textTransform: "uppercase",
                fontFamily: "GilroyBold",
              }}
            >
              PDF PO#{PO_number}
            </h4>

            <div className={classes.pdfWrapper}>
              {generateViewPdfFlag ? (
                <span className={`${classes.loader} ${classes.pdfLoader}`}>
                  <CircularProgress
                    thickness={6}
                    variant="indeterminate"
                    disableShrink
                    sx={{
                      animationDuration: "350ms",
                      color: "#55555580",
                    }}
                    size={25}
                  />
                  <p
                    style={{
                      display: "block",
                      fontSize: "12px",
                      marginLeft: "10px",
                      color: "#3c3636",
                    }}
                  >
                    Generating pdf...
                  </p>
                </span>
              ) : (
                pdfData && (
                  <iframe
                    src={pdfData}
                    width="100%"
                    height="100%"
                    title="pdf view"
                    style={{
                      height: "calc(100% - 65px)",
                      padding: "10px",
                      border: "1px solid #f2f2f2",
                      borderRadius: "6px",
                      width: "calc(100% - 20px)",
                      margin: "auto",
                    }}
                  ></iframe>
                )
              )}
              <DButtonOutlined
                label="Close"
                btnWidth={"12rem"}
                btnHeight={"4rem"}
                onClick={() => setOpenPoPdfModal(false)}
                buyerBtn={true}
                vendorBtnOutlined={false}
              />
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openViewItemPopup}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={openViewItemPopup}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            <PopupFrame
              inputChildren={viewUserChildren}
              poupHeading={viewUserHeading}
              actionButtons={viewItemsActionButtons}
              closePopup={'handleCloseViewItemPopup'}
              width={"auto"}
              customClass={classes.addPopup}
              styleCenterDiv={classes.centerDiv}
            />
          </div>
        </Fade>
      </Modal>
      {/* PO PDF view */}
      <PoPdfView pdfData={viewPO} ref={pdfRef} />
    </div>
  );
}

export default PoTab;

function TextInput({
  label = "label",
  type = "text",
  placeholder = "",
  disabled = false,
  errorMessage = "",
  customClass = "",
  register,
  name,
  unit, 
  ...rest
}) {
 
  return (
    <div className={`${styles.inputContainer} ${customClass}`}>
      <label className={styles.label}>{label}</label>
      <input
        type={type}
        name={name}
        className={`${styles.input}`}
        placeholder={placeholder}
        onChange={unit}
        disabled={disabled}
        {...register(name)}
        {...rest}
      />
      {errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}

// function stripHtml(html) {
//   const doc = new DOMParser().parseFromString(html, 'text/html');
//   return doc.body.textContent || "";
// }


function TextArea({
  label = "label",
  type = "text",
  placeholder = "",
  disabled = false,
  errorMessage = "",
  register,
  name,
  value ='',
  data = '', 
  readOnly=false,
  ...rest
}) {
  return (
    <div className={`${styles.inputContainer} ${classes.customTextareaSpan}`}>
      <label className={styles.label}>{label!= 'Comments' && label}</label>
      {data !="terms" &&
        <textarea
          type={type}
          name={name}
          className={`${styles.inputTextArea} ${classes.resizeNone}`}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          {...register(name)}
          {...rest}
        ></textarea>
       }
      {label =="Description" && data =="terms"  &&

        <div
        style={{ whiteSpace: 'pre-wrap', border: '1px solid #ccc', padding: '1rem', maxHeight: '500px', fontSize: '13px',overflowY: 'auto' }}
        dangerouslySetInnerHTML={{ __html: value }}
        />
      }
      {errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}

function PhoneNumberInput({
  label,
  customClassName,
  onChange,
  error,
  errorMessage,
  name,
  disabled = false,
  value,
  ...rest
}) {
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (typeof value === "string" && value.includes("+")) {
      let sanitizedValue = value
        .replace(/[^\w\s]/gi, "")
        .split(" ")
        .slice(1)
        .join("");
      setInputValue(sanitizedValue);
    } else {
      setInputValue(value || "");
    }
  }, [value]);

  const handlePhoneNumberChange = (status, number, countryData, fullNumber) => {
    if (onChange) {
      onChange(number, countryData, status, fullNumber);
    }

    // setValue(name, fullNumber);
  };

  const handleSelectFlag = (number, countryData) => {
    let countryCodeSplitted = number.split(" ");
    let countryCode = countryCodeSplitted[0] === `+${countryData.dialCode}`;
    // setValue(name, countryCode === false ? "" : number);
  };
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label}>{label}</label>
      <div className={styles.d_input_wrapper}>
        <IntlTelInput
          onPhoneNumberChange={handlePhoneNumberChange}
          onSelectFlag={handleSelectFlag}
          defaultCountry="in"
          value={inputValue}
          autoHideDialCode={true}
          format={false}
          fieldName={name}
          disabled={disabled}
          disableCountryCode={disabled}
          {...rest}
        />
      </div>
      {errorMessage && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}

function LocationSelectDropDown({
  defaultDropDown,
  errorMessage,
  placeHolder,
  label,
  value,
  customClassName,
  onChange,
  error,
  options,
  disabled,
  noInit,
  register,
  name,
  ...rest
}) {
  return (
    <div className={`${styles.inputContainer} ${customClassName}`}>
      <label className={styles.label}>{label}</label>
      {defaultDropDown ? (
        <div className={`${styles.select} ${error && styles.error}`}>
          <Select
            value={value}
            onChange={onChange}
            displayEmpty
            disabled={disabled}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              sx: {
                ".MuiMenu-paper": {
                  bottom: "10px !important",
                  maxHeight: "200px",
                },
              },
            }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: "0",
              },
              width: "100%",
              fontSize: "1.35rem",
              height: "46px",
              marginBottom: "1px",
              outline: "none",
              fontFamily: "Source Sans Regular",
            }}
            {...register(name)}
            {...rest}
          >
            <MenuItem value={""} sx={{ fontSize: "1.3rem" }}>
              {noInit ? "Select" : options && options[0]}
            </MenuItem>
            {options &&
              options?.map((option, i) =>
                !noInit && i == 0 ? (
                  ""
                ) : (
                  <MenuItem
                    sx={{ fontSize: "1.3rem" }}
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                )
              )}
          </Select>
        </div>
      ) : (
        ""
      )}
      {error && <span className={styles.errorText}>{errorMessage}</span>}
    </div>
  );
}

import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Box, Fade, Modal, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
//components
import HeaderTitle from "../../../components/common/HeaderTitle/HeaderTitle";
import DateBar from "../../../components/common/DateBar/DateBar";
import ProfileCard from "../../../components/common/ProfileCard/ProfileCard";
import Dbutton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
//img
import profileImg from "../../../assets/images/png/Profile.png";
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
//utills
import { API_ENDPOINT } from "../../../services/api/resources";
import PopupFrame from "../../../components/Popups/PopupFrame/PopupFrame";
import { emptyCheck, emptyOnlyCheck } from "../../../utils/validations";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import DTextArea from "../../../components/Dashboard/DashboardInputs/DTextArea";
//css
import classes from "./Prompt.module.css";

const promptReducer = (state, action) => {
  switch (action.type) {
    case "setPromptData":
      return { ...state, promptList: action.payload, editPromptData: {} };
    case "openEditPrompt":
      return {
        ...state,
        isEditmodalOpen: true,
        editPromptData: action.payload,
      };
    case "closeEditPrompt":
      return { ...state, isEditmodalOpen: false };
    case "updateSuccessModal":
      return { ...state, editSuccessModal: true, isEditmodalOpen: false };
    case "updateSuccessModalClose":
      return { ...state, editSuccessModal: false, editPromptData: {} };
    default:
      return { ...state };
  }
};
const PromptCard = ({ data, promptEditOpen }) => {
  const { module, message, key } = data;
  return (
    <div className={classes.promptScreen} key={key}>
      <div className={classes.propmtItem}>
        <div className={classes.propmtItemDesc}>
          <h4 className={classes.promptTitle}>{module}</h4>
          <p className={classes.promptDescription}>{message}</p>
        </div>
        <Dbutton
          label="EDIT AI DIALOGUE"
          customWrapperClass={classes.editBtnWrapper}
          customClass={classes.edtBtn}
          iconWidth={"2rem"}
          iconHeight={"2rem"}
          btnHeight={"4.5rem"}
          disabled={false}
          onClick={promptEditOpen}
        />
      </div>
    </div>
  );
};
const NoDataInfo = ({ message }) => {
  return (
    <div className={classes.nodataInforWrapper}>
      <p>{message}</p>
    </div>
  );
};
export default function Prompt() {
  const data = useSelector((state) => state.loginData.value);
  //credentials
  const auth_token = data?.auth_token;
  // const [editPrompt, setEditPrompt] = useState({})
  let initialState = {
    promptList: [],
    editPromptData: {},
    isEditmodalOpen: false,
    editSuccessModal: false,
  };
  //state
  const [promptDescripton, setPromptDescripton] = useState({
    value: "",
    isValid: true,
  });
  const [primaryBtnError, setPrimaryBtnError] = useState({
    error: false,
    errorMsg: "",
  });
  const [loadingState, setLoadingState] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  //reducer
  const [promptState, promptDispatch] = useReducer(promptReducer, initialState);

  useEffect(() => {
    setLoadingState(true);
    setIsDataLoading(true);
    axios({
      method: "GET",
      url: `${API_ENDPOINT.get_prompt_table}`,
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    })
      .then((response) => {
        setLoadingState(false);
        setIsDataLoading(false);
        let promptList = response.data.response;
        promptDispatch({ type: "setPromptData", payload: promptList });
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingState(false);
        setIsDataLoading(false);
        promptDispatch({ type: "setPromptData", payload: [] });
      });
  }, [promptState.editSuccessModal]);
  const handleEditSubmit = () => {
    setPrimaryBtnError({
      error: false,
      errorMsg: "",
    });
    setLoadingState(true);
    if (
      promptState["editPromptData"].token &&
      promptState["editPromptData"].message
    ) {
      axios({
        method: "POST",
        url: `${API_ENDPOINT.update_prompt_table}`,
        data: {
          prompt_token: promptState["editPromptData"].token,
          prompt_message: promptState["editPromptData"].message,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          setLoadingState(false);
          let response = res.data.response;
          if (response === "Update successfully") {
            promptDispatch({ type: "updateSuccessModal" });
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingState(false);
          setPrimaryBtnError({
            error: true,
            errorMsg: error.data.response,
          });
        });
    } else {
      setLoadingState(false);
      setPrimaryBtnError({
        error: true,
        errorMsg: "Prompt value not empt",
      });
    }
  };
  const InputsHeading = (
    <HeaderTitle
      titleText={"Edit AI Dialogue Genius"}
      customclass={classes.popupHeading}
    />
  );
  const itemInputs = [
    <DTextArea
      label="AI Dialogue Genius"
      type="text"
      maxLength={100}
      value={promptDescripton.value}
      onChange={(e) => {
        let val = e.target.value;
        setPromptDescripton({
          value: val,
          isValid: emptyOnlyCheck(val),
        });
        promptDispatch({
          type: "openEditPrompt",
          payload: { ...promptState.editPromptData, message: val },
        });
      }}
      error={!promptDescripton.isValid}
      customClassName={classes.popupFields}
      errorMsg={"Prompt Description is required"}
      size={"100%"}
    />,
    <div className={classes.editPromptNotes}>
      <InfoIcon sx={{ position: "relative", top: "-2px", color: "#5b5d5b" }} />
      <span>
        Don't remove or edit the curly brackets value in the dialogue below. For
        example, <code> &#65115;rfq&#65116;</code>{" "}
      </span>
    </div>,
  ];

  const itemsActionsButton = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={() => {
        setPrimaryBtnError({
          error: false,
          errorMsg: "",
        });
        promptDispatch({ type: "closeEditPrompt" });
      }}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
    />,
    <DButton
      label="Save"
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      loadingState={loadingState}
      error={primaryBtnError.error}
      errorMsg={primaryBtnError.errorMsg}
      onClick={handleEditSubmit}
    />,
  ];
  return (
    <>
      <div
        className={`${classes.users_tab_wrapper} ${classes.superAdminContainer}`}
      >
        <div className={classes.users_topHeader}>
          <div className="flex">
            <HeaderTitle
              titleText="AI Dialogue Genius"
              customclass={classes.dashHead}
            />
          </div>
          <div className="flex">
            <DateBar />
            <ProfileCard
              imageSrc={profileImg}
              profilePath={"/Organization_dashboard/My_profile"}
            />
          </div>
        </div>
        <div className={classes.promptScreenMain}>
          {!isDataLoading ? (
            promptState.promptList?.length > 0 ? (
              promptState.promptList?.map((item) => (
                <PromptCard
                  data={item}
                  promptEditOpen={() => {
                    setPromptDescripton({
                      value: item.message,
                      isValid: true, //emptyCheck(item.message),
                    });
                    promptDispatch({ type: "openEditPrompt", payload: item });
                  }}
                  key={item.token}
                />
              ))
            ) : (
              <NoDataInfo message="No Prompt Message Available" />
            )
          ) : (
            <>
              {[...Array(2)].map((_, index) => (
                <div
                  className={classes.promptSkeleton}
                  style={{
                    width: "100%",
                    maxHeight: "120px",
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ width: "80%" }}>
                    <Skeleton width={"100%"} height={"20px"} />
                    <br />
                    <Skeleton
                      width={"100%"}
                      height={"20px"}
                      sx={{ transform: "scale(1)" }}
                    />
                  </span>
                  <span style={{ width: "10%" }}>
                    <Skeleton
                      width={"100%"}
                      height={"35px"}
                      sx={{ transform: "scale(1)" }}
                    />
                  </span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {/* edit modal */}
      <Modal open={promptState.isEditmodalOpen}>
        <Fade in={promptState.isEditmodalOpen}>
          <div
            className={classes.flex}
            style={{
              height: "130vh",
            }}
          >
            <PopupFrame
              inputChildren={itemInputs}
              poupHeading={InputsHeading}
              actionButtons={itemsActionsButton}
              closePopup={() => promptDispatch({ type: "closeEditPrompt" })}
              customClass={classes.promptModalFram}
            />
          </div>
        </Fade>
      </Modal>
      {/* success modal */}
      <Modal open={promptState.editSuccessModal}>
        <Fade in={promptState.editSuccessModal}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={"AI Dialogue Update Successfuly."}
              popupDescription={""}
              customHeroTextClass={classes.successPopupTitle}
              popBtn={true}
              btnText={"Okay"}
              btnClick={() => {
                promptDispatch({ type: "updateSuccessModalClose" });
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

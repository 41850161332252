import React, { memo } from 'react';
import classes from './Homebuttons.module.css'
import { Telegram } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

const SignupBtn = ({ iconSrc, customClassName, buttonText, onClick, noIcon, loading, disabled }) => {

    return (
        <button className={`${classes.signup_btn} ${customClassName} ${classes.flex} ${disabled && classes.disabled}`} onClick={onClick} disabled={disabled}>
            <span>{buttonText}</span>
            {iconSrc && <img src={iconSrc} className={`${classes.signup_btn_img}`} />}
            {!noIcon && <Telegram className={`${classes.btnImg}`} />}
            {
                loading && <CircularProgress size={"22px"}
                    thickness={6}
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: "#fff",
                        animationDuration: '450ms',
                    }} />
            }
        </button>
    );
};

export default memo(SignupBtn);

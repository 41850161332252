import React, { memo } from "react";
import BrandLogo from "../../../components/common/BrandLogo/BrandLogo";
import classes from "./Header.module.css";
import logoImg from "../../../assets/images/svg/Logo.svg";
import NavLinks from "./NavLinks";
import { useNavigate } from "react-router-dom";
const Header = ({ setHomeVisible, setPrivacyPolicyVisible }) => {
  const navigate = useNavigate();

  const toHome = () => {
    navigate("/");
  };

  return (
    <div className={`${classes.header} ${classes.flex}`}>
      <div className={classes.container}>
        <div className={`${classes.logo} ${classes.flex}`}>
          <BrandLogo imgSrc={logoImg} imgOnclick={toHome} />
        </div>

        <NavLinks />
      </div>
    </div>
  );
};

export default memo(Header);

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Router, Routes, redirect } from "react-router-dom";
import DashboardMain from "../../pages/OrganisationDashboard/OrganisationDashboard";
const PrivateRoute = ({ Element }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return isLoggedIn ? <Element /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;

import React from "react";
import "./PrivacyPolicy.css"
import { Element } from "react-scroll";

const PrivacyPolicy = () => {
    return (
        <div className="prp_wrapper">
            <div className="privacy_content row">
                <div className="privacy_header mx_auto pb_5 col_12 col_md_9">
                    <h1 className="privacy_title">Privacy Policy</h1>
                    <p className="privacy_updated">(Last updated: June 2022)</p>
                </div>
                <div className="col_md_12">
                    <h2>HOW WE PROTECT YOUR PRIVACY</h2>
                    <p>
                        We at Teckost, deeply value the trust you have placed in us by
                        sharing your Personal Data with us. We respect your privacy and
                        consider protecting it our duty.
                    </p>
                    <p>
                        This <strong>privacy statement</strong> has been framed to answer
                        all your concerns regarding your privacy. It describes how
                        BidToaster collects, uses, and shares Personal Data provided to it,
                        by you or our customer and the rights available to you.
                    </p>
                    <div className="pl_4">
                        <h2>SCOPE</h2>
                        <p>This Privacy Statement applies to the Personal Data that</p>
                        <ul>
                            <li>
                                Our customers may provide to us during the time of Vendor/Buyer
                                Signup, including any changes made to such Personal Data later,{" "}
                            </li>
                            <li>
                                We may collect when you visit{" "}
                                <a href="https://www.bidz.ai">
                                    https://www.bidz.ai
                                </a>
                            </li>
                        </ul>
                        <p>
                            <b>
                                We are a business-to-business enterprise, and we do not deal
                                with individual consumers.
                            </b>{" "}
                            The Personal Data that we collect from you relates to your
                            official duties and not to you as a private individual.
                        </p>
                        <p>
                            If your organization i.e. our Customer provides you with access to
                            the BidToaster product then{" "}
                            <b>
                                it is the duty of our Customers to ensure that your privacy
                                rights are respected. It is their liability to ensure required
                                consents are obtained before entering any Personal Data into
                                BidToaster, including disclosure about third party data
                                collection and use.
                            </b>
                        </p>
                        <p>
                            This Privacy Statement doesn’t apply to any of our websites,
                            products or services that have a separate Privacy Statement.
                        </p>
                    </div>
                    <h2>YOUR PERSONAL DATA</h2>
                    <div className="pl_4">
                        <ul>
                            <li>
                                Personal Data that we may directly collect at the time of
                                Vendor/Buyer Signup
                                <ul>
                                    <li>Your first and last names</li>
                                    <li>Your designation</li>
                                    <li>
                                        Your official - email address, phone number and mobile
                                        number
                                    </li>
                                    <li>The registered address of your company.</li>
                                </ul>
                            </li>
                            <li>
                                IP address of visitors to{" "}
                                <a href="https://www.bidz.ai">
                                    https://www.bidz.ai
                                </a>
                            </li>
                            <li>
                                Data that you provide when you contact us for help or support.
                            </li>
                        </ul>
                    </div>
                    <h2>REASONS FOR PROCESSING YOUR PERSONAL DATA</h2>
                    <div className="pl_4">
                        <p>
                            We use the Personal Data collected from you to provide you with
                            the services of BidToaster in conducting business for your
                            employer or with a Trading Partner. We process Personal Data for
                            the performance of our contract with our Customers.
                        </p>
                        <strong>We use your Personal Data to</strong>
                        <ul>
                            <li>Provide you access to BidToaster’s content and services</li>
                            <li>
                                Enable the functioning of BidToaster and its associated services
                            </li>
                            <li>
                                Communicate with you about the BidToaster site, services, or
                                application
                            </li>
                            <li>
                                Process transactions requested or authorized by you through
                                BidToaster{" "}
                            </li>
                            <li>Prevent fraud, for authentication purposes, </li>
                            <li>Ensure network and information security</li>
                        </ul>
                        <p>
                            We will treat all your Personal Data as private and confidential.
                            We will process the data in a lawful, fair, and transparent
                            manner.
                        </p>
                    </div>
                    <h2>SHARING YOUR PERSONAL DATA WITH THIRD PARTIES</h2>
                    <div className="pl_4">
                        <p>
                            <b>
                                We will never share your Personal Data with another for
                                advertising. We will also never show you advertisements on our
                                platform.
                            </b>
                        </p>
                        <p>
                            When we share your Personal Data with third parties, it will be as
                            per the terms of this Privacy Statement.{" "}
                        </p>
                        <ul className="">
                            <li>
                                <h4>
                                    BidToaster’s integration with Third-Party Products or Services
                                </h4>
                                <p>
                                    When our customer requests for BidToaster’s integration with
                                    third party products or services, we will facilitate such
                                    integration with the consent of such third parties. This could
                                    result in disclosure of your Personal Data to such third
                                    parties.{" "}
                                    <b>
                                        Before such disclosure we will not seek additional
                                        permission for disclosure, from our customer. We recommend
                                        you review the privacy practices of such third parties. We
                                        are not liable for their privacy practices.{" "}
                                    </b>
                                </p>
                                <p>
                                    In such an instance if you do not wish to share your Personal
                                    Data, kindly contact your employer for alternatives.
                                </p>
                            </li>
                            <li>
                                <p>
                                    When you or your account administrator acting on your behalf
                                    request or authorise it.
                                </p>
                            </li>
                            <li>
                                <h4>Sub-processors</h4>
                                <p>
                                    Sharing your Personal Data with the third-party sub-processors
                                    we engage to provide us with services that aid in the proper
                                    functioning of BidToaster. For example, Azure is the cloud
                                    service provider that hosts BidToaster. The Personal Data
                                    collected by BidToaster will be stored in Azure. We assure you
                                    that in the contractual agreements with our sub-processors we
                                    will incorporate clauses like data protection,
                                    confidentiality, to protect your privacy.{" "}
                                </p>
                                <p>
                                    <i>
                                        You can view the list of sub-processors used by us by
                                        clicking <a href="/subprocess">here.</a>
                                    </i>
                                </p>
                            </li>
                            <li>
                                <h4>Legal Obligations</h4>
                                <ul>
                                    <li>
                                        Disclosure of Personal Data to comply with the law,
                                        regulations or as per a legal process
                                    </li>
                                    <li>
                                        Disclosure of Personal Data to protect and defend the rights
                                        of BidToaster
                                    </li>
                                    <li>
                                        Disclosure of Personal Data in an emergency or to protect
                                        personal safety
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <h2>RETENTION PERIOD</h2>
                    <div className="pl_4">
                        <p>
                            We retain your Personal Data as long as you are our Customer and
                            thereafter as required or permitted by applicable law. We will
                            delete your Personal Data when it is no longer necessary for the
                            purposes for which it was collected, and we are not legally
                            required or otherwise permitted to continue storing such data.
                        </p>
                        <p>
                            <b>
                                Personal Data related to vendors is retained as per the terms
                                agreed between the vendors and the customers using
                                BidToaster.
                            </b>
                        </p>

                        <ul>
                            <li>
                                <h4>Microsoft Azure</h4>
                                <p>
                                    The Personal Data collected by us is stored on the secure
                                    infrastructure provided by Microsoft Azure. The BCP/DR for
                                    BidToaster is also provided by Azure.
                                </p>
                            </li>
                            <li>
                                <h4>Access rights </h4>
                                <ul>
                                    <li>
                                        {" "}
                                        All Personal Data collected by us can be accessed only by
                                        authorised personnel.
                                    </li>
                                    <li>
                                        {" "}
                                        We use JWT token to prevent unauthorized login entry.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <h2>LOCATION OF PROCESSING OF PERSONAL DATA</h2>
                    <div className="pl-4">
                        <p>
                            To facilitate BidToaster’s use by our Customers from across the
                            globe we have data centres in several regions including the
                            European Economic Area. BidToaster operates using data centres
                            located mainly in the United States. As we add data centres in
                            more regions, these Privacy terms shall apply to our provision of
                            services in them as well.{" "}
                        </p>
                        <h4>Cross border data transfers</h4>
                        <p>
                            Owing to us having customers from across the globe, cross border
                            transfers of Personal Data may occur. We will ensure we have in
                            place the adequate transfer mechanism as mandated by the relevant
                            law. For example,
                        </p>
                        <strong>EEA (European Economic Area), UK, Switzerland</strong>
                        <p>
                            If we transfer personal data from EEA, Switzerland, and UK to
                            countries where the statutory levels of protection of Personal
                            Data is below the data protection standards of EEA, UK, and
                            Switzerland such transfers will be covered by the legally mandated
                            safeguards like:{" "}
                        </p>
                        <h4>Model Clauses: </h4>
                        <p>
                            For example, when Personal Data is transferred from EEA to a
                            country with incomparable data protection laws, such transfer will
                            be based on the Standard Contractual Clauses (according to the
                            Commission Implementing Decision (EU) 2021/914 of 4 June 2021).{" "}
                        </p>
                        <p>
                            You can learn more about Model Clauses by clicking on{" "}
                            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc/standard-contractual-clauses-international-transfers_en">
                                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc/standard-contractual-clauses-international-transfers_en{" "}
                            </a>{" "}
                        </p>
                    </div>
                    <h2>TRACKING TECHNOLOGIES </h2>
                    <div className="pl-4">
                        <h4>Cookies</h4>
                        <p>
                            {" "}
                            These are small files of information that a web server generates
                            and sends to a web browser. If you grant permission, web browser
                            will store such cookies in your computer’s hard drive. This allows
                            the cookies to capture and remember certain information about you
                            and provide such information to that site or service provider.
                        </p>
                        <h4>Why do we use cookies? </h4>
                        <p>BidToaster uses cookies to </p>
                        <ul>
                            <li>Administer and secure this website</li>
                            <li>Track visitor’s movement around this website </li>
                        </ul>
                        <p>
                            <b>
                                We use only Necessary Cookies. Hence you cannot disable the
                                cookies.
                            </b>{" "}
                            Your access to or use of this website constitutes your acceptance
                            to the terms of this Privacy Policy. If you do not wish to give
                            your acceptance, then kindly do not use this website. Personal
                            Data submitted by you while using BidToaster is not stored in
                            cookies.{" "}
                            <b>
                                We do not place any third-party advertising tracking cookies on
                                your computer during your use of BidToaster.
                            </b>
                        </p>
                    </div>
                    <h2>YOUR ACCESS AND CONTROL TO YOUR PERSONAL DATA</h2>
                    <div className="pl-4">
                        <p>
                            To the extent provided under applicable laws, you may request to
                            access, correct, update, or delete your Personal Data by
                            contacting us at{" "}
                            <a href="mailto: privacy@teckost.com">privacy@teckost.com</a>
                        </p>
                        <h4>Users of BidToaster Product</h4>
                        <p>
                            Our Customers are provided with user administration features using
                            which they can directly update or erase elements of User’s
                            Personal Data. So, if your organisation provides you with access
                            to BidToaster and is administering your use, then kindly contact
                            your IT Administrator to learn more about how to access and
                            control your Personal Data. If you are unable to do so, because
                            you are no longer an employee of our Customer, you may contact us
                            at <a href="mailto: privacy@teckost.com">privacy@teckost.com</a>.
                            In such an instance we will contact our Customer that is
                            responsible for the account and based on their response, we will
                            either accommodate your request or give you in writing the legal
                            basis for denial of your request, within 30 days.
                        </p>
                        <h4>Data Retention</h4>
                        <p>
                            BidToaster will retain your Personal Data for varying lengths
                            depending on the type of data, choices of your IT Administrator
                            and the applicable law, in accordance with the agreement between
                            us and our Customers. Once the agreement with a Customer in
                            BidToaster is terminated, we will delete the corresponding
                            Personal Data within 30 days.{" "}
                        </p>
                        <p>
                            Personal Data is retained for this period to confirm termination.{" "}
                        </p>
                        <p>
                            Personal Data may be retained for a longer duration if necessary
                            to
                        </p>
                        <ul>
                            <li>Comply with our legal obligations</li>
                            <li>Enforce our agreement</li>
                            <li>Resolve disputes.</li>
                        </ul>
                        <p>
                            We may also retain Personal Data for a longer duration on requests
                            of our Customer, unless prohibited by the applicable law.
                        </p>
                    </div>
                    <h2>LINKS TO THIRD PARTY WEBSITES</h2>
                    <div className="pl-4">
                        <p>
                            BidToaster may contain links to third party websites for example
                            when BidToaster is integrated with Third Party products or
                            services. Third-party websites have their own privacy policies
                            which are not under our control. We cannot be held liable for
                            their content or practices.{" "}
                            <b>
                                We strongly advise you to read the privacy policy of every such
                                third-party website.
                            </b>{" "}
                        </p>
                        <p>
                            However, we strive to provide you with a safe and pleasant
                            experience. If you have any feedback regarding these sites kindly
                            let us know at{" "}
                            <a href="mailto: privacy@teckost.com">privacy@teckost.com</a>.{" "}
                        </p>
                    </div>
                    <h2>REQUIREMENTS TO PROTECT CHILDREN'S PRIVACY. </h2>
                    <div className="pl-4">
                        <p>
                            BidToaster website is not intended for the use of anyone under the
                            age of 16. If you believe that a child under the age of 16 has
                            provided their Personal Data to us, please contact us at{" "}
                            <a href="mailto: privacy@teckost.com">privacy@teckost.com</a> and
                            we will take the necessary steps to delete such information.
                        </p>
                    </div>
                    <h2>FILING A COMPLAINT</h2>
                    <div className="pl-4">
                        <p>
                            If You have any complaints regarding our compliance with our
                            privacy and security practices, please contact us at{" "}
                            <a href="mailto: privacy@teckost.com">privacy@teckost.com</a>.{" "}
                        </p>
                        <p>
                            If you are a resident of European Union, you have the right to
                            file a complaint with a competent data protection authority.
                        </p>
                    </div>
                    <h2>CHANGES TO OUR PRIVACY STATEMENT</h2>
                    <div className="pl-4">
                        <p>
                            We may from time to time update this Privacy Statement for the
                            following reasons,
                        </p>
                        <ul>
                            <li>To reflect changes in our data processing policies,</li>
                            <li>
                                To reflect changes in BidToaster (addition/deletion of new
                                features and services),
                            </li>
                            <li>
                                As a response to feedback from customers, regulators, industry,
                                or other stakeholders,
                            </li>
                            <li>In response to changes in laws,</li>
                            <li>To be more transparent.</li>
                        </ul>
                        <p>Whenever we make such changes, we will</p>
                        <ul>
                            <li>
                                Revise the “last updated date” at the top of this statement,
                            </li>
                            <li>Put up a prominent notice on this website.</li>
                            <p>
                                If such changes significantly affect your rights, we will send
                                e-mail notifications to the Administrator Account of our
                                Customers.{" "}
                            </p>
                        </ul>
                        <p>
                            If you think that such updated changes affect your rights, kindly
                            contact your Account Administrator.
                        </p>
                        <p>
                            <strong>
                                After the effective date of changes to this Privacy Statement,
                                if you visit or continue using BidToaster and its associated
                                websites and services, it will be understood as your acceptance
                                to the terms of the updated Privacy Statement.
                            </strong>
                        </p>
                        <p>
                            We encourage you to periodically visit this page to be aware of
                            our latest privacy practices.
                        </p>
                    </div>
                    <div className="address-box">
                        <h2>HOW TO CONTACT US?</h2>
                        <p>
                            If you have any questions relating to this privacy policy or the
                            practices of this website kindly contact using the following
                            information:
                        </p>
                        <p>
                            <strong>Teckost IT Services Private Ltd,</strong>
                        </p>
                        <br />
                        53, North Boag Road, <br />
                        Fourth Floor, Mandira Block B, <br />
                        Behind Residency Towers, <br />
                        Chennai, Tamil Nadu 60001.
                        <br />
                        <p>
                            <a href="mailto: privacy@teckost.com">privacy@teckost.com</a>
                        </p>
                    </div>
                    <h2>DEFINITIONS of terms used in this policy</h2>
                    <div className="pl-4">
                        <ol>
                            <li>
                                <b>“We”</b>, <b>“Us”</b> and <b>“Our”</b> refers to Teckost IT
                                Services Pvt Ltd.
                            </li>
                            <li>
                                <b>“Our Customers”</b> refers to companies that have been
                                granted BidToaster Licenses, i.e., your employer company. They
                                may be referred to as <b>“Vendor”</b> or <b>“Buyer”</b> in this
                                statement. Individual users of BidToaster (whether employees of
                                “Vendor” or “Buyer”) collectively and individually maybe
                                referred to as “you” or “your” throughout this statement.
                            </li>
                            <li>
                                <b>“Trading Partner”</b> means an entity with which a Vendor or
                                Buyer transacts using BidToaster.
                            </li>
                            <li>
                                <b>“Personal data”</b> is any information related to an
                                identified or identifiable natural person.
                                <ul>
                                    <li>
                                        <b>“Identifiable natural person”</b> is someone who can be
                                        identified (directly/indirectly) by referring to an
                                        identifier.
                                    </li>
                                    <li>
                                        <b>“Identifier”</b> – Name, Identification number, location
                                        data, online identifier of that natural person.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>“Processing”</b> means any operation or set of operations
                                which is performed on personal data, whether or not by automated
                                means, such as collection, recording, organisation, structuring,
                                storage, adaptation or alteration, retrieval, consultation, use,
                                disclosure by transmission, dissemination or otherwise making
                                available, alignment or combination, restriction, erasure or
                                destruction.
                            </li>
                            <li>
                                <b>“Administrator Account”</b> is a Customer account designated
                                by Customer to serve as technical administrator of the
                                BidToaster product on behalf of the Customer.
                            </li>
                        </ol>
                    </div>
                </div >
            </div >
        </div>
    );
};

export default PrivacyPolicy;

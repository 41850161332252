import React, { useContext } from "react";
import { Autocomplete, Box, Grid, Skeleton, TextField } from "@mui/material"
import useAxios from "../../../hooks/useAxios"
import styles from "./DashboardInput.module.css";

const SelectCountry = (props) => {
  const { value, setValue, label, width} = props;
  const [data, loaded, error] = useAxios("https://restcountries.com/v3.1/all");

  if (loaded) {
    return (
      <Grid item xs={12} md={3}>
        <Skeleton variant="rounded" height={60} />
      </Grid>
    )
  }
  if (error) {
    return (
      <Grid item xs={12} md={3}>
        <div className={styles.d_input_container}>
          <label>{label}</label>
          <div className={styles.inputsborder}>
            Loading...
          </div>
        </div>
      </Grid>)
  }
  console.log('widthhhh', width)

  const dataFilter = data.filter(item => "currencies" in item);
  const dataCountries = dataFilter.map(item => {
    return {
      flag: item.flag,
      flags: item.flags.svg,
      countryName: item.name.common,
      currency: Object.keys(item.currencies)[0]
    }
    // return `${item.flag} ${Object.keys(item.currencies)[0]}`
  });
  return (
    <Grid item xs={12} md={3} style={{width: width == true ? '45%' : '100%'}}>
      <div className={styles.d_input_container}>
        <label>{label}</label>
        <div className={styles.inputsborder}>
          <Autocomplete
            value={value}
            disableClearable
            autoHighlight={true}
            autoComplete={true}
            sx={{
              height: "4.2rem",
              fontSize: "1.4rem",
              transition: "0",
              " MuiOutlinedInput-root": {
                paddingRight: '20px',
              },
              ".Mui-expanded": {
                transition: "none",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "0",
              },
              ".MuiAutocomplete-input": {
                fontSize: "1.4rem",
                fontFamily: "GilroyMedium",
                height: "2.6rem",
              },
            }}
            componentsProps={{
              popper: {
                sx: {
                  fontFamily: "gilroyMedium",
                },
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: true,
                  },
                ],
              },
            }}
            size="small"
            className={`${styles.select_wrapper_multiSelect}  ${error && styles.error
              }`}
            onChange={(event, newValue) => {
              setValue(newValue);
              props.onChange(newValue);

            }}
            getOptionLabel={(option) => (`${option.countryName ? option.countryName : ''} - ${option.currency ? option.currency : ''}`)}
            renderOption={(props, option) => <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                loading="lazy"
                width="20"
                srcSet={option.flags}
                src={option.flags}
                alt="" 
                />
                {option.countryName}
                {option.currency ? `- ${option.currency}` : ''}
          </Box>}
            options={dataCountries}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>
    </Grid>
  )
}

export default SelectCountry
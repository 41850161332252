import React, { useEffect, useState } from "react";
import errorImg from "../../../assets/images/png/Group 4@2x.png"
import classes from "./ErrorElement.module.css"
import DButton from "../../Dashboard/DashboardButtons/Dbutton";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorElement = () => {
  //get data from use selector
  const data = useSelector((state) => state.loginData.value);
  const location = useLocation();
  const navigate = useNavigate();
  const [roles, setRoles] = useState({
    Admin: "",
    Buyer: "",
    Vendor: "",
  });

  useEffect(() => {
    setRoles({
      Admin: data?.access_type === "admin" || data?.access_type === "Admin",
      Buyer:
        data?.access_type === "buyer" ||
        data?.access_type === "Buyer",
      Vendor: data?.access_type === "vendor" || data?.access_type === "Vendor",
    });
  }, [location.pathname])

  return (
    <div className={`${classes.ErrorElement} `}>
      <img src={errorImg} alt="Page not found" className={classes.errorImg} />
      <span>Page not found !</span>
      <p>
        The page you have looking for is not found.
      </p>
      <DButton label={"Okay"} onClick={() => {
        navigate(-1)
      }} buyerBtn={roles.Buyer} btnWidth={"14rem"} vendorBtn={roles.Vendor} />
    </div>
  );
};

export default ErrorElement;

import React from 'react';

const StatusMessage = ({nextAppStatus,nextAppName, preAppStatus, preAppName,  currentStatus, currentName, reGenerate, appStatus }) => {
  // const statusText = appStatus === 1 ? `Rejected by ${nextAppName}` :
  //                    nextAppStatus === 1 ? "Rejected by kk" :
  //                    appStatus === null ? "Pending" : "";

  // const statusColor = appStatus === 1 ? 'red' :
  //                     appStatus === 2 ? 'green' :
  //                     appStatus === null ? '#ffc107' : 'black';
  // console.log('preAppStatus', preAppStatus)
  // console.log('currentStatus', currentStatus)
  // console.log('reGenerate', reGenerate)
  // console.log('appstatussss', appStatus)
  // console.log('AAAAAAAAAAA')

  return (
    <span>
      {/* style={{ color: statusColor }} */}
      {/* <span >
        {statusText}
      </span> */}
      {/* <br /> */}
      {nextAppStatus === 1 && (
        <span>{`Rejected by ${nextAppName}`}</span>
      )}
      {preAppStatus === 1 && (
        <span>{`Rejected by ${preAppName}`}</span>
      )}
      {preAppStatus === null && currentStatus!==1 &&(
        <span>{`Waiting for ${preAppName} to approve`}</span>
      )}
      {preAppStatus === null && currentStatus ===1 && reGenerate!==1 &&(
        <span>{`Rejected by ${currentName}`}</span>
      )}
      {preAppStatus === undefined && currentStatus ===1 && reGenerate!==1 && appStatus!==1 && nextAppStatus!==1 && (
        <span>{`Rejected by ${currentName}`}</span>
      )}
      {preAppStatus === null && currentStatus ===1 && reGenerate==1 &&(
        <span>{`Waiting for ${preAppName} to approve`}</span>
      )}
    </span>
  );
};

export default StatusMessage;

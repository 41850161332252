/**
 *
 * @param {*} value
 * @returns "Boolean -> TRUE - If the value is not empty"
 */
const emptyCheck = (value, isLength) => {
  if (!isLength) {
    return value?.trim() !== "" && value?.length <= 255;
  } else {
    return value && value?.trim() !== "";
  }
};

const validateNumber = (value) =>{
   return /^[0-9]+$/.test(value)
}

const emptyOnlyCheck = (value, isLength) => {
  if (!isLength) {
    return value?.trim() !== "";
  } else {
    return value && value?.trim() !== "";
  }
};

const validateFaxNumber = (value) => {
  return value?.trim() !== "" && value?.length <= 20;
};

/**
 *
 * @param {*} WebURL
 * @returns "Boolean -> TRUE - If the value matches to the Web URL Pattern"
 */
const validateWebURL = (value) => {
  let webURLRegEx =
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(:\d+)?(\/[\w~.?+=&%@!\-:/]*)*(#[\w\-]*)?$/;
  return webURLRegEx.test(value);
};

//password validation
/**
 *
 * @param {String} password
 * @returns "Boolean -> TRUE - If the value passes the passwords test cases"
 */
const validatePassword = (password) => {
  const passwordRegex =
    /^(?!.*\s)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`])(?=.*[0-9])(?=.*[A-Z]).{8,}$/;

  return password && passwordRegex.test(password) && password.length <= 255;
};

const validateAlphaNumeric = (value) => {
  const alphaNumeric = /^[a-zA-Z0-9]+$/;
  return alphaNumeric.test(value);
};

const validateCompanyRegistrationNumber = (value) => {
  const alphaNumeric = /^[a-zA-Z0-9]/;
  return alphaNumeric.test(value) && value?.length <= 255;
};

/**
 *
 * @param {String} initialPassword
 * @param {String} confirmPassword
 * @returns "Boolean -> TRUE - If the initial and confirm password matches"
 */
// Validation function for confirm password field
const validateConfirmPassword = (initialPassword, confirmPassword) => {
  // Check if it matches the value of the password field
  return initialPassword === confirmPassword && confirmPassword?.length <= 255;
};

/**
 *
 * @param {String} label
 * @param {String} selectedValue
 * @returns "Boolean -> TRUE - If the value is same as label or empty"
 */

// Validate select Input
const validateSelect = (label, selectedValue) => {
  return selectedValue?.trim() !== "" && selectedValue !== label;
};

/**
 *
 * @param {Number} phoneNumber
 * @returns "Boolean -> TRUE - If the input is phone number"
 */
// Validation function for phone number field
const validatePhoneNumber = (phoneNumber) => {
  return (
    phoneNumber?.trim() !== "" ||
    phoneNumber !== "+" ||
    phoneNumber?.length !== 1
  );
};

/**
 *
 * @param {String} emailAddress
 * @returns "Boolean -> TRUE - if the input matches the email format"
 */
// Validation function for email field
const validateEmail = (emailAddress) => {
  // Check if it matches the email pattern

  // Basic email format check
  const basicRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Check for disallowed characters in the local part and domain
  const disallowedRegex = /["(),:;<>[\]\\]/;

  //changed new one

  // Check for consecutive dots in the local part
  const consecutiveDotsRegex = /[.]{2}/;

  // Check for consecutive dots in the domain
  const consecutiveDomainDotsRegex = /(@)[^.]+[.]{2,}(?=[^.]+)/;

  // Check for domain TLD having length greater than 1
  const domainTldLengthRegex = /(@)[^.]+[.][^.]{1,1}$/;

  // Check for IP address in the domain
  const ipAddressRegex = /(@)\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;

  // Perform all checks
  const isValid =
    basicRegex.test(emailAddress) &&
    !disallowedRegex.test(emailAddress) &&
    !consecutiveDotsRegex.test(emailAddress) &&
    !consecutiveDomainDotsRegex.test(emailAddress) &&
    !domainTldLengthRegex.test(emailAddress) &&
    !ipAddressRegex.test(emailAddress);

  return isValid;
};

/**
 *
 * @param {*} PAN_Number
 * @returns "Boolean -> TRUE - if the value matches the PAN card number format  */
//validate PAN card
const validatePAN = (value) => {
  const panRegex = /^([A-Z]{5})(\d{4})([A-Z]{1})$/;
  return panRegex.test(value);
};

/**
 *
 * @param {file} File
 * @returns "Boolean -> TRUE - if the given value is a valid file"
 */
const checkFile = (value) => {
  return value === "" || value?.length === 0;
};

// covert file to base64String
const convertToBASE64 = (file, callback) => {
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (e) => {
    const base64String = e.target.result;
    callback(base64String);
  };
  reader.readAsDataURL(file);
};

const validateFile = (
  file,
  notRequired,
  allowedFormats,
  maxFileSizeMB,
  fileName
) => {
  const result = {
    isValid: true,
    errorMessage: null,
  };

  if (!file && !notRequired) {
    result.isValid = false;
    result.errorMessage = `${fileName || "File"} is required.`;
  } else if (
    notRequired &&
    (file === null || file === "" || typeof file === "string")
  ) {
    // Scenario 1: File not required and can be missing, empty, or a string
    result.isValid = true;
    result.errorMessage = "";
  } else {
    // Scenario 2: File not required but should be valid if provided
    // Scenario 3: File is required, and it should be valid

    if (file && typeof file === "object") {
      // File object (file input)
      result.isValid = true;
      result.errorMessage = "";

      // Check file format (extension)
      if (allowedFormats && Array.isArray(allowedFormats)) {
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        if (!allowedFormats.includes(fileExtension)) {
          result.isValid = false;
          result.errorMessage = "Invalid file format.";
        }
      }

      // Check file size
      const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
      if (file.size > maxFileSizeBytes) {
        result.isValid = false;
        result.errorMessage = `File size must be less than ${maxFileSizeMB} MB.`;
      }
    }
  }

  return result;
};

const validateMultipleFiles = (
  file,
  notRequired,
  allowedFormats,
  maxFileSizeMB,
  fileName
) => {
  const result = {
    value: file,
    isValid: true,
    errorMessage: null,
  };

  if (!file && !notRequired) {
    result.isValid = false;
    result.errorMessage = `${fileName || "File"} is required.`;
  } else if (
    notRequired &&
    (file === null || file === "" || typeof file === "string")
  ) {
    // Scenario 1: File not required and can be missing, empty, or a string
    result.isValid = true;
    result.errorMessage = "";
  } else {
    // Scenario 2: File not required but should be valid if provided
    // Scenario 3: File is required, and it should be valid

    if (file && typeof file === "object") {
      // File object (file input)
      result.isValid = true;
      result.errorMessage = "";

      // Check file format (extension)
      if (allowedFormats && Array.isArray(allowedFormats)) {
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        if (!allowedFormats.includes(fileExtension)) {
          result.isValid = false;
          result.errorMessage = "Invalid file format.";
        }
      }

      // Check file size
      const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
      if (file.size > maxFileSizeBytes) {
        result.isValid = false;
        result.errorMessage = `File size must be less than ${maxFileSizeMB} MB.`;
      }
    }
  }

  return result;
};

export {
  validateEmail,
  emptyCheck,
  emptyOnlyCheck,
  validatePassword,
  validateConfirmPassword,
  validateSelect,
  validatePhoneNumber,
  validatePAN,
  checkFile,
  validateWebURL,
  validateAlphaNumeric,
  validateCompanyRegistrationNumber,
  validateFaxNumber,
  convertToBASE64,
  validateFile,
  validateMultipleFiles,
  validateNumber,
};

import React from 'react'
import loaderImg from "../../../assets/images/png/Logo@2x.png"
import classes from './AppLoader.module.css'
const AppLoader = () => {
    return (
        <div className={`${classes.AppLoader} flex`}>
            <img src={loaderImg} alt="Loading..." />
        </div>
    )
}

export default AppLoader;
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import loginDataSlice from "../features/LoginDataSlice";
import UsersDataSlice from "../features/UsersDataSlice";
import ItemsDataSlice from "../features/ItemsDataSlice";
import VendorDataSlice from "../features/VendorDataSlice";
import orgRFQDataSlice from "../features/OrgRFQDataSlice";
import authSlice from "../features/AuthSlice";
import AdminDataSlice from "../features/AdminDataSlice";
import BuyerRFQDataSlice from "../features/BuyerRFQDataSlice";
import VendorRFQDataSlice from "../features/VendorRFQDataSlice";
import VendorContactsData from "../features/VendorContactsData";
import SideBarToggleAction from "../features/SideBarToggleSlice";

const persistConfig = {
  key: "main",
  storage,
};
const persistConfigLog = {
  key: "log",
  storage,
};
const persistConfigAdminData = {
  key: "Admin",
  storage
}
const persistSideBarToggler = {
  key: "sideBarToggler",
  storage
}

const loginDataPersister = persistReducer(persistConfig, loginDataSlice);
const authPersistor = persistReducer(persistConfigLog, authSlice);
const adminDataPersister = persistReducer(persistConfigAdminData, AdminDataSlice);
const sideBarTogglePersistor = persistReducer(persistSideBarToggler, SideBarToggleAction);

const store = configureStore({
  reducer: {
    loginData: loginDataPersister,
    adminData: adminDataPersister,
    usersData: UsersDataSlice,
    itemsData: ItemsDataSlice,
    vendorsData: VendorDataSlice,
    orgRFQData: orgRFQDataSlice,
    buyerRFQData: BuyerRFQDataSlice,
    vendorRFQData: VendorRFQDataSlice,
    vendorContactsData: VendorContactsData,
    sideBarToggle:sideBarTogglePersistor,
    auth: authPersistor,
  },
});

const persistor = persistStore(store);

export { store, persistor };

import React, { memo, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import dayjs from 'dayjs';
import classes from '../ChartStyles.module.css'
import ChartIndicator from '../../ChartIndicator/ChartIndicator';
import { Skeleton } from '@mui/material';
import { monthlyReportObjFormate } from '../../../../utils/chartKeyFormat';

const BarChart = ({ labels, mainData, chartDataSet, loadingState, filterOnchange, vendorDashboard, customCardClass, durationValue }) => {
    const [selectedOption, setSelectedOption] = useState(durationValue ? durationValue : 'last six months');
    const [formattedLabels, setFormattedLabels] = useState([]);
    let normalScales = {
        x: {
            grid: {
                display: true,
            },
            border: {
                dash: [6],
            },
        },
        y: {
            grid: {
                display: false,
            },
            ticks: {
                stepSize: mainData ? Math.ceil(Math.max(...mainData?.map((item)=>Number(item))) / 5) : 0,
                beginAtZero: true,
                callback: function (value, index, values) {
                    return value.toLocaleString('en-IN');
                }
            },
            beginAtZero: true,
        },
    }

    let vendorScales = {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                stepSize: mainData ? Math.ceil(Math.max(...mainData?.map((item)=>Number(item))) / 5) : 0,
                beginAtZero: true,
            },
        },
        y: {
            grid: {
                display: true,
            },
            border: {
                dash: [6],
            },
            ticks: {
                stepSize: mainData ? Math.ceil(Math.max(...mainData?.map((item)=>Number(item))) / 5) : 0,
                beginAtZero: true,
                callback: function (value, index, values) {
                    return value.toLocaleString('en-IN');
                }
            },
            beginAtZero: true,
        },
    }

    useEffect(() => {
        const endDate = new Date();

        let newFormattedLabels = [];

        switch (selectedOption) {
            case "monthly":
                newFormattedLabels = monthlyReportObjFormate(labels)?.reverse()
                break;
            case 'this week':
                newFormattedLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                break;

            case 'this month':
                const weeksInMonth = Math.ceil(endDate.getDate() / 7);
                newFormattedLabels = Array.from({ length: weeksInMonth }, (_, i) => `Week ${i + 1}`);
                break;

            case 'last month':
                newFormattedLabels = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
                break;

            case 'last six months':
                newFormattedLabels = Array.from({ length: 6 }, (_, i) => dayjs().subtract(i, 'month').format('MMM')).reverse();
                break;

            default:
                break;
        }

        setFormattedLabels(newFormattedLabels);
    }, [selectedOption, labels]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        filterOnchange && filterOnchange(event.target.value);
    };

    const dataSet = {
        labels: vendorDashboard ? formattedLabels : labels,
        datasets: [
            {
                label: chartDataSet?.label,
                backgroundColor: chartDataSet?.barColors,
                barPercentage: vendorDashboard ? 0.3 : 0.6,
                data: mainData ? mainData : [],
            }
        ]
    };
    const optionsDataSet = {
        plugins: {
            legend: false,
            tooltip: {
                callbacks: {
                  label: (context)=> context?.parsed?.y?.toLocaleString('en-IN')
                },
            },
        },
        options: {
            responsive: true
        },
        scales: vendorDashboard ? vendorScales : normalScales,

    };

    return (
        <div className={`${classes.barsCard} ${customCardClass}`}>
            {
                !loadingState ? <>
                    <div className={classes.linearProgressInline}>
                        <div>
                            <span>{chartDataSet?.cardHeading}</span>
                            <div className={classes.indicators}>
                                {
                                    chartDataSet?.chartIndicators?.map((e) => (
                                        <span key={e.value}><ChartIndicator label={e.value} color={e.color} /></span>
                                    ))
                                }
                            </div>
                        </div>
                        {/* <div className={classes.monthPicker}>
                            <span>Show : </span>
                            <select className={classes.filterDropdown} onChange={handleOptionChange} value={selectedOption}>
                                <option value="this week">This week</option>
                                <option value="this month">This month</option>
                                <option value="last month">Last month</option>
                                <option value="last six months">Last six months</option>
                            </select>
                        </div> */}
                    </div>
                    <Bar data={dataSet} options={optionsDataSet} style={{ maxHeight: vendorDashboard ? '170px' : "200px" }} />
                </>
                    :
                    <>
                        <div className={classes.linearProgress} style={{
                            marginBottom: '0'
                        }}>
                            <span><Skeleton /></span>
                            <div className={classes.indicators}>
                                <Skeleton width={"70%"} />
                            </div>
                        </div>

                        <div className={`${classes.flex} ${classes.lineSkeleton}`}>

                            <Skeleton width={"100%"} height={"250px"} />

                        </div>
                    </>
            }
        </div>
    );
};

export default memo(BarChart);

import React, { memo } from "react";
import styles from "./SuccessPopupDashboard.module.css";
import DButton from "../../../components/Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../../components/Dashboard/DashboardButtons/dButtonOutlined";
import { useState } from "react";

const SuccessPopupDashboard = ({
  secondaryBtn,
  btnTextSecondary,
  btnClickSecondary,
  popupImage,
  popupHeroText,
  popupDescription,
  popupDescriptionLG,
  btnText,
  popBtn,
  btnClick,
  btnWidth,
  btnHeight,
  vendorBtn,
  vendorBtnOutlined,
  customClass,
  buyerBtnOutline,
  buyerBtn,
  loadingPrimaryBtn,
  customHeroTextClass,
  customHeroImgClass,
  poContent,
  btnTextPo
}) => {
  const [loadingprimaryBtn, setLoadingPrimaryBtn] = useState(false);
  const [loadingSecondaryBtn, setLoadingSecondaryBtn] = useState(false);
  const handlePrimaryClick = () => {
    setLoadingPrimaryBtn(true);
    btnClick(); 
    setTimeout(() => {
      setLoadingPrimaryBtn(false);
    }, 2000);
  };

  const handleSecondaryClick = () => {
    setLoadingSecondaryBtn(true);
    btnClickSecondary();
    setTimeout(() => {
      setLoadingSecondaryBtn(false);
    }, 2000);
  };

  console.log('popupDescription', popupDescription)
  return (
    <div
      className={`${styles.login_success_popup_wrapper} ${styles.flex} ${styles.absCenter} ${customClass}`}
    >
      {popupImage && (
        <div
          className={`${styles.popup_image} ${customHeroImgClass} ${styles.flex}`}
        >
          <img src={popupImage} alt="popup image" />
        </div>
      )}

      <div className={`${styles.popup_text} ${styles.flex}`}>
        {popupHeroText && (
          <span className={`${styles.primary} ${customHeroTextClass}`}>
            {" "}
            {popupHeroText}
          </span>
        )}
        {/* {popupDescription && <p>{popupDescription}</p>} */}
        {popupDescription && <div
        className={`${styles.description}`}
        dangerouslySetInnerHTML={{ __html: popupDescription }}></div>}
        {popupDescriptionLG && (
          <p className={styles.pDescLG}>{popupDescriptionLG}</p>
        )}
        <div className="flex">
          {secondaryBtn && !poContent&& (
            <DButtonOutlined
              label={btnTextSecondary}
              btnWidth={btnWidth ? btnWidth : "17rem"}
              btnHeight={btnHeight ? btnHeight : "4.2rem"}
              onClick={btnClickSecondary}
              buyerBtn={buyerBtnOutline}
              vendorBtnOutlined={vendorBtnOutlined}
            />
          )}
          {popBtn && (
            <>
              <DButton
                label={btnText}
                customWrapperClass={styles.yesBtn}
                loadingState={poContent ?loadingprimaryBtn : loadingPrimaryBtn}
                btnWidth={btnWidth ? btnWidth :poContent ? "12rem" : "17rem"}
                btnHeight={btnHeight ? btnHeight : "4.2rem"}
                onClick={handlePrimaryClick}
                buyerBtn={buyerBtn}
                vendorBtn={vendorBtn}
              />
              {poContent && 
                <DButtonOutlined
                  label={btnTextPo}
                  // customWrapperClass={styles.yesBtn}
                  loadingState={loadingSecondaryBtn}
                  btnWidth={btnWidth ? btnWidth :poContent ? "12rem" : "17rem"}
                  btnHeight={btnHeight ? btnHeight : "4.2rem"}
                  onClick={handleSecondaryClick}
                  buyerBtn={buyerBtnOutline}
                  vendorBtnOutlined={vendorBtnOutlined}
                />
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SuccessPopupDashboard);

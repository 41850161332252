import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: []
}

export const vendorsDataSlice = createSlice({
    name: 'vendorsData',
    initialState,
    reducers: {
        setVendorsData: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setVendorsData } = vendorsDataSlice.actions;
export default vendorsDataSlice.reducer;
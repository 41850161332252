import React, { memo } from 'react';
import classes from './HeaderTitle.module.css';

const HeaderTitle = ({ titleText, customclass,styleapply }) => {

    const wrapperClass = styleapply ? classes.lowercaseText: classes.header_title_wrapper;

    return (
        
        <div className={`${wrapperClass} ${customclass}`}>
            <span>{titleText}</span>
        </div>
    );
}

export default memo(HeaderTitle);

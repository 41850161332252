import React, { useState, useEffect } from "react";
//css
import styles from "./ProfileCard.module.css";
import { useSelector, useDispatch } from "react-redux";
//img
import successPopupImg from "../../../assets/images/png/Group 2 2@2x.png";
import profileIcon from "../../../assets/images/svg/profile@.svg";
import profileImg from "../../../assets/images/png/profile-pic.jpg";
import settingImg from "../../../assets/images/svg/settings@.svg";
import logoutImg from "../../../assets/images/svg/logout@.svg";
import sessionExpiredImage from "../../../assets/images/png/Group 5@2x.png";
import logoutPopupImg from "../../../assets/images/png/Group 92@2x.png";
import { Box, Fade, Menu, Modal } from "@mui/material";
import SuccessPopupDashboard from "../../../components/Popups/PopupFrame/SuccessPopupDashboard";
import { useNavigate } from "react-router-dom";
import { setLoginData } from "../../../features/LoginDataSlice";
import {
  clearLogout,
  clearSessionTimeout,
  logOut,
  sessionTimeout,
  setNotifyLogout,
} from "../../../features/AuthSlice";
// API
import axios from "axios";
import { persistStore } from "redux-persist";
import { store } from "../../../store/Store";
import DButton from "../../Dashboard/DashboardButtons/Dbutton";
import DButtonOutlined from "../../Dashboard/DashboardButtons/dButtonOutlined";
import DPasswordInput from "../../Dashboard/DashboardInputs/DPasswordInput";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import {
  emptyCheck,
  validateConfirmPassword,
  validatePassword,
} from "../../../utils/validations";
import PopupFrame from "../../Popups/PopupFrame/PopupFrame";
import { API_ENDPOINT } from "../../../services/api/resources";
import { ClassNames } from "@emotion/react";
import { setSideBarToggle } from "../../../features/SideBarToggleSlice";

const ProfileCard = ({
  profilePath,
  vendorBtn,
  vendorBtnOutlined,
  buyerBtn,
  buyerBtnOutline,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //getting data from use selector
  const AdminData = useSelector((state) => state.adminData.value);
  const loginData = useSelector((state) => state.loginData.value);

  const [auth_token, setAuthToken] = useState(loginData?.auth_token);
  const isNotifyLogout = useSelector((state) => state.auth.NotifyLogout);
  const isSessionTimeout = useSelector((state) => state.auth.sessionExpired);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const sessionTimeoutInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          dispatch(sessionTimeout());
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(sessionTimeoutInterceptor);
    };
  }, []);

  useEffect(() => {
    if (isNotifyLogout) {
      handleOpenLogoutConfirmation();
    } else if (isSessionTimeout) {
      handleOpenSessionExpiryConfirmation();
    } else {
      handleCloseSessionExpiryConfirmation();
      handleCloseLogoutConfirmation();
    }
  }, [isNotifyLogout, isSessionTimeout]);

  //popup for logout confiramtion
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);

  const handleOpenLogoutConfirmation = () => {
    setOpenLogoutConfirmation(true);
  };
  const handleCloseLogoutConfirmation = () => {
    setOpenLogoutConfirmation(false);
    dispatch(clearLogout());
  };

  const handleLogout = () => {
    handleCloseLogoutConfirmation();
    navigate(`${loginData.access_type === "superAdmin" ? "/ai_dialogue" : "/login"}`);
    dispatch(logOut());
    persistStore(store).purge(["log", "Admin", "main"]);
    dispatch(setSideBarToggle(false));
  };

  //popup for session expiry confiramtion
  const [openSessionExpiryConfirmation, setOpenSessionExpiryConfirmation] =
    useState(false);

  const handleOpenSessionExpiryConfirmation = () => {
    setOpenSessionExpiryConfirmation(true);
  };
  const handleCloseSessionExpiryConfirmation = () => {
    setOpenSessionExpiryConfirmation(false);
    dispatch(clearSessionTimeout());
  };

  const handleSessionExpiry = () => {
    handleCloseSessionExpiryConfirmation();
    navigate("/login");
    dispatch(setLoginData([]));
    dispatch(logOut());
  };

  //settings popup properties
  const [oldPassword, setOldPassword] = useState({
    value: "",
    isValid: true,
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    isValid: true,
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    value: "",
    isValid: true,
  });
  const [errorState, setErrorState] = useState({
    error: false,
    errorMsg: "",
  });

  const resetForm = () => {
    setOldPassword({
      value: "",
      isValid: true,
    });
    setNewPassword({
      value: "",
      isValid: true,
    });
    setConfirmNewPassword({
      value: "",
      isValid: true,
    });
    setErrorState({
      error: false,
      errorMsg: "",
    });
  };

  //button loading state
  const [loadingState, setLoadingState] = useState(false);

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(true);
    resetForm();
  };
  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  //popup flags
  const [openS, setOpenS] = useState(false);
  const handleOpenS = () => setOpenS(true);
  const handleCloseS = () => {
    setOpenS(false);
  };

  const validateForm = () => {
    const isOldPasswordValid = emptyCheck(oldPassword.value);
    const isPasswordValid = validatePassword(newPassword.value);
    const isConfPasswordValid =
      validateConfirmPassword(newPassword.value, confirmNewPassword.value) &&
      emptyCheck(confirmNewPassword.value);

    setOldPassword((prev) => ({
      ...prev,
      isValid: isOldPasswordValid,
    }));

    setNewPassword((prev) => ({
      ...prev,
      isValid: isPasswordValid,
    }));

    setConfirmNewPassword((prev) => ({
      ...prev,
      isValid: isConfPasswordValid,
    }));

    return isOldPasswordValid && isPasswordValid && isConfPasswordValid;
  };

  const handleSubmit = async () => {
    const isValidated = validateForm();
    setLoadingState(true);
    if (isValidated) {
      await axios({
        method: "POST",
        url: `${API_ENDPOINT.change_password}`,
        data: {
          email: !vendorBtn
            ? loginData?.user?.email
            : loginData?.user?.company_official_email,
          old_password: oldPassword.value,
          new_password: newPassword.value,
          confirm_password: confirmNewPassword.value,
        },
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      })
        .then((res) => {
          setLoadingState(false);
          handleOpenS();
          handleCloseSettings();
          setErrorState({
            error: false,
            errorMsg: "",
          });
        })
        .catch((err) => {
          setLoadingState(false);
          console.log(err);
          setErrorState({
            error: true,
            errorMsg: Object.values(err?.response?.data),
          });
        });
    } else {
      setLoadingState(false);
      setOldPassword((prev) => ({
        ...prev,
        value: prev.value,
        isValid: prev.isValid,
      }));

      setNewPassword((prev) => ({
        ...prev,
        value: prev.value,
        isValid: prev.isValid,
      }));

      setConfirmNewPassword((prev) => ({
        ...prev,
        value: prev.value,
        isValid: prev.isValid,
      }));
    }
  };

  const SettingsInputsHeading = (
    <HeaderTitle titleText={"Settings"} customclass={styles.popupHeading} />
  );

  const SettingsInputs = [
    <DPasswordInput
      label={"Old Password"}
      type={"password"}
      value={oldPassword.value}
      onChange={(e) => {
        let val = e.target.value;
        setOldPassword({
          value: val,
          isValid: validatePassword(val),
        });
      }}
      error={!oldPassword.isValid}
      errorMsg={
        !emptyCheck(oldPassword.value)
          ? "Old Password is required"
          : !oldPassword.isValid
          ? "Invalid password"
          : ""
      }
      size={"100%"}
    />,
    <DPasswordInput
      label={"New Password"}
      type={"password"}
      value={newPassword.value}
      onChange={(e) => {
        let val = e.target.value;
        setNewPassword({
          value: val,
          isValid: validatePassword(val),
        });
      }}
      error={!newPassword.isValid}
      errorMsg={
        !emptyCheck(newPassword.value)
          ? "New Password is required"
          : !newPassword.isValid
          ? "Invalid password"
          : ""
      }
      customWrapperClass={styles.pwds}
      size={"44%"}
      info={true}
    />,

    <DPasswordInput
      label={"Confirm Password"}
      type={"password"}
      onChange={(e) => {
        let val = e.target.value;
        setConfirmNewPassword({
          value: val,
          isValid: emptyCheck(val),
        });
      }}
      error={!confirmNewPassword.isValid}
      errorMsg={
        !emptyCheck(confirmNewPassword.value)
          ? "Confirm Password is required"
          : !confirmNewPassword.isValid
          ? "Password doesn't match"
          : ""
      }
      customWrapperClass={styles.pwds}
      value={confirmNewPassword.value}
      confirmPassword={true}
      comparePassword={newPassword.value}
      size={"44%"}
    />,
  ];

  const SettingsInputsActionBtns = [
    <DButtonOutlined
      label={"Cancel"}
      onClick={handleCloseSettings}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      buyerBtn={buyerBtn}
      vendorBtn={vendorBtn}
    />,
    <DButton
      label={"Save"}
      error={errorState.error}
      errorMsg={errorState.errorMsg}
      loadingState={loadingState}
      buyerBtn={buyerBtn}
      vendorBtn={vendorBtn}
      btnWidth={"17rem"}
      btnHeight={"4rem"}
      onClick={handleSubmit}
    />,
  ];

  return (
    <>
      <div className={`${styles.profileWrapper}`}>
        <div className={`${styles.profile}`} onClick={(e) => handleClick(e)}>
          <div className={styles.profile_image}>
            <img
              src={
                AdminData?.profile_image?.includes("False") ||
                AdminData?.profile_image === null ||
                !AdminData?.profile_image
                  ? profileImg
                  : AdminData?.profile_image
              }
              alt="Profile"
            />
          </div>

          <div className={styles.profile_text}>
            <span className={styles.profile_name}>
              {/* {AdminData?.first_name} {AdminData?.last_name} */}
              {AdminData?.first_name || "No username"}
            </span>
            <p className={styles.profile_role}>{loginData?.access_type == "User" ? 'Approver' : loginData?.access_type}</p>
          </div>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: window.innerWidth < 768 ? "left" : "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            transform:
              window.innerWidth < 768
                ? "translate(50px,10px)"
                : "translate(20px,10px)",
            ".MuiMenu-list": {
              paddingTop: 0,
              paddingBottom: 0,
            },
            ".MuiMenu-paper": {
              boxShadow: "0px 6px 24px #00000017",
            },
          }}
        >
          <div className={styles.dropdown_menu}>
            <div className={styles.profilecardSm}>
              <div className={styles.profile_text}>
                <em className={styles.profile_name}>{AdminData?.first_name}</em>
                <p className={styles.profile_role}>{loginData?.access_type}</p>
              </div>
            </div>
            {
              loginData.access_type !== "superAdmin" && (
                <>
                  <span
                    onClick={() => {
                      navigate(profilePath, {
                        state: { buyerBtn: buyerBtn, vendorBtn: vendorBtn },
                      });
                    }}
                  >
                    <img src={profileIcon} /> <p>My Profile</p>
                  </span>
                  <span onClick={handleOpenSettings}>
                    <img src={settingImg} /> <p>Settings</p>
                  </span>
                  <div className={styles.line}></div>
                </>
              )
            }
            <span onClick={handleOpenLogoutConfirmation}>
              <img src={logoutImg} /> <p>Logout</p>
            </span>
          </div>
        </Menu>
      </div>

      {/* settings modal */}
      <Modal
        open={openSettings}
        closeAfterTransition
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          overflow: "auto",
        }}
      >
        <Fade in={openSettings}>
          <div
            className={styles.flex}
            style={{
              height: "100vh",
            }}
          >
            <PopupFrame
              customClass={styles.settingsPopup}
              inputChildren={SettingsInputs}
              poupHeading={SettingsInputsHeading}
              actionButtons={SettingsInputsActionBtns}
              closePopup={handleCloseSettings}
            />
          </div>
        </Fade>
      </Modal>

      <Modal open={openLogoutConfirmation}>
        <Fade in={openLogoutConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={logoutPopupImg}
              popupHeroText={"Are you sure want to logout?"}
              customClass={styles.logoutPopup}
              popBtn={true}
              btnText={"Yes"}
              buyerBtn={buyerBtn}
              buyerBtnOutline={buyerBtnOutline}
              vendorBtn={vendorBtn}
              vendorBtnOutlined={vendorBtnOutlined}
              secondaryBtn={true}
              btnTextSecondary={"Cancel"}
              btnClick={handleLogout}
              btnClickSecondary={handleCloseLogoutConfirmation}
            />
          </Box>
        </Fade>
      </Modal>

      <Modal open={openSessionExpiryConfirmation}>
        <Fade in={openSessionExpiryConfirmation}>
          <Box>
            <SuccessPopupDashboard
              popupImage={sessionExpiredImage}
              popupHeroText={"Session Expired!"}
              popupDescription={"Please login again."}
              customClass={styles.logoutPopup}
              popBtn={true}
              btnText={"Login"}
              buyerBtn={buyerBtn}
              buyerBtnOutline={buyerBtnOutline}
              vendorBtn={vendorBtn}
              vendorBtnOutlined={vendorBtnOutlined}
              btnClick={handleSessionExpiry}
              btnClickSecondary={handleCloseSessionExpiryConfirmation}
            />
          </Box>
        </Fade>
      </Modal>

      {/* success modal popup */}
      <Modal open={openS}>
        <Fade in={openS}>
          <Box>
            <SuccessPopupDashboard
              popupImage={successPopupImg}
              popupHeroText={
                <>
                  Password changed <br /> successfully!
                </>
              }
              // popupDescription={}
              popBtn={true}
              btnText={"Okay"}
              buyerBtn={buyerBtn}
              vendorBtn={vendorBtn}
              btnClick={handleCloseS}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(ProfileCard);
